import { string, object ,number} from "yup";

const defaultValidations = {
    englishTitle: string().required("English title is required"),
    arabicTitle: string().required("English title is required"),
}

export const newGlobalSettingSchema = (hasExtraField:boolean) =>{
    if(hasExtraField){
        return object().shape({
            ...defaultValidations,
            extraField: number().required("Please enter a valid number value")
        });
    }
    return object().shape(defaultValidations);
    
} 