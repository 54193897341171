import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Fieldset } from "primereact/fieldset";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import {
  ICouponAllFormData,
  ICouponAmountType,
  ICouponAppliedTo,
  ICouponFormData,
  PlatformEnum,
} from "src/api/types/coupon";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { couponFormValidationSchema } from "./validation-schema";
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import FormFieldError from "src/components/Kit/FormFieldError";
import { couponService } from "src/api/services/coupon";
import { toast } from "src/utils/toast";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import dayjs from "dayjs";

const CreateCoupon: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingRandomButton, setLoadingRandomButton] =
    useState<boolean>(false);
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
    clearErrors,
  } = useForm<ICouponAllFormData>({
    resolver: yupResolver(couponFormValidationSchema(t)),
    mode: "all",
    reValidateMode: "onBlur",
    defaultValues: {
      codes: "",
      description: "",
      amountType: ICouponAmountType.FIXED,
      amountValue: 1,
      startDate: "",
      expireDate: "",
      minimumPackagePrice: 0,
      usagePerUser: 1,
      usageLimit: 1,
      appliedTo: ICouponAppliedTo.ALL,
      platforms: [],
      startDateHasSpecifiedValue: true,
      expireDateHasSpecifiedValue: true,
      usagePerUserHasSpecifiedValue: true,
      usageLimitHasSpecifiedValue: true,
    },
  });

  useEffect(() => {
    if (!watch("startDateHasSpecifiedValue")) {
      setValue("startDate", "");
      clearErrors("startDate");
    }
  }, [watch("startDateHasSpecifiedValue")]);

  useEffect(() => {
    if (!watch("expireDateHasSpecifiedValue")) {
      setValue("expireDate", "");
      clearErrors("expireDate");
    }
  }, [watch("expireDateHasSpecifiedValue")]);

  // empty per code input if unlimited is selected
  useEffect(() => {
    if (!watch("usageLimitHasSpecifiedValue")) {
      clearErrors("usageLimit");
    }
  }, [watch("usageLimitHasSpecifiedValue")]);

  // empty per user input if unlimited is selected
  useEffect(() => {
    if (!watch("usagePerUserHasSpecifiedValue")) {
      clearErrors("usagePerUser");
    }
  }, [watch("usagePerUserHasSpecifiedValue")]);

  const generateRandomCode = async () => {
    setLoadingRandomButton(true);
    try {
      const result = await couponService.generateRandomCode(1);
      setValue("codes", result.data.payload[0]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingRandomButton(false);
    }
  };

  const onSubmitForm = async (data: ICouponAllFormData) => {
    const formData = { ...data };
    // console.log("Start Has Date: ", data.startDateHasSpecifiedValue);
    // console.log("Expire Has Date: ", data.expireDateHasSpecifiedValue);

    if (data.startDateHasSpecifiedValue) {
      if (new Date(data.startDate!).getTime() <= new Date().getTime()) {
        toast.error("Start Date must be after Now");
        return;
      }
      formData.startDate = dayjs(formData.startDate).format(
        "YYYY-MM-DDTHH:mm:ssZ",
      );
    } else {
      delete formData.startDate;
    }

    if (data.expireDateHasSpecifiedValue) {
      if (data.startDateHasSpecifiedValue) {
        if (
          new Date(data.expireDate!).getTime() <
          new Date(data.startDate!).getTime()
        ) {
          toast.error("Expire Date must be after Start Date");
          return;
        }
      } else {
        if (new Date(data.expireDate!).getTime() <= new Date().getTime()) {
          toast.error("Expire Date must be after Now");
          return;
        }
      }
      formData.expireDate = dayjs(formData.expireDate).format(
        "YYYY-MM-DDTHH:mm:ssZ",
      );
    } else {
      delete formData.expireDate;
    }

    if (!data.usageLimitHasSpecifiedValue) {
      delete formData.usageLimit;
    }

    if (!data.usagePerUserHasSpecifiedValue) {
      delete formData.usagePerUser;
    }

    if (data.minimumPackagePrice === 0) {
      delete formData.minimumPackagePrice;
    }

    if (data.description?.trim() === "") {
      delete formData.description;
    }

    delete formData.startDateHasSpecifiedValue;
    delete formData.expireDateHasSpecifiedValue;
    delete formData.usageLimitHasSpecifiedValue;
    delete formData.usagePerUserHasSpecifiedValue;

    console.log(formData);
    // return;

    try {
      setLoading(true);
      const { codes, ...rest } = formData;
      console.log({ ...rest, codes: [codes] });
      await couponService.createCoupon({ ...rest, codes: [codes] });
      toast.success(t("The coupon created successfully"));
      navigate(ROUTE_CONSTANTS.DASHBOARD.PROMOTION.COUPON.ROOT.ABSOLUTE);
      // console.log(formData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onShow = () => {
    let selectAllCheckbox = document.querySelector(
      ".p-multiselect-header > .p-checkbox",
    );
    selectAllCheckbox?.after(" Select All");
  };
  return (
    <>
      <Fieldset>
        <h3>Create New Coupon</h3>
        <form noValidate onSubmit={handleSubmit(onSubmitForm)}>
          <div className="flex gap-3">
            <div className="flex-1">
              <FormFieldWrapper>
                <label
                  htmlFor="codes"
                  className={classNames({ "p-error": errors.codes })}>
                  {t("fields.code")} *
                </label>
                <div>
                  <Controller
                    name="codes"
                    control={control}
                    render={({ field }) => (
                      <>
                        <div className="p-inputgroup flex-1">
                          <InputText
                            type="text"
                            {...field}
                            id="codes"
                            className={classNames({
                              "p-invalid": errors.codes,
                            })}
                            style={{ letterSpacing: "3px" }}
                            onChange={(event) =>
                              setValue(
                                "codes",
                                event.target.value.toUpperCase(),
                              )
                            }
                          />
                          <Button
                            label="Random Code"
                            className="p-button-outlined"
                            style={{ height: "48px" }}
                            disabled={loadingRandomButton}
                            onClick={generateRandomCode}
                          />
                        </div>
                        <FormFieldError>{errors.codes?.message}</FormFieldError>
                      </>
                    )}
                  />
                </div>
              </FormFieldWrapper>
            </div>
            <div className="flex-1"></div>
          </div>
          <div className="flex gap-3">
            <div className="flex-1">
              <FormFieldWrapper>
                <label
                  htmlFor="amountType"
                  className={classNames({ "p-error": errors.amountType })}>
                  {t("fields.amountType")} *
                </label>
                <div>
                  <Controller
                    name="amountType"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        id="amountType"
                        {...field}
                        value={field.value}
                        options={[
                          {
                            label: "Amount in KD",
                            value: "FIXED",
                          },
                          {
                            label: "Amount in PERCENTAGE",
                            value: "PERCENTAGE",
                          },
                        ]}
                        className={classNames("element-full-w", {
                          "p-invalid": !!errors.amountType,
                        })}
                      />
                    )}
                  />
                </div>
              </FormFieldWrapper>
            </div>
            <div className="flex-1">
              <FormFieldWrapper>
                <label
                  htmlFor="amountValue"
                  className={classNames({ "p-error": errors.amountValue })}>
                  {t("fields.amountValue")} *
                </label>
                <div>
                  <Controller
                    name="amountValue"
                    control={control}
                    render={({ field }) => (
                      <>
                        <div className="p-inputgroup flex-1">
                          <InputText
                            type="number"
                            {...field}
                            id="amountValue"
                            className={classNames("block w-full", {
                              "p-invalid": !!errors.amountValue,
                            })}
                            onChange={(e) => {
                              const value = e.target.value;
                              const numberValue = Number(value);
                              if (typeof numberValue === "number") {
                                const amountTypeValue = watch("amountType");
                                if (
                                  amountTypeValue ===
                                  ICouponAmountType.PERCENTAGE
                                ) {
                                  if (numberValue > 0 && numberValue <= 100) {
                                    setValue("amountValue", numberValue);
                                  }
                                } else {
                                  if (numberValue >= 1) {
                                    setValue("amountValue", numberValue);
                                  }
                                }
                              }
                            }}
                          />
                          <span className="p-inputgroup-addon">
                            {watch("amountType") === ICouponAmountType.FIXED
                              ? "KD"
                              : "%"}
                          </span>
                        </div>
                        <FormFieldError>
                          {errors.amountValue?.message}
                        </FormFieldError>
                      </>
                    )}
                  />
                </div>
              </FormFieldWrapper>
            </div>
          </div>
          <div className="flex gap-3">
            <div className="flex-1">
              <FormFieldWrapper>
                <label
                  htmlFor="startDate"
                  className={classNames({ "p-error": errors.startDate })}>
                  {t("fields.startDate")} *
                </label>
                <div className="flex gap-2">
                  <Controller
                    name="startDateHasSpecifiedValue"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        id="startDateHasSpecifiedValue"
                        {...field}
                        value={field.value}
                        options={[
                          {
                            label: "Select Date",
                            value: true,
                          },
                          {
                            label: "Whenever",
                            value: false,
                          },
                        ]}
                      />
                    )}
                  />
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        type="datetime-local"
                        disabled={!watch("startDateHasSpecifiedValue")}
                        {...field}
                        id="startDate"
                        className={classNames("block w-full", {
                          "p-invalid": !!errors.startDate,
                        })}
                      />
                    )}
                  />
                </div>
                <FormFieldError>{errors.startDate?.message}</FormFieldError>
              </FormFieldWrapper>
            </div>
            <div className="flex-1">
              <FormFieldWrapper>
                <label
                  htmlFor="expireDate"
                  className={classNames({ "p-error": errors.expireDate })}>
                  {t("fields.expireDate")} *
                </label>
                <div className="flex gap-2">
                  <Controller
                    name="expireDateHasSpecifiedValue"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        id="expireDateHasSpecifiedValue"
                        {...field}
                        value={field.value}
                        options={[
                          {
                            label: "Select Date",
                            value: true,
                          },
                          {
                            label: "Whenever",
                            value: false,
                          },
                        ]}
                      />
                    )}
                  />
                  <Controller
                    name="expireDate"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        type="datetime-local"
                        disabled={!watch("expireDateHasSpecifiedValue")}
                        {...field}
                        id="expireDate"
                        className={classNames("block w-full", {
                          "p-invalid": !!errors.expireDate,
                        })}
                      />
                    )}
                  />
                </div>
                <FormFieldError>{errors.expireDate?.message}</FormFieldError>
              </FormFieldWrapper>
            </div>
          </div>
          <div className="flex gap-3">
            <div className="flex-1">
              <FormFieldWrapper>
                <label
                  htmlFor="appliedTo"
                  className={classNames({ "p-error": errors.appliedTo })}>
                  {t("fields.appliedTo")} *
                </label>
                <div>
                  <Controller
                    name="appliedTo"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        id="appliedTo"
                        {...field}
                        value={field.value}
                        options={[
                          {
                            label: "All",
                            value: "ALL",
                          },
                          {
                            label: "First Contract",
                            value: "FIRST_CONTRACT",
                          },
                        ]}
                        className={classNames("element-full-w", {
                          "p-invalid": !!errors.appliedTo,
                        })}
                      />
                    )}
                  />
                </div>
              </FormFieldWrapper>
            </div>
            <div className="flex-1">
              <FormFieldWrapper>
                <label
                  htmlFor="description"
                  className={classNames({ "p-error": errors.description })}>
                  {t("fields.description")} (optional)
                </label>
                <div>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        type="text"
                        {...field}
                        id="description"
                        className={classNames("block w-full", {
                          "p-invalid": !!errors.description,
                        })}
                      />
                    )}
                  />
                </div>
              </FormFieldWrapper>
            </div>
          </div>
          <div className="flex gap-3">
            <div className="flex-1">
              <FormFieldWrapper>
                <label
                  htmlFor="usageLimit"
                  className={classNames({ "p-error": errors.usageLimit })}>
                  Use Limit (Per Code) *
                </label>
                <div className="flex gap-2">
                  <Controller
                    name="usageLimitHasSpecifiedValue"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        id="usageLimitHasSpecifiedValue"
                        {...field}
                        value={field.value}
                        options={[
                          {
                            label: "Limited",
                            value: true,
                          },
                          {
                            label: "Unlimited",
                            value: false,
                          },
                        ]}
                      />
                    )}
                  />
                  <Controller
                    name="usageLimit"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        type="number"
                        disabled={!watch("usageLimitHasSpecifiedValue")}
                        {...field}
                        value={
                          !watch("usageLimitHasSpecifiedValue")
                            ? ""
                            : field.value
                        }
                        id="usageLimit"
                        className={classNames("block w-full", {
                          "p-invalid": !!errors.usageLimit,
                        })}
                      />
                    )}
                  />
                </div>
              </FormFieldWrapper>
            </div>
            <div className="flex-1">
              <FormFieldWrapper>
                <label
                  htmlFor="usagePerUser"
                  className={classNames({ "p-error": errors.usagePerUser })}>
                  Use Limit (Per User) *
                </label>
                <div className="flex gap-2">
                  <Controller
                    name="usagePerUserHasSpecifiedValue"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        id="usagePerUserHasSpecifiedValue"
                        {...field}
                        value={field.value}
                        options={[
                          {
                            label: "Limited",
                            value: true,
                          },
                          {
                            label: "Unlimited",
                            value: false,
                          },
                        ]}
                      />
                    )}
                  />
                  <Controller
                    name="usagePerUser"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        type="number"
                        disabled={!watch("usagePerUserHasSpecifiedValue")}
                        {...field}
                        value={
                          !watch("usagePerUserHasSpecifiedValue")
                            ? ""
                            : field.value
                        }
                        id="usagePerUser"
                        className={classNames("block w-full", {
                          "p-invalid": !!errors.usagePerUser,
                        })}
                      />
                    )}
                  />
                </div>
              </FormFieldWrapper>
            </div>
          </div>
          <div className="flex gap-3">
            <div className="flex-1">
              <FormFieldWrapper>
                <label
                  htmlFor="minimumPackagePrice"
                  className={classNames({
                    "p-error": errors.minimumPackagePrice,
                  })}>
                  {t("fields.minimumPackagePrice")} *
                </label>
                <div>
                  <Controller
                    name="minimumPackagePrice"
                    control={control}
                    render={({ field }) => (
                      <div className="p-inputgroup flex-1">
                        <InputText
                          type="number"
                          {...field}
                          id="minimumPackagePrice"
                          className={classNames({
                            "p-invalid": !!errors.minimumPackagePrice,
                          })}
                        />
                        <span className="p-inputgroup-addon">KD</span>
                      </div>
                    )}
                  />
                </div>
              </FormFieldWrapper>
            </div>
            <div className="flex-1">
              <FormFieldWrapper>
                <label
                  htmlFor="platforms"
                  className={classNames({ "p-error": errors.platforms })}>
                  {t("fields.platforms")} *
                </label>
                <div>
                  <Controller
                    name="platforms"
                    control={control}
                    render={({ field }) => (
                      <>
                        <MultiSelect
                          placeholder="Please select at least one item"
                          options={[
                            {
                              label: "Web",
                              value: PlatformEnum.WEB,
                            },
                            {
                              label: "Android",
                              value: PlatformEnum.ANDROID,
                            },
                            {
                              label: "IOS",
                              value: PlatformEnum.IOS,
                            },
                          ]}
                          id="platforms"
                          {...field}
                          maxSelectedLabels={3}
                          className={classNames("element-full-w", {
                            "p-invalid": !!errors.platforms,
                          })}
                          onShow={onShow}
                        />
                        <FormFieldError>
                          {errors.platforms?.message}
                        </FormFieldError>
                      </>
                    )}
                  />
                </div>
              </FormFieldWrapper>
            </div>
          </div>
          <div className="flex gap-2">
            <Button
              className="p-button-outlined p-button-secondary"
              disabled={loading}
              onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button type="submit" disabled={loading}>
              Confirm
            </Button>
          </div>
        </form>
      </Fieldset>
    </>
  );
};

export default CreateCoupon;
