import React from "react";
import { Link } from "react-router-dom";
import QudraLogo from "../QudraLogo";

interface ILogoLinkProps{
    href?:string;
}

const LogoLink:React.FC<ILogoLinkProps> = ({href="/"})=>{
    return (<Link to={href}>
        <QudraLogo />
    </Link>)
}

export default LogoLink;