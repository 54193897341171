import React from "react";
import Header from "src/components/App/PageHeader";

const ContractSetting: React.FC = () => {
    return <>
        <Header
            hasGoBack={true}
            title='Contract Setting'
        />
        <div>
            Contract Setting Content
        </div>
    </>
}

export default ContractSetting;