import { API_ROUTES } from "src/constants/api-routes";
import { PaginatedResponse } from "src/hooks/useUploadMedia";
import { AxiosInstance } from "../index";
import {  IAxiosResponse, IPaginatedRequestBody } from "../types/axios-response";
import { GlobelSettingKeys, IGlobalSettingBody, IGlobalSettingItem } from "../types/global-setting";



export const globalSettingServices = {
    create(entity:GlobelSettingKeys, data:IGlobalSettingBody){
        return AxiosInstance.post<IAxiosResponse<IGlobalSettingItem>>(`${API_ROUTES.GLOBAL_SETTINGS[entity]}/create`,data)
    },
    get(entity:GlobelSettingKeys,data?:IPaginatedRequestBody){
        return AxiosInstance.post<PaginatedResponse<IGlobalSettingItem>>(API_ROUTES.GLOBAL_SETTINGS[entity],data || {})
    },
    update(entity:GlobelSettingKeys,id:number,data:IGlobalSettingBody){
        return AxiosInstance.put<IAxiosResponse<IGlobalSettingItem>>(`${API_ROUTES.GLOBAL_SETTINGS[entity]}/${id}`,  data);
    },
    toggleActivation(entity:GlobelSettingKeys,id:number,isActive:boolean){
        return AxiosInstance.put<IAxiosResponse<boolean>>(`${API_ROUTES.GLOBAL_SETTINGS[entity]}/${id}/activation`,  {active:isActive})
    },
};