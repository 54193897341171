import React, { Dispatch, SetStateAction } from "react";
import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import Column from "../components/Column";
import { sortableKeyboardCoordinates, arrayMove } from "@dnd-kit/sortable";
import { settingItem } from "../helper";

type Props = {
  setting: settingItem[];
  setSetting: Dispatch<SetStateAction<settingItem[]>>;
  title: string;
};

const Form = (props: Props) => {
  const { setting, setSetting, title } = props;
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const getTaskPos = (key: string) =>
    setting.findIndex((task) => task.key === key);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id === over.id) return;

    setSetting((tasks) => {
      const originalPos = getTaskPos(active.id);
      const newPos = getTaskPos(over.id);
      const list = arrayMove(tasks, originalPos, newPos);
      const orderedList = list.map((item, index) => {
        return { ...item, order: index + 1 };
      });
      return orderedList;
    });
  };

  const onCountChange = (key: string, value: number) => {
    setSetting((setting) => {
      return setting.map((item) => {
        if (item.key === key)
          return {
            ...item,
            count: Number(value),
          };
        return item;
      });
    });
  };
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragEnd={handleDragEnd}>
      <Column title={title} tasks={setting} onCountChange={onCountChange} />
    </DndContext>
  );
};

export default Form;
