import React from "react";
import { IFunctionalComponentProps } from "src/types/functional-component";
import "./styles.css";

interface IGeneralPageProps extends IFunctionalComponentProps{};

const GeneralPageContent:React.FC<IGeneralPageProps> = ({children})=>{
    return <div className="general-page">{children}</div>
}

export default GeneralPageContent;