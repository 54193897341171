import { ACCESS_TOKEN } from "src/constants/access-token";
import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import { IAuthenticatedUser, ILoginPayload } from "../types/auth";
import { IAxiosResponse } from "../types/axios-response";

export const authService = {
  login(email: string, password: string) {
    return AxiosInstance.post<IAxiosResponse<ILoginPayload>>(
      API_ROUTES.AUTH.LOGIN,
      {
        email,
        password,
      }
    );
  },
  refresh(refresh_token: string) {
    return AxiosInstance.get<IAxiosResponse<string>>(
      API_ROUTES.AUTH.REFRESH_TOKEN,
      {
        byRefreshToken: true,
        headers: {
          Authorization: `Bearer ${refresh_token}`,
        },
      }
    );
  },
  logout() {
    return AxiosInstance.post<IAxiosResponse<{}>>(API_ROUTES.AUTH.LOGOUT);
  },
  getProfile() {
    return AxiosInstance.get<IAxiosResponse<IAuthenticatedUser>>(
      API_ROUTES.AUTH.GET_PROFILE
    );
  },
  forgotPassword(email: string) {
    return AxiosInstance.post<IAxiosResponse<{}>>(
      API_ROUTES.AUTH.FORGOT_PASSWORD,
      { email }
    );
  },
  resetPassword(token: string, password: string) {
    localStorage.removeItem(ACCESS_TOKEN);
    console.log("api token", token)
    return AxiosInstance.post<IAxiosResponse<{}>>(
      API_ROUTES.AUTH.RESET_PASSWORD,
      { password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
  changePassword(oldPassword: string, newPassword: string) {
    return AxiosInstance.post<IAxiosResponse<{}>>(
      API_ROUTES.AUTH.CHANGE_PASSWORD,
      { oldPassword, newPassword },{
        headers:{
          Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
      }
    );
  }
};
