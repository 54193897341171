import React from 'react'
import './styles.css'

interface IHighlighTextProps {
    boolean: boolean,
    style?: object,
    children?: React.ReactNode
}

const HighlightText:React.FC<IHighlighTextProps> = ({boolean, style, children}) => {
    return (
        <div style={{...style}} className={`highlight-text-wrapper ${boolean ? 'highlight-text-verified-color' : 'highlight-text-not-verified-color'}`}>
            <span className={`highlight-text`}>
                {children}
            </span>
        </div>
    )
}
export default HighlightText    