import React from "react";
import { IFunctionalComponentProps } from "src/types/functional-component";
import "./styles.css";

interface IListingPageProps extends IFunctionalComponentProps{
    title:string;
}

const ListingPage:React.FC<IListingPageProps> = ({children,title})=>{
    return <div className="listing-page">
        <h2 className="text-color font-medium text-2xl m-0 mb-4">{title}</h2>
        <div>{children}</div>
    </div>
}

export default ListingPage;