/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import './styles.css'
import { IExperienceFromData, IExperience } from 'src/api/types/base-data'
import FormFooterActions from "src/components/Kit/FormFooterActions";
import { Button } from "primereact/button";
import { modalStyle } from 'src/constants/modal-styles'
import Modal from 'react-modal';
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import { InputText } from "primereact/inputtext";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { freelancerService } from "src/api/services/freelancer";
import dayjs from "dayjs"
import { IBaseUser } from "src/api/types/user";
import { toast } from "src/utils/toast";
import Card from "src/components/Kit/Card";
import CardHeader from "src/components/Kit/Card/CardHeader";
import CardBody from "src/components/Kit/Card/CardBody";
import { Divider } from "primereact/divider";
import { baseService } from "src/api/services/base";


interface IDropDownOption {
    label: string;
    value: number
}
interface IExperienceProps{
    userData: IBaseUser;
}

const Experiences: React.FC<IExperienceProps> = ({userData}) => {
    const [experiences, setExperiences] = useState(userData?.experiences || [])
    
    const handleUpdateList = (id: number, newData: IExperienceFromData) => {
        let tempExperiences = experiences.map(obj => ({...obj}))
        tempExperiences.forEach(ex => {
            if(ex.id === id) {
                ex.city.id = newData.city;
                ex.companyName = newData.companyName;
                ex.employmentType.id = newData.employmentType;
                ex.endAt = newData.endAt;
                ex.startAt = newData.startAt;
                ex.position = newData.position
            }
        })
        setExperiences(tempExperiences)
    }

    return (
        <Card className="mt-4">
            <CardHeader>Experiences</CardHeader>
            <CardBody>
            <div className="flex flex-column">
                {experiences.length > 0 
                    ?   experiences.map((experience,ind) => 
                            <div key={experience.id}>
                                {
                                    ind !== 0 && <Divider />
                                }
                                <ExperienceCard 
                                userId={userData!.id} 
                                experience={experience}
                                onUpdateList={handleUpdateList}
                            />
                            </div>
                        ) 
                    :   <div style={{ width: '100%' }} className='flex justify-content-center'><span>No Experience added yet!</span></div>
                }
            </div>
            </CardBody>
        </Card>
    )
}


export default Experiences;


interface IExperienceCardProps {
    experience: IExperience;
    userId: number;
    onUpdateList: (id:number, newData: IExperienceFromData) => void
}

const ExperienceCard:React.FC<IExperienceCardProps> = ({ experience, userId, onUpdateList }) => {
    const [show, setShow] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [countries, setCountries] = useState<IDropDownOption[]>([])
    const [cities, setCities] = useState<IDropDownOption[]>([])
    const [employmentTypes, setEmploymentTypes] = useState<IDropDownOption[]>([])
    const [selectedCountry, setSelectedCountry] = useState<number>(experience!.city.country.id)
    
    const { control, formState: { errors }, handleSubmit, getValues, setValue, setError, clearErrors, reset } = useForm<IExperienceFromData>({
        defaultValues: {
            position: experience.position,
            companyName: experience.companyName,
            employmentType: experience.employmentType.id,
            city: experience.city.id,
            startAt: experience?.startAt ? new Date(experience?.startAt) : new Date(),
            endAt: experience?.endAt ? new Date(experience?.endAt) : new Date(),
        },
        mode: "all",
        resolver: yupResolver(object().shape({
            position:
                string()
                .required("Position name is required"),
            companyName:
                string()
                .required("Company name is required"),
        }))
    })

    const onFormSubmit = async (data:IExperienceFromData) => {
        if(getValues().city === -1) {
            setError('city', { type: 'custom', message: 'City is required!' })
            return 
        }else clearErrors('city')


        const tempData = {
            companyName: data.companyName,
            position: data.position,
            employmentTypeId: data.employmentType,
            cityId: data.city,
            startAt: data.startAt,
            endAt: data.endAt,
        }
        
        try{
            setLoading(true)
            await freelancerService.editExperience(tempData, userId, experience.id)
            setLoading(false)
            setShow(false)
            onUpdateList(experience.id, data)
            toast.success("Experience updated successfully!");
        }catch(error){
            setLoading(false)
            console.log(error);
        }
    }

    const getListOfCountries = async () => {
        let tempCountries: IDropDownOption[] = []

        try{
            let res = await baseService.getCountries()
            res.data.payload.items.forEach(country => {
                tempCountries.push({
                    label: country.title,
                    value: country.id
                })
            })
            setCountries(tempCountries)
        }catch(error){
            console.log(error);
        } 
    }

    const getListOfEmploymentTypes = async () => {
        let tempEmploymentTypes: IDropDownOption[] = []

        try{
            let res = await baseService.getEmploymentTypes()
            res.data.payload.items.forEach(empType => {
                tempEmploymentTypes.push({
                    label: empType.title,
                    value: empType.id
                })
            })
            setEmploymentTypes(tempEmploymentTypes)
        }catch(error){
            console.log(error);
        } 
    } 
    
    const getListOfCities = async (id:number) => {
        let tempCities: IDropDownOption[] = []
        try{
         let res = await baseService.getCities(id,true)
         if(res.data.payload.items.length > 0 ) {
            res.data.payload.items.forEach(city => {
                tempCities.push({
                    label: city.title,
                    value: city.id
                })
            })
         }
         setCities(tempCities)
        }catch(error){
         console.log(error);
        } 
    }
    
    useEffect(() => {
        setValue('city', -1)
        getListOfCities(selectedCountry)
    }, [selectedCountry])
    
    useEffect(() => {
        if(show) {
            getListOfCountries()
            getListOfEmploymentTypes()
        }else{
            reset({
                position: experience.position,
                companyName: experience.companyName,
                employmentType: experience.employmentType.id,
                city: experience.city.id,
                startAt: experience?.startAt ? new Date(experience?.startAt) : new Date(),
                endAt: experience?.endAt ? new Date(experience?.endAt) : new Date(),
            })
        }
    }, [show])
    
    return (
        <div
            className="flex flex-row align-items-center justify-content-between p-2 m-1"
            style={{
                position: 'relative'
            }}
        >
            <div className="flex flex-column">
                <div className="flex flex-row">
                    <span className="education-card-title">{experience.position}</span>
                    
                </div>
                <div className="flex flex-row">
                    <span className="education-card-degree-title">
                        {experience.companyName}
                    </span>
                </div>
                <div className="flex flex-row">
                    <span className="education-card-date-title">
                        {dayjs(experience.startAt).format('MMM YYYY')} - {dayjs(experience.endAt).format('MMM YYYY')}
                    </span>
                </div>
            </div>
            <div className="flex flex-row-reverse align-items-center">
                <div 
                    className={"linked-account-edit-button cursor-pointer"} 
                    onClick={() => setShow(true)}
                >
                    <i 
                        className={"pi pi-pencil"} 
                        style={{ 'fontSize': '1em' }}
                    />
                </div>
            </div>
            <Modal 
                ariaHideApp={false}
                style={modalStyle} 
                contentLabel={"Update Experience"} 
                isOpen={show} 
                onRequestClose={() => setShow(false)}
            >
                <div className="flex flex-row justify-content-between">
                    <span style={{fontWeight: '600'}}>Update experience</span>
                    <div className="p-1 cursor-pointer" onClick={() => setShow(false)}>
                        <i 
                            className={"pi pi-times"} 
                            style={{ 'fontSize': '1em', color: 'var(--black)' }}
                        />
                    </div>
                </div>
                <form className="mt-5" noValidate onSubmit={handleSubmit(onFormSubmit)}>
                    <FormFieldWrapper>
                        <label htmlFor="position" className={classNames({ 'p-error': errors.position })}>Major</label>
                            <Controller name="position" control={control} render={({ field }) => (
                                <InputText autoFocus type="text" {...field} id="position" className={classNames('block w-full', { 'p-invalid': !!errors.position })} />
                            )} />
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="companyName" className={classNames({ 'p-error': errors.companyName })}>Company Name</label>
                            <Controller name="companyName" control={control} render={({ field }) => (
                                <InputText autoFocus type="text" {...field} id="companyName" className={classNames('block w-full', { 'p-invalid': !!errors.companyName })} />
                            )} />
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="employmentType" className={classNames({ 'p-error': errors.employmentType })}>employmentType</label>
                            <Controller name="employmentType" control={control} render={({ field }) => (
                                <Dropdown 
                                    options={employmentTypes} 
                                    style={{width: '100%'}}
                                    {...field}
                                />
                            )} />
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="city" className={classNames({ 'p-error': errors.city })}>country</label>
                            <Dropdown 
                                options={countries} 
                                value={selectedCountry}
                                onChange={e => setSelectedCountry(e.value)}
                                style={{width: '100%'}}
                            />
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="city" className={classNames({ 'p-error': errors.city })}>City</label>
                            <Controller name="city" control={control} render={({ field }) => (
                                <Dropdown 
                                    options={cities} 
                                    style={{width: '100%'}}
                                    {...field}
                                />
                            )} />
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="startAt" className={classNames({ 'p-error': errors.startAt })}>Start At</label>
                            <Controller name="startAt" control={control} render={({ field }) => (
                                <Calendar style={{width: '100%'}} id='startAt' {...field} />
                            )} />
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="endAt" className={classNames({ 'p-error': errors.endAt })}>End At</label>
                            <Controller name="endAt" control={control} render={({ field }) => (
                                <Calendar style={{width: '100%'}} id='endAt' {...field} />
                            )} />
                    </FormFieldWrapper>
                    <FormFooterActions>
                        <Button label={'Update'}  className="p-button-primary" type="submit" loading={loading}/>
                        <Button label={'Cancel'}  className="p-button-danger ml-2" onClick={() => setShow(false)}/>
                    </FormFooterActions>
                </form>
            </Modal>
        </div>
    )
}