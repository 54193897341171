import React, { useState, useCallback, useEffect } from 'react'
import { IFreelancerServices } from "src/api/types/base-data";
import { contextFactory } from "src/utils/context-factory";
import { IFreelancerServicesContext } from "./types";
import { useParams } from "react-router-dom";
import { freelancerService } from "src/api/services/freelancer";

export const [FreelancerServicesContext, useFreelancerServices] = contextFactory<IFreelancerServicesContext>("FreelancerServicesContext", "useFreelancerServices");

interface IFreelancerServicesProviderProps {
    children: React.ReactNode
}

export const FreelancerServicesProvider:React.FC<IFreelancerServicesProviderProps> = ({ children }) => {
    const [services, setServices] = useState<IFreelancerServices[]>([])
    const [loading, setLoading] = useState(true);
    const [editingServiceItem,setEditingServiceItem] = useState<IFreelancerServices>();
    const routeParams = useParams<{ id: string }>();

    const fetchFreelancerDetails = useCallback(async () => {
            if (routeParams.id) {
                try{
                    let res = await freelancerService.getServices(parseInt(routeParams.id))
                    setServices(res.data.payload.items)
                    setLoading(false)
                }catch(error){
                    console.log(error);
                    setLoading(false)
                }
            }
    }, [routeParams.id]);

    useEffect(() => {
        fetchFreelancerDetails()
    }, [fetchFreelancerDetails])

    const closeEditServiceModal = ()=>{
        setEditingServiceItem(undefined);
    }

    const openEditServiceModal = (sender:IFreelancerServices)=>{
        setEditingServiceItem(sender);
    }
    
    return (
        <FreelancerServicesContext.Provider value={{
            services, 
            setServices, 
            loading, 
            setLoading,
            editingServiceItem,
            openEditServiceModal,
            closeEditServiceModal}}>
            {children}
        </FreelancerServicesContext.Provider>
    )
}
