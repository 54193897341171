import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./MenuItem.module.css";

interface IMenuItemProps {
    label: string;
    icon: string;
    url: string;
}

const MenuItem: React.FC<IMenuItemProps> = ({ label, icon, url }) => {
    return <NavLink
        to={url}
        className={({ isActive }) => `${styles["menu-item"]} ${isActive ? styles["active"] : ""}`}
    >
        <i
            className={icon}
        />
        <span className={styles['label']} >{label}</span>
    </NavLink>
}

export default MenuItem;