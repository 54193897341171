import { useCallback, useEffect, useState } from "react";
import ListingPage from "src/components/App/ListingPage";
import { dynamicHomeService } from "src/api/services/dynamic-home";
import Search from "./Search";
import RelocationData from "./RelocationData";
import { MAX_ALLOWED_ORDERED_JOBS } from "src/constants/dynamic-home";
import { arrayMove } from "@dnd-kit/sortable";
import { toast } from "src/utils/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { TOrderedStarItem } from "src/types/dynamic-home";
import { Box } from "@mui/material";
import { TPayloadKey } from "./RelocationData/index";

type Props = {
  orderedItemsUrl: string;
  searchUrl: string;
  updateOrderedItemsUrl: string;
  payloadKey: TPayloadKey;
  name: string;
};

const StarManager = (props: Props) => {
  const {
    orderedItemsUrl,
    searchUrl,
    updateOrderedItemsUrl,
    payloadKey,
    name,
  } = props;
  const [orderedJobs, setOrderedJobs] = useState<TOrderedStarItem[]>([]);
  const [loading, setLoading] = useState(false);

  const fetOrderedItems = useCallback(async () => {
    try {
      setLoading(true);

      const result = await dynamicHomeService.getOrderedItems(orderedItemsUrl);
      setOrderedJobs(
        result.data.payload.items
          .map((job) => ({
            id: job.id,
            title: getTitle(job),
            order: job.order,
          }))
          .sort((a, b) => a.order - b.order),
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetOrderedItems();
  }, [fetOrderedItems]);

  const addToOrderedJob = (jobId: number, jobTitle: string) => {
    if (orderedJobs.length <= MAX_ALLOWED_ORDERED_JOBS) {
      setOrderedJobs((prev) => [
        ...prev,
        { id: jobId, order: orderedJobs.length + 1, title: jobTitle },
      ]);
    } else {
      toast.error(`Can't add more than ${MAX_ALLOWED_ORDERED_JOBS} items`);
    }
  };

  const removeFromOrderedJob = (jobId: number) => {
    setOrderedJobs((prev) =>
      prev.map((job) => {
        if (job.id === jobId) {
          return { ...job, order: 0 };
        }
        return job;
      }),
    );
  };
  const changeOrder = (originalPos: number, newPos: number) => {
    setOrderedJobs((prev) => {
      const list = arrayMove(prev, originalPos, newPos);
      const orderedList = list
        .filter((item) => item.order !== 0)
        .map((item, index) => {
          return { ...item, order: index + 1 };
        });
      return orderedList;
    });
  };
  const getTitle = (item: any) => {
    if (payloadKey === "jobs") return item.title;
    if (payloadKey === "packages") return item.title;
    if (payloadKey === "freelancers") return item.fullName;
    if (payloadKey === "categories") return item.ml_title?.en;
    return "";
  };
  const notDeletedItems = orderedJobs.filter((item) => item.order > 0);
  return (
    <>
      <Box pt={2}>
        {loading ? (
          <div className="flex justify-content-center">
            <ProgressSpinner />
          </div>
        ) : (
          <>
            <Search
              orderedJobs={notDeletedItems}
              addToOrderedJob={addToOrderedJob}
              searchUrl={searchUrl}
              getTitle={getTitle}
              name={name}
            />
            {orderedJobs && (
              <RelocationData
                orderedJobs={orderedJobs}
                notDeletedItems={notDeletedItems}
                changeOrder={changeOrder}
                removeFromOrderedJob={removeFromOrderedJob}
                updateOrderedItemsUrl={updateOrderedItemsUrl}
                payloadKey={payloadKey}
                name={name}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default StarManager;
