import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "primereact/button";
import { Fieldset, FieldsetToggleParams } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { staticImages } from "src/assets";
import Avatar from "src/components/App/Avatar";
import GeneralFormErrorMessage from "src/components/App/GeneralFormErrorMessage";
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import { getAxiosError } from "src/utils/get-axios-error";
import { toast } from "src/utils/toast";
import { partnersService } from "../../../../../api/services/partners";
import { INewPartnerData, IPartner } from "../../../../../api/types/partner";
import { categoryValidationSchema } from "../../validation-schema";
import "./styles.css";

interface INewCategoryProps {
  onNewCategoryAdded: (data: IPartner) => void;
}

const NewPartner: React.FC<INewCategoryProps> = ({ onNewCategoryAdded }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>("");
  const [mediaId, setMediaId] = useState<number | undefined>();
  const [showImageBox, setShowImageBox] = useState(true);

  useEffect(() => {
    if (!showImageBox) {
      setTimeout(() => {
        setShowImageBox(true);
      }, 10);
    }
  }, [showImageBox]);

  const onFieldSetToggle = (e: FieldsetToggleParams) => {
    setCollapsed(e.value);
  };

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<INewPartnerData>({
    defaultValues: {
      title: "",
      description: "",
      website: "",
    },
    mode: "all",
    resolver: yupResolver(categoryValidationSchema()),
  });

  const onAvatarChanged = (id: number) => {
    setMediaId(id);
  };

  const setUploadingImage = (value: boolean) => {
    setLoading(value);
  };

  const onFormSubmit = async (data: INewPartnerData) => {
    if (loading) return;
    if (!mediaId) {
      toast.error("Image is required!");
      return;
    }
    const tempData: INewPartnerData = { ...data };
    tempData.images = [mediaId];
    try {
      setLoading(true);
      let res = await partnersService.addNew(tempData);
      reset({
        title: "",
        description: "",
        website: "",
      });

      setMediaId(undefined);
      setShowImageBox(false);
      onNewCategoryAdded(res.data.payload);
      toast.success("New Partner Successfully created");
    } catch (err) {
      const error = getAxiosError(err);
      const message = error.meta?.message || "Server Error";
      setApiError(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="new-category-box mb-4">
      <Fieldset
        legend="New Partner"
        transitionOptions={{ timeout: 1000 }}
        toggleable
        collapsed={collapsed}
        onToggle={onFieldSetToggle}>
        <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
          {apiError && <GeneralFormErrorMessage message={apiError} />}
          <div className="new-category-content">
            <div>
              {showImageBox && (
                <div className="flex gap-8">
                  <div className="flex flex-column gap-2">
                    <label className="font-semibold text-sm">Image</label>
                    <Avatar
                      src={staticImages.noImage}
                      editable
                      onAvatarChanged={onAvatarChanged}
                      rectangle
                      width="120px"
                      height="184px"
                      setUploading={setUploadingImage}
                      style={{ borderRadius: "10px", marginBottom: "15px" }}
                    />
                  </div>
                </div>
              )}
              <div className="image-and-desc">
                <p>Maximum Size(600 * 920) Minimum Size(260 * 400)</p>
                <p>Format: Image Types, *.jpg, *.png, *.jpeg</p>
              </div>

              <FormFieldWrapper>
                <label
                  htmlFor="title"
                  className={classNames({
                    "p-error": errors.title,
                  })}>
                  Title
                </label>
                <Controller
                  name="title"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      type="title"
                      {...field}
                      id="englishTitle"
                      className={classNames("block w-full", {
                        "p-invalid": !!errors.title,
                      })}
                    />
                  )}
                />
                {errors.title && (
                  <small className="p-error">{errors.title.message}</small>
                )}
              </FormFieldWrapper>

              <FormFieldWrapper>
                <label
                  htmlFor="englishTitle"
                  className={classNames({
                    "p-error": errors.description,
                  })}>
                  Description
                </label>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      type="title"
                      {...field}
                      id="englishTitle"
                      className={classNames("block w-full", {
                        "p-invalid": !!errors.description,
                      })}
                    />
                  )}
                />
                {errors.description && (
                  <small className="p-error">
                    {errors.description.message}
                  </small>
                )}
              </FormFieldWrapper>
              <FormFieldWrapper>
                <label
                  htmlFor="arabicTitle"
                  className={classNames({
                    "p-error": errors.website,
                  })}>
                  Website
                </label>
                <Controller
                  name="website"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      type="title"
                      {...field}
                      id="arabicTitle"
                      className={classNames("block w-full", {
                        "p-invalid": !!errors.website,
                      })}
                    />
                  )}
                />
                {errors.website && (
                  <small className="p-error">{errors.website.message}</small>
                )}
              </FormFieldWrapper>
            </div>
          </div>

          <div>
            <Button
              label={"Save"}
              className="p-button-primary"
              type="submit"
              loading={loading}
            />
          </div>
        </form>
      </Fieldset>
    </div>
  );
};

export default NewPartner;
