import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import {  IAxiosResponse } from "../types/axios-response";
import { IGeneralFee } from "../types/fee";


export const feeServices = {
    get(){
        return AxiosInstance.get<IAxiosResponse<IGeneralFee>>(API_ROUTES.FEE.ROOT)
    },
    update(data?: IGeneralFee){
        return AxiosInstance.put<IAxiosResponse<IGeneralFee>>(API_ROUTES.FEE.ROOT, data || {})
    },
};