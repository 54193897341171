import { classNames } from "primereact/utils";
import React from "react";
import { IFunctionalComponentProps } from "src/types/functional-component";
import styles from  "./FormFieldWrapper.module.css";

interface IFormFieldWrapperProps extends IFunctionalComponentProps{};

const FormFieldWrapper:React.FC<IFormFieldWrapperProps> = ({children})=>{
    return <div className={classNames(styles["form-field-wrapper"])}>{children}</div>
}

export default FormFieldWrapper;