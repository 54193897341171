import React, { useState, useRef, useEffect } from 'react'
import useUploadMedia from 'src/hooks/useUploadMedia'
import './styles.css'
import { toast } from "src/utils/toast";

interface AIAvatarProps {
    src?: string;
    style?: object;
    editable?: boolean;
    width?: string;
    height?: string;
    iconSize?: string;
    onAvatarChanged?:(data: number)=> void;
    rectangle?: boolean
    setUploading?:(value:boolean)=>void;
}

interface IUploadedMediaPayload {
    id: number;
    originalName: string;
    obfuscatedName: string;
    url: string;
}

const Avatar: React.FC<AIAvatarProps> = ({src, style, editable, width, height, onAvatarChanged, iconSize, rectangle,setUploading }) => {
    const fileUploadRef = useRef<HTMLInputElement>(null)
    const [imageString, setImageString] = useState<string>(src || "");

    useEffect(()=>{
        setImageString(src || "");
    },[src])

    const { upload, uploading } = useUploadMedia({
        onComplete: (data: IUploadedMediaPayload) => {
            setImageString(data.url)
            onAvatarChanged && onAvatarChanged(data.id)
            toast.success("Images uploaded successfully!");
        },
        onError: (err: any) => {
            console.log("Avatar Upload Error");
            toast.error("Theren is a problem when uploading image")
        }
    });

    useEffect(()=>{
        if(setUploading){
            setUploading(uploading)
        }
    },[uploading,setUploading])
    
    const onAvatarClicked = () => {
        if(!editable) return 
        if (uploading) return 

        fileUploadRef.current?.click()
    }

    const onFileUploadChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (FileReader && files && files.length) {
            let fr = new FileReader();
            fr.onload = function () {
                const image = fr.result as string;
                setImageString(image);
            }
            fr.readAsDataURL(files[0]);
        }
        files && upload(files[0])
    }

    return (
        <div>
            <img
                className="general-avatar-picker"
                style={{
                    cursor: editable ? 'pointer' : 'inherit',
                    width: width || '',
                    height: height || '',
                    borderRadius: rectangle ? '0px' : '50%',
                    ...style
                }}
                src={imageString ? imageString :  src}
                onClick={onAvatarClicked}
                alt='avatar'
            />
            {/* <div className='general-avatar-picker-edit-icon-wrapper'>
                <i 
                    className={"pi pi-camera"} 
                    style={{ 'fontSize': iconSize ? iconSize : '1.5em', color: 'var(--primary-blue-900)' }}
                />
            </div> */}
            <input type="file" ref={fileUploadRef}
                accept="image/png, image/gif, image/jpeg"
                onChange={onFileUploadChanged}
                style={{ display: 'none' }}
            />
        </div>
    )
}

export default Avatar