import React, { useCallback, useEffect, useState } from "react";
import { baseService } from "src/api/services/base";
import { FilterType, ISortOrFilter } from "src/api/types/base-data";
import { IListItem } from "src/components/Kit/ButtonDropDown/types";
import { contextFactory } from "src/utils/context-factory";
import { getAxiosError } from "src/utils/get-axios-error";
import { getListQueryItems } from "src/utils/get-list-query-items";
import { toast } from "src/utils/toast";
import { ISortAndFiltersContext, ISortAndFiltersContextProps } from "./types";
export const [SortAndFiltersContext, useSortAndFilters] = contextFactory<ISortAndFiltersContext>("SortAndFiltersContext", "useSortAndFilters");

export const SortAndFiltersProvider: React.FC<ISortAndFiltersContextProps> = ({ children, listName, onChange ,
    ignoreFilterItem,onExportClicked,exportLoading = false}) => {
    const [activeSortItem, setActiveSortItem] = useState<IListItem>();
    const [filtersIsOpen, setFiltersIsOpen] = useState<boolean>(false);
    const [sortItems, setSortItems] = useState<IListItem[]>([]);
    const [filterItems, setFilterItems] = useState<ISortOrFilter[]>([]);
    const [filters, setFilters] = useState<Record<string, FilterType>>();
    const [searchQuery, setSearchQuery] = useState("");

    const getData = useCallback(async () => {
        try {
            if (!listName) return;
            const response = await baseService.getListInfoByEntity(listName);

            const result = response.data.payload.items[0];
            //handling sort Items
            if(result && result.info && result.info.sorts){
                const tempSortItems = result.info.sorts.map(s => ({
                    key: s.key,
                    title: s.title
                }));
                setSortItems(tempSortItems);
                if(result.info.filters){
                    const tempFilterItems = [...result.info.filters];
                    if(ignoreFilterItem){
                        const ignoreItemIndex = tempFilterItems.findIndex(item=>item.key === ignoreFilterItem);
                        if(ignoreItemIndex >= 0){
                            tempFilterItems.splice(ignoreItemIndex,1);
                        }
                    }
                    setFilterItems(tempFilterItems);
                }
            }
        } catch (err) {
            console.log(err);
            const error = getAxiosError(err);
            toast.error(error.meta?.message!);
        } finally {

        }
    }, [listName,ignoreFilterItem]);

    useEffect(() => {
        getData();
    }, [getData]);

    const applyChanges = (incomingData?: Record<string, FilterType>) => {
        const tempFilters = !!incomingData ? incomingData : (filters || {});
        const data = getListQueryItems(tempFilters);
        if (searchQuery) {
            data.searchQuery = searchQuery;
        }
        onChange(data);
        setFiltersIsOpen(false);
    }

    const resetAll = () => {
        setFilters({});
        const data = getListQueryItems({});
        onChange(data);
        setFiltersIsOpen(false);
    }


    return <SortAndFiltersContext.Provider value={{
        activeSortItem,
        setActiveSortItem,
        filtersIsOpen,
        setFiltersIsOpen,
        sortItems,
        filterItems,
        setFilterItems,
        filters,
        setFilters,
        searchQuery,
        setSearchQuery,
        applyChanges,
        resetAll,
        onExportClicked,
        exportLoading
    }}>{children}</SortAndFiltersContext.Provider>
}