import { string, object } from "yup";

export const categoryValidationSchema = () =>
  object().shape(
    {
      title: string().required("Title is required"),

      description: string()
        .nullable()
        .notRequired()
        .test(
          "min-length",
          "Description must be at least 16 characters",
          (value) => !value || value.length >= 16,
        ),
      website: string()
        .nullable()
        .notRequired()
        .when("website", {
          is: (value: string) => value?.length,
          then: (rule) =>
            rule.matches(
              /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
              "Invalid website URL",
            ),
        }),
    },

    [
      // Add Cyclic deps here because when require itself
      ["website", "website"],
    ],
  );
