import React, { useCallback, useEffect } from "react";
import { authService } from "src/api/services/auth";
import { useGeneralLoading, useSetGeneralLoading, useSetUser, useUser } from "src/context/global-context";
import { IFunctionalComponentProps } from "src/types/functional-component";
import { getAuthorization } from "src/utils/get-authorization";
import "./styles.css";

interface IGeneralLayoutWrapperProps extends IFunctionalComponentProps { }

const GeneralLayoutWrapper: React.FC<IGeneralLayoutWrapperProps> = ({ children }) => {
    const user = useUser();
    const setUser = useSetUser();
    const loading = useGeneralLoading();
    const setLoading = useSetGeneralLoading();

    const getProfile = useCallback(async () => {
        try {
            setLoading(true);
            const result = await authService.getProfile();
            setUser(result.data.payload);
        } catch (err) {
            console.log(err);
        } finally{
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!user) {
            const jwt = getAuthorization();
            if (jwt) {
                getProfile();
            }
        }
    }, [user, getProfile]);
    return <div className="general-layout-wrapper">
        {
            loading ? (<div className="empty-box"></div>) : children
        }
    </div>
}

export default GeneralLayoutWrapper;