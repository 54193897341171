import React, { useEffect, useState } from "react"
import './styles.css'
import { ISkillsProps } from './types'
import { freelancerService } from "src/api/services/freelancer"
import { modalStyle } from 'src/constants/modal-styles'
import Modal from 'react-modal';
import FormFooterActions from "src/components/Kit/FormFooterActions";
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { ISkill } from "src/api/types/base-data"
import { toast } from "src/utils/toast";
import Card from "src/components/Kit/Card"
import CardBody from "src/components/Kit/Card/CardBody"
import CardHeader from "src/components/Kit/Card/CardHeader"
import { baseService } from "src/api/services/base"

interface IDropDownOption {
    label: string;
    value: number
}

const Skills: React.FC<ISkillsProps> = ({ userData }) => {
    const [skills, setSkills] = useState(userData.skills || [])

    const handleUpdateSkill = (id: number, newData: ISkillTempData, levelTitle: string) => {
        const tempSkills = skills.map(obj => ({ ...obj }))
        tempSkills.forEach((skill: ISkill) => {
            if (skill.id === id) {
                skill.skill.ml_title.en = newData.title;
                skill.level.id = newData.levelId;
                skill.level.ml_title.en = levelTitle
            }
        })
        setSkills(tempSkills)
    }

    return (
        <Card className="mt-4">
            <CardHeader>Skills</CardHeader>
            <CardBody>
                <div className="flex flex-row flex-wrap">
                    {skills.length > 0
                        ? skills.map(skill =>
                            <SkillCard
                                key={skill.id}
                                userId={userData.id}
                                skill={skill}
                                onUpdateList={handleUpdateSkill}
                            />
                        )
                        : <div style={{ width: '100%' }} className='flex justify-content-center'><span>No skill added yet!</span></div>
                    }
                </div>
            </CardBody>
        </Card>
    )
}

export default Skills;


interface ISkillCarProps {
    userId: number;
    skill: ISkill;
    onUpdateList: (id: number, tempData: ISkillTempData, levelTitle: string) => void
}

interface ISkillTempData {
    title: string;
    levelId: number;
}

const SkillCard: React.FC<ISkillCarProps> = ({ skill, userId, onUpdateList }) => {
    const [show, setShow] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedSkill, setSelectedSkill] = useState<number>(skill.skill.id)
    const [selectedLevel, setSelectedLevel] = useState<number>(skill.level.id)

    const [skillOption, setSkillOption] = useState<IDropDownOption[]>([])
    const [levelOptions, setLevelOptions] = useState<IDropDownOption[]>([])

    const handleUpdateSkill = async () => {
        let levelTitle = "";
        const tempData = {
            skillId: selectedSkill,
            levelId: selectedLevel
        }

        if (levelOptions.length > 0) levelTitle = levelOptions.find(d => d.value === tempData.levelId)!.label;
        const selectedSkillFromList = skillOption.find(sk => sk.value === selectedSkill);

        try {
            setLoading(true)
            await freelancerService.editSkill(tempData, userId, skill.id)
            setShow(false);
            setLoading(false);
            onUpdateList(skill.id, { levelId: tempData.levelId, title: selectedSkillFromList?.label! }, levelTitle || '')
            toast.success("Skill updated successfully!");
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    const getData = async () => {
        try {
            Promise.all([baseService.getSkills(), baseService.getSkillLevels()]).then((values) => {
                const tempSkills: IDropDownOption[] = []
                const tempLevels: IDropDownOption[] = []

                values[0].data.payload.items.forEach(skill => {
                    tempSkills.push({
                        label: skill.title,
                        value: skill.id
                    })
                })
                values[1].data.payload.items.forEach(level => {
                    tempLevels.push({
                        label: level.title,
                        value: level.id
                    })
                })
                setSkillOption(tempSkills)
                setLevelOptions(tempLevels)
            })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (show) getData()
    }, [show])

    return (
        <>
            <div
                onClick={() => setShow(true)}
                className="flex flex-row align-items-center justify-content-center cursor-pointer language-card-wrapper"
            >
                <span className="language-card-title">{skill.skill.ml_title.en}</span>
                <span className="language-card-level-title">{`(${skill.level.ml_title.en})`}</span>
            </div>
            <Modal
                ariaHideApp={false}
                style={modalStyle}
                contentLabel={"Update linked account"}
                isOpen={show}
                onRequestClose={() => setShow(false)}
            >
                <div className="flex flex-row justify-content-between">
                    <span style={{ fontWeight: '600' }}>Edit skill</span>
                    <div className="p-1 cursor-pointer" onClick={() => setShow(false)}>
                        <i
                            className={"pi pi-times"}
                            style={{ 'fontSize': '1em', color: 'var(--black)' }}
                        />
                    </div>
                </div>
                <div className="flex flex-column mb-4 mt-4">
                    <Dropdown
                        value={selectedSkill}
                        options={skillOption}
                        onChange={(e) => setSelectedSkill(e.value)}
                        editable
                    />
                    <Dropdown
                        value={selectedLevel}
                        options={levelOptions}
                        onChange={(e) => setSelectedLevel(e.value)}
                        className="mt-4"
                    />
                </div>
                <FormFooterActions>
                    <Button label={'Update'} className="p-button-primary" loading={loading} onClick={handleUpdateSkill} />
                    <Button label={'Cancel'} className="p-button-danger ml-3" onClick={() => setShow(false)} />
                </FormFooterActions>
            </Modal>
        </>
    )
}

