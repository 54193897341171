import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { settingItem } from "../../helper";
import Task from "../Task";

type Props = {
  tasks: settingItem[];
  title: string;
  onCountChange: (key: string, value: number) => void;
};
const Column = ({ title, tasks, onCountChange }: Props) => {
  return (
    <div>
      <h2 className="font-semibold text-lg mb-3">{title}</h2>
      <SortableContext items={tasks} strategy={verticalListSortingStrategy}>
        {tasks.map((task) => (
          <Task key={task.id} task={task} onCountChange={onCountChange} />
        ))}
      </SortableContext>
    </div>
  );
};

export default Column;
