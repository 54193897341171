import { IEntityMangementItem } from "./types";

export const settingItems: IEntityMangementItem[] = [{
    name: 'Skills',
    code: "SKILLS",
    singularNoun:"Skill",
}, 
// {
//     name: 'Degrees',
//     code: "DEGREES",
//     singularNoun:"Degree",
// }, 
{
    name: 'Languages',
    code: "LANGUAGES",
    singularNoun:"Language",
}, {
    name: 'Language Levels',
    code: "LANGUAGE_LEVELS",
    singularNoun:"Language Level",
    extraField:{
        label: 'Order',
        name: 'order'
    }
}, {
    name: 'Skill Levels',
    code: "SKILL_LEVELS",
    singularNoun:"Skill Level",
    extraField:{
        label: 'Order',
        name: 'order'
    }
}, {
    name: 'Time Units',
    code: "TIME_UNITS",
    singularNoun:"Time Unit",
    extraField:{
        label: 'Hours',
        name: 'hours'
    }
}]