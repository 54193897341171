import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import {  IPaginatedRequestBody, PaginatedResponse } from "../types/axios-response";
import { IPaymentListItem } from "../types/payments";
import { ITransactionListItem } from "../types/transactions";

export const financialService = {
    getAllTransactions(data?:IPaginatedRequestBody){
        return AxiosInstance.post<PaginatedResponse<ITransactionListItem>>(API_ROUTES.FINANCIAL.TRANSACTION.GET_TRANSACTIONS ,data || {});
    },
    exportTransactions(data?:IPaginatedRequestBody){
        return AxiosInstance.post<Blob>(API_ROUTES.FINANCIAL.TRANSACTION.EXPORT_TRANSACTIONS, data || {});
    },
    getAllPayments(data?:IPaginatedRequestBody){    
        console.log(data);
        return AxiosInstance.post<PaginatedResponse<IPaymentListItem>>(API_ROUTES.FINANCIAL.PAYMENTS.GET_ALL ,data || {});
    },
    exportPayments(data?:IPaginatedRequestBody){
        return AxiosInstance.post<Blob>(API_ROUTES.FINANCIAL.PAYMENTS.EXPORT, data || {});
    }
};