/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Header from "src/components/App/PageHeader";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { number, object } from "yup";
import CustomCard from "src/components/Kit/CustomCard";
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import FormFooterActions from "src/components/Kit/FormFooterActions";
import { Button } from "primereact/button";
import { feeServices } from "src/api/services/fee-services";
import { toast } from "src/utils/toast";

interface IFeeManagementForm {
  customer: number;
  freelancer: number;
}

const FeeManagement = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<IFeeManagementForm>({
    defaultValues: {
      customer: 0,
      freelancer: 0,
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        freelancer: number().max(100).min(0).required("Title name is required"),
      })
    ),
  });

  const onFormSubmit = async (data: IFeeManagementForm) => {
    const tempData = {
      freelancer: data.freelancer / 100,
      customer: 0,
    };

    try {
      setLoading(true);
      await feeServices.update(tempData);
      setLoading(false);
      toast.success("General fee updated!");
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getFeePercentage = async () => {
    try {
      let res = await feeServices.get();
      setInitialLoading(false);
      setValue("freelancer", res.data.payload.freelancer * 100);
      setValue("customer", res.data.payload.customer * 100);
    } catch (error) {
      setInitialLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getFeePercentage();
  }, []);

  if (initialLoading)
    return (
      <div>
        <span>loading ...</span>
      </div>
    );

  return (
    <>
      <Header hasGoBack={true} title="System Fee Management" />
      <div className="grid">
        <div className="xl:col-6 lg:col-6 md:col-6 sm:col-12 p-3">
          <CustomCard>
            <form
              className="mt-5"
              noValidate
              onSubmit={handleSubmit(onFormSubmit)}
            >
              <FormFieldWrapper>
                <label
                  htmlFor="freelancer"
                  className={classNames({ "p-error": errors.freelancer })}
                >
                  Freelancer Fee(%):
                </label>
                <Controller
                  name="freelancer"
                  control={control}
                  render={({ field }) => (
                      <InputText
                      autoFocus
                      type="number"
                      min={0}
                      {...field}
                      id="position"
                      className={classNames("block w-full", {
                        "p-invalid": !!errors.freelancer,
                      })}
                    />
                  )}
                />
              </FormFieldWrapper>
              {/* <FormFieldWrapper>
                <label
                  htmlFor="customer"
                  className={classNames({ "p-error": errors.customer })}
                >
                  Customer Percentage
                </label>
                <Controller
                  name="customer"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      autoFocus
                      type="number"
                      {...field}
                      id="position"
                      className={classNames("block w-full", {
                        "p-invalid": !!errors.customer,
                      })}
                    />
                  )}
                />
              </FormFieldWrapper> */}
              <FormFooterActions>
                <Button
                  label={"Update"}
                  className="p-button-primary"
                  type="submit"
                  loading={loading}
                />
              </FormFooterActions>
            </form>
          </CustomCard>
        </div>
      </div>
    </>
  );
};

export default FeeManagement;
