import React from "react";
import { useTranslation } from "react-i18next";
import Header from "src/components/App/PageHeader";
import Card from "src/components/Kit/Card";
import CardBody from "src/components/Kit/Card/CardBody";
import { useUser } from "src/context/global-context";
import { useTitle } from "src/hooks/useTitle";
import ChangePassword from "./ChangePassword";

const MyProfile: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitles.myProfile"));
    const user = useUser();
    return <div>
        <Header
            hasGoBack={true}
            title={t("titles.myProfile")}
        />
        {
            !!user && <Card>
                <CardBody>
                    <div className="container">
                        <section className="section-wrapper">
                            <h4>Email</h4>
                            <span>{user.email}</span>
                        </section>
                        <section className="section-wrapper">
                            <h4>Role</h4>
                            <span>{user.role}</span>
                        </section>
                        <section className="section-wrapper">
                            <h4>Current Status</h4>
                            <span>{user.status}</span>
                        </section>
                    </div>
                </CardBody>
            </Card>
        }
        {
            !!user && <ChangePassword />
        }
    </div>
}

export default MyProfile;