import React from "react";
import styles from "./CardTitle.module.css";

interface ICardTitleProps{
    children:React.ReactNode;
}

const CardTitle:React.FC<ICardTitleProps> = (props)=>{
    const {children} = props;
    return <div className={styles["card-title"]}>
        <h2>{children}</h2>
    </div>
}

export default CardTitle;