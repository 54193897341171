import { ITab } from "src/types/functional-component";

export const customerProfileTabs: ITab[] = [
  {
    label: "Jobs",
    value: "jobs",
  },
  {
    label: "Profile",
    value: "profile",
  },
];
