import { IContract } from "src/api/types/contracts";
import { ContractType } from "../../../../api/types/contracts";
export const getItems = (contract?: IContract) => {
  if (!contract) return [];
  if (contract.type === ContractType.PACKAGE)
    return contract.package?.items || [];
  if (contract.type === ContractType.JOB) return contract.job?.items || [];
  return [];
};

export const isJobContract = (contract?: IContract) => {
  if (contract?.type === ContractType.JOB) return true;
  return false;
};
