import React from "react";
import { ICustomCardProps } from "./types";
import { InputSwitch } from "primereact/inputswitch";
import "./styles.css";

const CustomCard: React.FC<ICustomCardProps> = ({
  children,
  style,
  className,
  editButton,
  addButton,
  onEditClick,
  onClick,
  topLine,
  deleteButton,
  onDelete,
  hasSwitchButton = false,
  isSwitchButtonActive = false,
  onToggleSwitchButton = () => {},
}) => {
  return (
    <div
      className={`general-card ${className}`}
      style={{
        position: "relative",
        borderTop: topLine ? "3px solid var(--primary-blue-400)" : "",
        ...style,
      }}
      onClick={onClick}>
      {children}
      {editButton && (
        <div className="general-card-action-wrapper" onClick={onEditClick}>
          <i
            className="pi pi-pencil"
            style={{ fontSize: "1em", color: "var(--white)" }}></i>
        </div>
      )}
      {addButton && (
        <div className="general-card-action-wrapper" onClick={onEditClick}>
          <i
            className="pi pi-plus"
            style={{ fontSize: "1em", color: "var(--white)" }}></i>
        </div>
      )}
      {deleteButton && (
        <div className="remove-button" onClick={onDelete}>
          <i
            className="pi pi-trash"
            style={{ fontSize: "1em", color: "var(--white)" }}></i>
        </div>
      )}
      {hasSwitchButton && (
        <div className="switch-button">
          <InputSwitch
            checked={isSwitchButtonActive}
            className={`mt-3`}
            onChange={onToggleSwitchButton}
          />
        </div>
      )}
    </div>
  );
};

export default CustomCard;
