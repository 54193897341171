import React from 'react'
import "./styles.css";
import BackButton from '../BackButton'
import { IPageHeaderProps } from './types'
import { Button } from "primereact/button";
import { ITab } from "src/types/functional-component"

const Header: React.FC<IPageHeaderProps> = ({ title, hasGoBack, titleStyle, tabs, updateTab, currentTab }) => {
    
    return (
        <div className='page-header-wrapper'>
            <div className='flex flex-row align-items-center'>
                {hasGoBack && <BackButton />}
                <span style={{...titleStyle}} className='page-header-title'>{title}</span>
            </div>
            <div>
            {tabs && tabs.map((tab: ITab) => 
                <Button 
                    key={tab.value} 
                    onClick={() => updateTab && updateTab(tab.value)}
                    className='m-1' 
                    style={{
                        background: currentTab === tab.value ? 'var(--primary-blue-500)' : 'var(--primary-blue-100)',
                        color: currentTab === tab.value ? 'var(--white)' : 'var(--primary-blue-500)'
                    }}
                >
                    {tab.label}
                </Button>
            )}
            </div>
        </div>
    )
}
export default Header