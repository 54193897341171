import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import {  IAxiosResponse, IPaginatedRequestBody, PaginatedResponse } from "../types/axios-response";
import { ICategory, INewCategoryData } from "../types/category";


export const categoryService = {
    getAll(data?: IPaginatedRequestBody){
        return AxiosInstance.post<PaginatedResponse<ICategory>>(API_ROUTES.CATEGORIES.ROOT, data || {})
    },
    addNew(data?: INewCategoryData){
        return AxiosInstance.post<IAxiosResponse<ICategory>>(API_ROUTES.CATEGORIES.ADD_NEW, data || {})
    },
    update(categoryId: number, data?: INewCategoryData){
        return AxiosInstance.put<IAxiosResponse<ICategory>>(API_ROUTES.CATEGORIES.UPDATE(categoryId), data || {})
    },
    changeActivation(id:number, active: boolean){
        return AxiosInstance.put<IAxiosResponse<{}>>
            (API_ROUTES.CATEGORIES.CHANGE_ACTIVATION(id), {
                active
            });
    },
};