import { Card } from "primereact/card";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";


export const PasswordChanged: React.FC = () => {
    const { t } = useTranslation();
    return <Card className="item-card text-center">
        <div className="mb-4">
            <i className="pi pi-check-circle text-4xl text-green-600"></i>
        </div>
        <div className="mb-2 text-gray-600">{t("content.yourPasswordChangedSuccessfuly")}</div>
        <div className="mb-4">
            <Link to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE}>{t("actions.login")}</Link>
        </div>
    </Card >
}
