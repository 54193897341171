import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import Column from "../components/Column";
import { TOrderedStarItem } from "src/types/dynamic-home";

type Props = {
  items: TOrderedStarItem[];
  changeOrder: (jobId: number, newOrder: number) => void;
  title: string;
  removeFromOrderedJob: (jobId: number) => void;
};

const Form = (props: Props) => {
  const { items, changeOrder, title, removeFromOrderedJob } = props;
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const getTaskPos = (itemId: number) =>
    items.findIndex((task) => task.id === itemId);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    const originalPos = getTaskPos(active.id);
    const newPos = getTaskPos(over.id);
    if (active.id === over.id) return;
    changeOrder(originalPos, newPos);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragEnd={handleDragEnd}>
      <Column
        title={title}
        items={items}
        removeFromOrderedJob={removeFromOrderedJob}
      />
    </DndContext>
  );
};

export default Form;
