import React from "react";
import "./styles.css";

interface IGeneralFormErrorMessage{
    message:string;
}

const GeneralFormErrorMessage:React.FC<IGeneralFormErrorMessage> = ({message})=>{
    return <div className="general-error-message" data-testid="errorElement">
        <span>
            <i className="pi pi-info-circle"></i>
        </span>
        <span>{message}</span>
    </div>
}

export default GeneralFormErrorMessage;