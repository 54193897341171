import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "primereact/button";
import { Chips } from "primereact/chips";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { baseService } from "src/api/services/base";
import { IDropDownOption } from "src/api/types/base-data";
import FormFieldError from "src/components/Kit/FormFieldError";
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import { modalStyle } from "src/constants/modal-styles";
import { getAxiosError } from "src/utils/get-axios-error";
import { toast } from "src/utils/toast";
import { customerService } from "../../../../../../../api/services/customer";
import { useCustomerJobs } from "../../CustomerJobsContext";
import { editServiceValidationSchema } from "./edit-service-validation-schema";

interface IEditJobForm {
  title: string;
  description: string;
  category: number;
  tags: string[];
  limitedRevisions: boolean;
  revisions: number;
  items: string[];
  deliveryTime: number;
  deliveryTimeUnitId: number;
  price: number;
}

const EditJobModal: React.FC = () => {
  const { closeEditServiceModal, editingJobItem, jobs, setJobs } =
    useCustomerJobs();
  const { t } = useTranslation();
  const [categories, setCategories] = useState<IDropDownOption[]>([]);
  // const [tags, setTags] = useState<IDropDownOptionString[]>([]);
  const [saving, setSaving] = useState(false);
  const [items, setItems] = useState<string[]>(editingJobItem?.items || []);
  const [deliveryTimeUnits, setDeliveryTimeUnits] = useState<IDropDownOption[]>(
    [],
  );
  const [tags, setTags] = useState<string[]>(editingJobItem?.tags || []);
  const getCategories = useCallback(async () => {
    try {
      const result = await baseService.getCategories();
      let tempCategories: IDropDownOption[] = [];
      if (result.data.payload.items.length > 0) {
        tempCategories = result.data.payload.items.map((item) => ({
          label: item.title,
          value: item.id,
        }));
      }
      setCategories(tempCategories);
    } catch (err) {}
  }, []);

  const getTimeUnits = useCallback(async () => {
    try {
      const result = await baseService.getTimeUnits();
      console.log(result.data.payload.items);
      const tempDeliveryTimeUnits: IDropDownOption[] =
        result.data.payload.items.map((item) => ({
          value: item.id,
          label: item.title,
        }));
      setDeliveryTimeUnits(tempDeliveryTimeUnits);
    } catch (err) {
      console.log(err);
      const error = getAxiosError(err);
      toast.error(error.meta?.message!);
    }
  }, []);

  useEffect(() => {
    getTimeUnits();
  }, [getTimeUnits]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    trigger,
  } = useForm<IEditJobForm>({
    defaultValues: {
      title: editingJobItem?.title || "",
      description: editingJobItem?.description || "",
      tags: editingJobItem?.tags || [],
      category: editingJobItem?.category.id,
      limitedRevisions: editingJobItem?.limitedRevisions,
      revisions: editingJobItem?.revisions,
      items: editingJobItem?.items || [],
      deliveryTime: editingJobItem?.deliveryTime,
      deliveryTimeUnitId: editingJobItem?.deliveryTimeUnit.id,
      price: editingJobItem?.price
        ? parseFloat(editingJobItem.price)
        : undefined,
    },
    resolver: yupResolver(editServiceValidationSchema()),
  });

  const onCloseClicked = () => {
    closeEditServiceModal();
  };

  const onFormSubmit = async (data: IEditJobForm) => {
    try {
      setSaving(true);
      const result = await customerService.editJob(editingJobItem?.id!, {
        categoryId: data.category,
        description: data.description,
        tags: tags,
        title: data.title,
        deliveryTime: data.deliveryTime,
        deliveryTimeUnitId: data.deliveryTimeUnitId,
        items: items,
        price: data.price,
        ...(data.limitedRevisions ? { revision: data.revisions } : {}),
      });
      const tempJobs = [...jobs!];
      const targetIndex = tempJobs.findIndex(
        (s) => s.id === editingJobItem?.id!,
      );
      tempJobs[targetIndex] = result.data.payload;
      setJobs(tempJobs);
      toast.success("Job updated successfully");
      closeEditServiceModal();
    } catch (err) {
      const error = getAxiosError(err);
      toast.error(error.meta?.message!);
    } finally {
      setSaving(false);
    }
  };
  console.log("errors", errors);
  useEffect(() => {
    if (!watch("limitedRevisions")) {
      trigger("revisions");
    }
  }, [watch("limitedRevisions")]);
  return (
    <Modal
      ariaHideApp={false}
      style={modalStyle}
      contentLabel={"Edit Job"}
      isOpen={!!editingJobItem}
      onRequestClose={onCloseClicked}>
      <div className="flex flex-row justify-content-between">
        <span style={{ fontWeight: "600" }}>Edit Job</span>
        <div className="p-1 cursor-pointer" onClick={onCloseClicked}>
          <i
            className={"pi pi-times"}
            style={{ fontSize: "1em", color: "var(--black)" }}
          />
        </div>
      </div>
      <div style={{ height: 800 }}>
        <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
          <FormFieldWrapper>
            <label
              htmlFor="title"
              className={classNames({ "p-error": errors.title })}>
              {t("fields.title")}
            </label>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <InputText
                  autoFocus
                  type="text"
                  {...field}
                  id="title"
                  className={classNames("block w-full", {
                    "p-invalid": !!errors.title,
                  })}
                />
              )}
            />
            <FormFieldError>{errors.title?.message}</FormFieldError>
          </FormFieldWrapper>
          <FormFieldWrapper>
            <label
              htmlFor="description"
              className={classNames({ "p-error": errors.description })}>
              {t("fields.description")}
            </label>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <InputTextarea
                  autoFocus
                  {...field}
                  id="description"
                  className={classNames("block w-full", {
                    "p-invalid": !!errors.description,
                  })}
                />
              )}
            />
            <FormFieldError>{errors.description?.message}</FormFieldError>
          </FormFieldWrapper>
          <FormFieldWrapper>
            <label
              htmlFor="category"
              className={classNames({ "p-error": errors.category })}>
              {t("fields.category")}
            </label>
            <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={categories}
                  className={classNames("element-full-w", {
                    "p-invalid": !!errors.category,
                  })}
                />
              )}
            />
          </FormFieldWrapper>
          <div className="flex-1">
            <FormFieldWrapper>
              <label
                htmlFor="limitedRevisions"
                className={classNames({ "p-error": errors.limitedRevisions })}>
                Revision *
              </label>
              <div className="flex gap-2">
                <Controller
                  name="limitedRevisions"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      id="limitedRevisions                        "
                      {...field}
                      value={field.value}
                      options={[
                        {
                          label: "Limited",
                          value: true,
                        },
                        {
                          label: "Unlimited",
                          value: false,
                        },
                      ]}
                    />
                  )}
                />
                <Controller
                  name="revisions"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      type="number"
                      disabled={!watch("limitedRevisions")}
                      {...field}
                      value={!watch("limitedRevisions") ? "" : field.value}
                      id="usageLimit"
                      className={classNames("block w-full", {
                        "p-invalid": !!errors.revisions,
                      })}
                    />
                  )}
                />
              </div>
              <FormFieldError>{errors.revisions?.message}</FormFieldError>
            </FormFieldWrapper>
          </div>
          <FormFieldWrapper>
            <div>Job Deliverable </div>
            <Chips
              value={items}
              onChange={(e) => {
                const validItems = e.value.filter(
                  (t) => t.length >= 2 && t.length < 30,
                );
                setItems(validItems);
              }}
              className="block w-full"
            />
            {items.length < 1 && (
              <FormFieldError>at least one item is required</FormFieldError>
            )}
          </FormFieldWrapper>

          <div className="grid">
            <div className="col">
              <FormFieldWrapper>
                <label
                  htmlFor="deliveryTime"
                  className={classNames({ "p-error": errors.deliveryTime })}>
                  {t("fields.deliveryTime")}
                </label>
                <Controller
                  name="deliveryTime"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      autoFocus
                      type="text"
                      {...field}
                      id="deliveryTime"
                      className={classNames("block w-full", {
                        "p-invalid": !!errors.deliveryTime,
                      })}
                    />
                  )}
                />
              </FormFieldWrapper>
            </div>
            <div className="col">
              <FormFieldWrapper>
                <label
                  htmlFor="deliveryTimeUnitId"
                  className={classNames({
                    "p-error": errors.deliveryTimeUnitId,
                  })}>
                  {t("fields.unit")}
                </label>
                <Controller
                  name="deliveryTimeUnitId"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={deliveryTimeUnits}
                      className={classNames("element-full-w", {
                        "p-invalid": !!errors.deliveryTimeUnitId,
                      })}
                    />
                  )}
                />
              </FormFieldWrapper>
            </div>
          </div>

          <FormFieldWrapper>
            <label
              htmlFor="price"
              className={classNames({ "p-error": errors.price })}>
              Budget
            </label>
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <InputText
                  autoFocus
                  type="text"
                  {...field}
                  id="price"
                  className={classNames("block w-full", {
                    "p-invalid": !!errors.price,
                  })}
                />
              )}
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <div>Tags</div>
            <Chips
              value={tags}
              onChange={(e) => {
                const validTags = e.value.filter(
                  (t) => t.length >= 2 && t.length < 30,
                );
                setTags(validTags);
              }}
              className="block w-full"
            />
            {tags.length < 1 && (
              <FormFieldError>at least one tag is required</FormFieldError>
            )}
          </FormFieldWrapper>
          <div>
            <Button
              label="Submit"
              type="submit"
              disabled={saving}
              loading={saving}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditJobModal;
