import React from "react";
import Details from "./components/Details";
import { CustomerDetailsProvider } from "./CustomerDetailsContext";

const CustomerDetails: React.FC = () => {
  return (
    <CustomerDetailsProvider>
      <Details />
    </CustomerDetailsProvider>
  );
};

export default CustomerDetails;
