import React, { useCallback, useEffect, useState } from "react";
import ListingPage from "src/components/App/ListingPage";
import { Card } from "primereact/card";
import { IGetListBody } from "src/api/types/base-data";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import { DEFAULT_PAGE_SIZE } from "src/constants/pagination";
import { financialService } from "src/api/services/financial";
import SortAndFilters from "src/components/App/SortAndFilters";
import { filterListNames } from "src/constants/filter-list-names";
import {   fuckingDateTemplate,transactionContractId } from "src/components/App/TableHelpers";
import { Column } from "primereact/column";
import { IPaymentListItem } from "src/api/types/payments";
import { useListCSVDownload } from "src/hooks/use-list-csv-download";

const Payments: React.FC = () => {
    const [payments, setPayments] = useState<IPaymentListItem[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState<IGetListBody>();
    const [lazyParams, setLazyParams] = useState<DataTablePFSEvent>({
        filters: {

        },
        first: 0,
        multiSortMeta: [],
        page: 0,
        pageCount: 2,
        rows: DEFAULT_PAGE_SIZE,
        sortField: "",
        sortOrder: null,
    });

    const {exportLoading,exportResult} = useListCSVDownload(financialService.exportPayments,'payments');

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const body: IGetListBody = !!filters ? {
                ...filters,
                ...{
                    skip: lazyParams.page! * DEFAULT_PAGE_SIZE,
                    take: DEFAULT_PAGE_SIZE
                }
            } : {
                skip: lazyParams.page! * DEFAULT_PAGE_SIZE,
                take: DEFAULT_PAGE_SIZE
            }
            const result = await financialService.getAllPayments(body);

            setTotalRecords(result.data.payload.pagination.totalItems);
            setPayments(result.data.payload.items);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, [lazyParams, filters]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onPageChange = (e: DataTablePFSEvent) => {
        setLazyParams(e);
    }

    const onSortAndFiltersChanged = (data: IGetListBody) => {
        setFilters(data);
        setLazyParams({
            ...lazyParams,
            page: 0,
            first: 1
        })
    }

    return <>
        <ListingPage 
            title={"Payments"}
        >
            <SortAndFilters listName={filterListNames.PAYMENTS} onChange={onSortAndFiltersChanged} 
                exportLoading={exportLoading}
                onExportClicked={exportResult}/>
            <Card>
                <DataTable value={payments} emptyMessage="No Payment found." loading={loading}
                    lazy
                    first={lazyParams.first}
                    paginator
                    rows={DEFAULT_PAGE_SIZE}
                    totalRecords={totalRecords}
                    onPage={onPageChange}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    dataKey="id"
                    className="qud-table">
                    <Column field="id" header="ID" />
                    <Column field="contractId" header="Contract ID" body={transactionContractId}/>
                    <Column field="recordId" header="Record ID" />
                    <Column field="amount" header="Amount"  />
                    <Column field="status" header="Status" />
                    <Column field="ownerName" header="Owner Name" />
                    <Column field="ownerEmail" header="Owner Email" />
                    <Column field="date" header="Date" body={fuckingDateTemplate}/>
                </DataTable>
            </Card>
        </ListingPage>
    </>
}

export default Payments;