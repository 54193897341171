import React from "react";
import styles from "./Card.module.css";
import { classNames } from "primereact/utils";

interface ICardProps{
    children:React.ReactNode;
    className?:string;
}

const Card:React.FC<ICardProps> = (props)=>{
    const {children,className} = props;
    return <div className={classNames(styles.card,className)}>{children}</div>
}

export default Card;