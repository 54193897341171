import React from 'react';
import './App.css';
import  Routes  from './Routes';


const App: React.FC = () => {

  return (<Routes />);
}

export default App;
