import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import {
  IAxiosResponse,
  IPaginatedRequestBody,
  PaginatedResponse,
} from "../types/axios-response";
import { IEditJobRequestData, IJobItem } from "../types/job";
import { IBaseUser } from "../types/user";

export const customerService = {
  create(email: string, firstName: string, lastName: string) {
    return AxiosInstance.post<IAxiosResponse<IBaseUser>>(
      API_ROUTES.CUSTOMERS.CREATE,
      {
        email,
        firstName,
        lastName,
      },
    );
  },
  getAll(data?: IPaginatedRequestBody) {
    return AxiosInstance.post<PaginatedResponse<IBaseUser>>(
      API_ROUTES.CUSTOMERS.ROOT,
      data || {},
    );
  },
  exportAll(data?: IPaginatedRequestBody) {
    return AxiosInstance.post<Blob>(API_ROUTES.CUSTOMERS.EXPORT, data || {});
  },
  getDetails(id: string) {
    return AxiosInstance.get<IAxiosResponse<IBaseUser>>(
      `${API_ROUTES.CUSTOMERS.ROOT}/${id}`,
    );
  },
  removeUser(id: number) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.CUSTOMERS.REMOVE(id),
      {},
    );
  },
  getJobs(customerId: number) {
    return AxiosInstance.post<PaginatedResponse<IJobItem>>(
      API_ROUTES.CUSTOMERS.JOBS,
      {
        skip: 0,
        take: 50,
        sort: {},
        searchQuery: "",
        filters: {
          ownerUserId: customerId,
        },
      },
    );
  },
  editJob(jobId: number, data: IEditJobRequestData) {
    return AxiosInstance.put<IAxiosResponse<any>>(
      API_ROUTES.CUSTOMERS.EDIT_JOB(jobId),
      data,
    );
  },
  toggleJob(id: number, targetStatus: boolean) {
    return AxiosInstance.path<IAxiosResponse<{}>>(
      API_ROUTES.CUSTOMERS.TOGGLE_JOB(id, targetStatus),
      {},
    );
  },
  deleteJob(id: number) {
    return AxiosInstance.delete<IAxiosResponse<{}>>(
      API_ROUTES.CUSTOMERS.DELETE_JOB(id),
      {},
    );
  },
};
