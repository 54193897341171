import React from "react";
import styles from "./SortAndFilters.module.css";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import ButtonDropDown from "src/components/Kit/ButtonDropDown";
import { IListItem } from "src/components/Kit/ButtonDropDown/types";
import { SortAndFiltersProvider, useSortAndFilters } from "./SortAndFiltersContext";
import Filters from "./Filters";
import { ISortAndFiltersProps } from "./types";
import { IGetListBody } from "src/api/types/base-data";

const SortAndFiltersContent: React.FC = () => {
    const { activeSortItem, filterItems, setActiveSortItem, filtersIsOpen, setFiltersIsOpen, sortItems, filters, setFilters, applyChanges,
        searchQuery, setSearchQuery, onExportClicked,exportLoading } = useSortAndFilters();

    const onSortChanged = (sender: IListItem) => {
        setActiveSortItem(sender);
        setFilters({
            ...filters,
            sort: sender.key
        });
        applyChanges({
            ...filters,
            sort: sender.key
        });
    }

    const onFiltersClicked = () => {
        setFiltersIsOpen(!filtersIsOpen);
    }

    const onSearchQueryChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value.trim());
    }

    const onSearchKeyUp = (e: React.KeyboardEvent) => {
        if (e.key.toLowerCase() === "enter") {
            applyChanges();
        }
    }

    const onExport = ()=>{
        if(onExportClicked){
            console.log("filters",filters)
            let _filters:IGetListBody = {};
            if(filters?.sort){
                const {sort,...rest} = filters;
                _filters.sort = sort as string;
                _filters.filters = {...rest};

            }else{
                _filters.filters = {...filters};
            }
            if(activeSortItem?.key){
                _filters.sort = activeSortItem.key;
            }
            if(searchQuery){
                _filters.searchQuery = searchQuery;
            }
            onExportClicked(_filters);
        }
    }

    return <div className={classNames(styles["sort-and-filters"], "p-3")}>
        <div className={styles.controls}>
            <div className={styles["search-box"]}>
                <span className="p-input-icon-left">
                    <i className="ph-magnifying-glass" />
                    <InputText placeholder="Search" className="p-inputtext-sm block"
                        value={searchQuery} onChange={onSearchQueryChanged}
                        onKeyUp={onSearchKeyUp} />
                </span>
            </div>
            <div className={styles.buttons}>
                {
                    !!filterItems.length && (<Button label="Filters" icon="ph-funnel" iconPos="left" className="p-button-primary  m-2"
                        onClick={onFiltersClicked} />)
                }
                {
                    !!sortItems.length && (<ButtonDropDown label="Sort" items={sortItems} onChange={onSortChanged} activeItem={activeSortItem} />)
                }
                {
                    !!onExportClicked && (<Button label="Export" icon="ph ph-export" iconPos="left" className="p-button-primary  m-2"
                        loading={exportLoading}
                        disabled={exportLoading}
                        onClick={onExport} />)
                }
            </div>

        </div>
        <Filters />
        {/* <div>Selected Items</div> */}
    </div>

}

const SortAndFilters: React.FC<ISortAndFiltersProps> = (props) => {
    const { listName, onChange, ignoreFilterItem,onExportClicked,exportLoading } = props;
    return <SortAndFiltersProvider listName={listName} onChange={onChange} ignoreFilterItem={ignoreFilterItem} onExportClicked={onExportClicked}
        exportLoading={exportLoading}>
        <SortAndFiltersContent />
    </SortAndFiltersProvider>
}

export default SortAndFilters;