import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import FormFooterActions from "src/components/Kit/FormFooterActions";
import { Controller, useForm } from "react-hook-form";
import { IResetPasswordForm } from "./types";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordValidationSchema } from "./validation-schema";
import { classNames } from "primereact/utils";
import { authService } from "src/api/services/auth";
import GeneralFormErrorMessage from "src/components/App/GeneralFormErrorMessage";
import { useTranslation } from "react-i18next";
import { getAxiosError } from "src/utils/get-axios-error";
import { useTitle } from "src/hooks/useTitle";
import GeneralPageContent from "src/components/App/GeneralPageContent";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PasswordChanged } from "./PasswordChanged";
import { toast } from "src/utils/toast";
import ROUTE_CONSTANTS from "src/Routes/route-constants";

const ResetPassword: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitles.forgotPassword"));
    const [loading, setLoading] = useState<boolean>(false);
    const [passwordChanged, setPasswordChanged] = useState<boolean>(false);
    const [searchParams] = useSearchParams();

    const [apiError, setApiError] = useState<string>("");

    
    const navigate = useNavigate();

    const { control, formState: { errors }, handleSubmit } = useForm<IResetPasswordForm>({
        defaultValues: {
            newPassword: "",
            confirmPassword: ""
        },
        mode: "all",
        resolver: yupResolver(resetPasswordValidationSchema(t))
    });

    useEffect(() => {
        const qToken = searchParams.get("token");
        if (!qToken) {
            toast.error("Reset Password Token Is Not Provided")
            navigate(ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE);
        } 
    }, [searchParams, navigate]);

    const onFormSubmit = async (data: IResetPasswordForm) => {
        try {
            setLoading(true);
            const token = searchParams.get("token");
            await authService.resetPassword(token!,data.newPassword);
            setPasswordChanged(true);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error.meta?.message || "Server Error";
            setApiError(message);
        } finally {
            setLoading(false);
        }
    }

    return <GeneralPageContent>
        <div className="content">
            {
                passwordChanged ? (
                    <PasswordChanged />
                ) : (
                    <Card className="item-card-trasparent" title={t("titles.resetPassword")}>
                        <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
                            {
                                apiError && <GeneralFormErrorMessage message={apiError} />
                            }
                            <FormFieldWrapper>
                                <label htmlFor="email" className={classNames({ 'p-error': errors.newPassword })}>{t("fields.newPassword")}</label>
                                    <Controller name="newPassword" control={control} render={({ field }) => (
                                        <InputText autoFocus type="password" {...field} id="newPassword" className={classNames('block w-full', { 'p-invalid': !!errors.newPassword })} />
                                    )} />
                            </FormFieldWrapper>
                            <FormFieldWrapper>
                                <label htmlFor="confirmPassword" className={classNames({ 'p-error': errors.confirmPassword })}>{t("fields.confirmPassword")}</label>
                                    <Controller name="confirmPassword" control={control} render={({ field }) => (
                                        <InputText autoFocus type="password" {...field} id="confirmPassword" className={classNames('block w-full', { 'p-invalid': !!errors.confirmPassword })} />
                                    )} />
                            </FormFieldWrapper>
                            <FormFooterActions>
                                <Button label={t("actions.submit")} className="p-button-primary" type="submit" loading={loading} />
                            </FormFooterActions>
                        </form>
                    </Card>
                )
            }

        </div>
    </GeneralPageContent>
}

export default ResetPassword;
