import React from "react";
import styles from "./CardBody.module.css";
import { classNames } from "primereact/utils";

interface ICardBodyProps{
    children:React.ReactNode;
    className?:string;
}

const CardBody:React.FC<ICardBodyProps> = (props)=>{
    const {children,className} = props;
    return <div className={classNames(styles["card-body"],className)}>{children}</div>
}

export default CardBody;