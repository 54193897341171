import React, { useState } from "react";
import { Card } from "primereact/card";
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import FormFooterActions from "src/components/Kit/FormFooterActions";
import { Controller, useForm } from "react-hook-form";
import { ILoginFormData } from "./types";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginValidationSchema } from "./validation-schema";
import { classNames } from "primereact/utils";
import { Link, useNavigate } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { authService } from "src/api/services/auth";
import { useSetUser, useUser } from "src/context/global-context";
import { setAuthorization } from "src/utils/set-authorization";
import GeneralFormErrorMessage from "src/components/App/GeneralFormErrorMessage";
import { useTranslation } from "react-i18next";
import { getAxiosError } from "src/utils/get-axios-error";
import { useTitle } from "src/hooks/useTitle";
import GeneralPageContent from "src/components/App/GeneralPageContent";

const Login: React.FC = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    useTitle(t("pageTitles.login"));
    const [loading,setLoading] = useState<boolean>(false);
    const setUser = useSetUser();
    const user = useUser();
    const [apiError,setApiError] = useState<string>("");
    const { control, formState: { errors }, handleSubmit } = useForm<ILoginFormData>({
        defaultValues: {
            email: "",
            password: ""
        },
        mode: "all",
        resolver: yupResolver(loginValidationSchema())
    });

    const onFormSubmit = async(data:ILoginFormData) => {
        setLoading(true);
        try{
            const result = await authService.login(data.email,data.password);
            setUser(result.data.payload.user);
            setAuthorization(result.data.payload.token, result.data.payload.refreshToken);
            navigate(ROUTE_CONSTANTS.DASHBOARD.ROOT.ABSOLUTE);
        }catch(err){
            const error = getAxiosError(err);
            const message = error.meta?.message || "Server Error";
            setApiError(message);
        }finally{
            setLoading(false);
        }
    }

    return <GeneralPageContent>
        <div className="content">
            <Card className="item-card-trasparent" title="Welcome Back" subTitle="Sign in to continue" >
                <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
                    {
                        apiError && <GeneralFormErrorMessage message={apiError} />
                    }
                    <FormFieldWrapper>
                            <label htmlFor="email" className={classNames({ 'p-error': errors.email })}>{t("fields.email")}</label>
                            <Controller name="email" control={control} render={({ field }) => (
                                <InputText autoFocus type="email" {...field} id="email" className={classNames('block w-full',{ 'p-invalid': !!errors.email})} />
                            )} />
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>{t("fields.password")}</label>
                        <Controller name="password" control={control} render={({ field }) => (
                                <InputText type="password" {...field} id="password" className={classNames('block w-full',{ 'p-invalid': !!errors.password})} />
                            )} />
                    </FormFieldWrapper>
                    <div className="text-right mb-2">
                            <Link to={ROUTE_CONSTANTS.AUTH.FORGOT_PASSWORD.ABSOLUTE} className="no-underline">{t("content.forgotPassword")}</Link>
                    </div>
                    <FormFooterActions>
                        <Button label={t("actions.submit")} 
                            className="p-button-primary" type="submit" loading={loading}
                            />
                    </FormFooterActions>
                </form>
            </Card>
            {
                user && <div className="mt-3 text-center text-sm text-green-600">You already signed in as <b>{user.email}</b> but you can sign in by another account or go to the <Link to={ROUTE_CONSTANTS.DASHBOARD.ROOT.ABSOLUTE}>dashboard</Link></div>
            }
        </div>
    </GeneralPageContent>
}

export default Login;