import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { globalSettingServices } from "src/api/services/global-settings";
import { IGlobalSettingBody, IGlobalSettingItem } from "src/api/types/global-setting";
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import { getAxiosError } from "src/utils/get-axios-error";
import { toast } from "src/utils/toast";
import { IEntityMangementItem, INewGlobalSettingData } from "../../types";
import { newGlobalSettingSchema } from "./validation-schema";

interface ISettingManagmentFormProps {
   settingItem: IEntityMangementItem;
   defaultData?: IGlobalSettingItem;
   onSave: (data: IGlobalSettingItem) => void;
   onCancel: (sender?: IGlobalSettingItem) => void;
}

const SettinManagmentForm: React.FC<ISettingManagmentFormProps> = (props) => {
   const { t } = useTranslation();
   const { settingItem, defaultData, onCancel, onSave } = props;
   const [saving,setSaving] = useState(false);
   const { control, reset, formState: { errors }, handleSubmit } = useForm<INewGlobalSettingData>({
      defaultValues: {
         arabicTitle: '',
         englishTitle: '',
         extraField:0
      },
      mode: "all",
      resolver: yupResolver(newGlobalSettingSchema(!!settingItem.extraField))
   });

   const onFormSubmit = async (data: INewGlobalSettingData) => {
      const apiBody:IGlobalSettingBody = {
         isActive:true,
         ml_title:{
            ar:data.arabicTitle,
            en:data.englishTitle
         },
         ...(!!settingItem.extraField  && {
            [settingItem.extraField.name]:parseInt(`${data.extraField}`)
         } )
      }
      try{
         setSaving(true);
         if(defaultData){
            const response = await globalSettingServices.update(settingItem.code,defaultData.id,{
               ...apiBody,
               isActive:defaultData.isActive
            });
            onSave(response.data.payload);
         }else{
            const response = await globalSettingServices.create(settingItem.code,apiBody);
            onSave(response.data.payload);
            resetForm();
         }
         toast.success(`${settingItem.singularNoun} ${!!defaultData ? "updated":"created"} successfully`);
      }catch(err){
         const error = getAxiosError(err);
         toast.error(error.meta?.message!);
      }finally{
         setSaving(false);
      }
   }

   const resetForm = ()=>{
      reset({
         arabicTitle: "",
         englishTitle: "",
         extraField:0
      })
   }

   const onCancelClicked = () => {
      if(defaultData){
      onCancel(defaultData);
      }else{

         resetForm();
      }
   }

   const onSaveClicked = () => {

   }


   useEffect(() => {
      if (defaultData && !!defaultData.ml_title) {
         reset({
            arabicTitle: defaultData.ml_title.ar,
            englishTitle: defaultData.ml_title.en,
            extraField: defaultData?.hours || defaultData?.order || 0
         })
      } else {
         resetForm();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [defaultData])

   return (
      <Fieldset legend={defaultData ? 'Edit' : 'Create'} toggleable>
         <form noValidate onSubmit={handleSubmit(onFormSubmit)}>

            <div className="new-global-setting-content">
               <div className="flex">
                  <div className="flex-1 px-3">
                     <FormFieldWrapper>
                        <label htmlFor="englishTitle" className={classNames({ 'p-error': errors.englishTitle })}>{t("fields.englishTitle")}</label>
                           <Controller name="englishTitle" control={control} render={({ field }) => (
                              <InputText type="text" {...field} id="englishTitle"
                                 className={classNames('block w-full', { 'p-invalid': !!errors.englishTitle })} />
                           )} />
                     </FormFieldWrapper>
                  </div>
                  <div className="flex-1 px-3">
                     <FormFieldWrapper>
                        <label htmlFor="arabicTitle" className={classNames({ 'p-error': errors.arabicTitle })}>{t("fields.arabicTitle")}</label>
                           <Controller name="arabicTitle" control={control} render={({ field }) => (
                              <InputText type="text" {...field} id="arabicTitle"
                                 className={classNames('block w-full', { 'p-invalid': !!errors.arabicTitle })} />
                           )} />
                     </FormFieldWrapper>
                  </div>
               </div>
               {
                  settingItem.extraField && (
                     <div className="flex">
                        <div className="flex-1 px-3">
                           <FormFieldWrapper>
                              <label htmlFor="extraField" className={classNames({ 'p-error': errors.extraField })}>{settingItem.extraField.label}</label>
                                 <Controller name="extraField" control={control} render={({ field }) => (
                                    <InputText type="number" {...field} id="extraField"
                                       className={classNames('block w-full', { 'p-invalid': !!errors.extraField })} />
                                 )} />
                           </FormFieldWrapper>
                        </div>
                     </div>
                  )
               }
               <div className="flex">
                  <div className="flex-1 px-3">

                     <Button label="Save" className="p-button-primary mr-3" type="submit"
                        onClick={onSaveClicked}
                        loading={saving}
                        disabled={saving}
                     />
                     <Button label="Cancel" className="p-button-danger mr-3" type="button"
                        onClick={onCancelClicked}
                     />

                  </div>
               </div>
            </div>
         </form>

      </Fieldset>
   )
}

export default SettinManagmentForm

