import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import { classNames } from "primereact/utils";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { customerService } from "src/api/services/customer";
import { userService } from "src/api/services/user";
import { IGetListBody } from "src/api/types/base-data";
import { IBaseUser } from "src/api/types/user";
import ListingPage from "src/components/App/ListingPage";
import NewUser from "src/components/App/NewUser";
import SortAndFilters from "src/components/App/SortAndFilters";
import { dateCreatedAtBodyTemplate, emailBodyTemplate, emailVerifiedBodyTemplate, fullNameBodyTemplate, statusTemplate } from "src/components/App/TableHelpers";
import { filterListNames } from "src/constants/filter-list-names";
import { DEFAULT_PAGE_SIZE } from "src/constants/pagination";
import { useListCSVDownload } from "src/hooks/use-list-csv-download";
import { useTitle } from "src/hooks/useTitle";
import { toast } from "src/utils/toast";

const Customers: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitles.customers"))

    const [activationTargetLoading, setActivationTargetLoading] = useState<number>(-1);
    const [removingTargetLoading, setRemovingTargetLoading] = useState<number>(-1);
    const [loading, setLoading] = useState<boolean>(false);
    const [customers, setCustomers] = useState<IBaseUser[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filters, setFilters] = useState<IGetListBody>();
    
    const [lazyParams, setLazyParams] = useState<DataTablePFSEvent>({
        filters: {

        },
        first: 0,
        multiSortMeta: [],
        page: 0,
        pageCount: 2,
        rows: DEFAULT_PAGE_SIZE,
        sortField: "",
        sortOrder: null,
    });

    const {exportLoading,exportResult} = useListCSVDownload(customerService.exportAll,'customers');

    const fetchCustomres = useCallback(async () => {
        try {
            setLoading(true);
            const body: IGetListBody = !!filters ? {
                ...filters,
                ...{
                    skip: lazyParams.page! * DEFAULT_PAGE_SIZE,
                    take: DEFAULT_PAGE_SIZE
                }
            } : {
                skip: lazyParams.page! * DEFAULT_PAGE_SIZE,
                take: DEFAULT_PAGE_SIZE
            }
            const result = await customerService.getAll(body);
            setTotalRecords(result.data.payload.pagination.totalItems);
            setCustomers(result.data.payload.items);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, [lazyParams, filters]);

    useEffect(() => {
        fetchCustomres();
    }, [fetchCustomres]);

    const onPageChange = (e: DataTablePFSEvent) => {
        setLazyParams(e);
    }

    const changeUserActivation = (rowData: IBaseUser) => {
        confirmDialog({
            message: `Are you sure you want to ${rowData.status === 'Active' ? "deactivate" : "activate"} ${rowData.email}`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                try {
                    const tempCustomers = [...customers];
                    setActivationTargetLoading(rowData.id);
                    await userService.changeActivation(rowData.id, rowData.status !== "Active")
                    const targetItemIndex = tempCustomers.indexOf(rowData);
                    tempCustomers[targetItemIndex].status = rowData.status === "Active" ? "Inactive" : "Active";
                    setCustomers(tempCustomers);
                } catch (err) {
                    console.log(err);
                } finally {
                    setActivationTargetLoading(-1);
                }
            }
        })
    }

    const removeUser = (rowData: IBaseUser) => {
        confirmDialog({
            message: `Are you sure you want to remove ${rowData.email}`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                try {
                    const tempCustomers = [...customers];
                    setRemovingTargetLoading(rowData.id);
                    await customerService.removeUser(rowData.id)
                    const targetItemIndex = tempCustomers.indexOf(rowData);
                    tempCustomers.splice(targetItemIndex, 1);
                    setCustomers(tempCustomers);
                    toast.success("Customer removed successfuly");
                } catch (err) {
                    console.log(err);
                } finally {
                    setRemovingTargetLoading(-1);
                }
            }
        })
    }

    const changeActivationTemplate = (rowData: IBaseUser) => {
        return <>
            <Button
                label={t(rowData.status === 'Active' ? "actions.deactive" : "actions.active")}
                className={classNames("p-button-outlined p-button-sm", { "p-button-danger": rowData.status === "Active", "p-button-secondary": rowData.status !== "Active" })}
                type="button"
                loading={activationTargetLoading === rowData.id}
                onClick={() => { changeUserActivation(rowData) }}
            />
        </>
    }

    const removeUserTemplate = (rowData: IBaseUser) => {
        return <>
            <Button
                label="Remove"
                className="p-button-outlined p-button-sm p-button-danger"
                type="button"
                loading={removingTargetLoading === rowData.id}
                onClick={() => { removeUser(rowData) }}
            />
        </>
    }

    const onCustomerCreated = (data: IBaseUser) => {
        setCustomers([data, ...customers]);
    }

    const onSortAndFiltersChanged = (data: IGetListBody) => {
        setFilters(data);
        setLazyParams({
            ...lazyParams,
            page: 0,
            first: 1
        })
    }


    return <>
        <ListingPage title={t("titles.customers")}>
            <NewUser type="customer" afterSave={onCustomerCreated} />
            <SortAndFilters listName={filterListNames.CUSTOMERS} onChange={onSortAndFiltersChanged} onExportClicked={exportResult}
                exportLoading={exportLoading} />
            <Card>
                <DataTable value={customers} emptyMessage="No Customers found." loading={loading}
                    lazy
                    first={lazyParams.first}
                    paginator
                    rows={DEFAULT_PAGE_SIZE}
                    totalRecords={totalRecords}
                    onPage={onPageChange}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    dataKey="id"
                    className="qud-table" >
                    <Column field="id" header="ID" />
                    <Column field="email" header="Email Address" body={emailBodyTemplate}></Column>
                    <Column header="Full Name" body={fullNameBodyTemplate}></Column>
                    <Column field="status" header="Status" body={statusTemplate} />
                    <Column field="emailVerified" header="Email Verified" body={emailVerifiedBodyTemplate}></Column>
                    <Column field="createdAt" header="Created At" body={dateCreatedAtBodyTemplate}></Column>
                    <Column header="" body={changeActivationTemplate} />
                    <Column header="" body={removeUserTemplate} />
                </DataTable>
            </Card>
        </ListingPage>
        <ConfirmDialog />
    </>
}

export default Customers;