/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import './styles.css'
import { IEducations, IEducationFormData } from 'src/api/types/base-data'
import FormFooterActions from "src/components/Kit/FormFooterActions";
import { Button } from "primereact/button";
import { modalStyle } from 'src/constants/modal-styles'
import Modal from 'react-modal';
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import { InputText } from "primereact/inputtext";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import dayjs from "dayjs"
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { freelancerService } from "src/api/services/freelancer";
import { IBaseUser } from "src/api/types/user";
import { toast } from "src/utils/toast";
import { Divider } from "primereact/divider";
import Card from "src/components/Kit/Card";
import CardHeader from "src/components/Kit/Card/CardHeader";
import CardBody from "src/components/Kit/Card/CardBody";
import { baseService } from "src/api/services/base";

interface IDropDownOption {
    label: string;
    value: number;
}
interface IEducationProps {
    userData: IBaseUser
}
const Educations: React.FC<IEducationProps> = ({ userData }) => {
    const [educations, setEducations] = useState(userData?.educations || [])

    const handleUpdateList = (id: number, newData: IEducationFormData, degreeTitle: string | undefined) => {
        let tempEducation = educations.map(obj => ({ ...obj }))
        tempEducation.forEach(ed => {
            if (ed.id === id) {
                ed.city.id = newData.city;
                ed.degree.id = newData.degree;
                ed.degree.title = degreeTitle || '';
                ed.endAt = newData.endAt;
                ed.major = newData.major;
                ed.startAt = newData.startAt;
                ed.universityName = newData.universityName;
            }
        })
        setEducations(tempEducation)
    }

    return (
        <Card className="mt-4">
            <CardHeader>Education</CardHeader>
            <CardBody>
            <div className="flex flex-column">
                {educations.length > 0
                    ? educations.map((education, ind) =>

                        <div key={education.id}>
                            {
                                ind !== 0 && <Divider />
                            }
                            <EducationCard
                                key={education.id}
                                userId={userData!.id}
                                education={education}
                                onUpdateList={handleUpdateList}
                            />
                        </div>
                    )
                    : <div style={{ width: '100%' }} className='flex justify-content-center'><span>No Education added yet!</span></div>
                }
            </div>
            </CardBody>
        </Card>
    )
}

export default Educations;

interface IEducationCardProps {
    education: IEducations;
    userId: number;
    onUpdateList: (id: number, newData: IEducationFormData, degreeTitle: string | undefined) => void
}

const EducationCard: React.FC<IEducationCardProps> = ({ userId, education, onUpdateList }) => {
    const [show, setShow] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [countries, setCountries] = useState<IDropDownOption[]>([])
    const [cities, setCities] = useState<IDropDownOption[]>([])
    const [degrees, setDegrees] = useState<IDropDownOption[]>([])
    const [selectedCountry, setSelectedCountry] = useState<number>(education.city.country.id)

    const { control, formState: { errors }, handleSubmit, reset, getValues, setValue, setError, clearErrors } = useForm<IEducationFormData>({
        defaultValues: {
            major: education.major,
            universityName: education.universityName,
            degree: education.degree.id,
            city: education.city.id,
            startAt: education?.startAt ? new Date(education?.startAt) : new Date(),
            endAt: education?.endAt ? new Date(education?.endAt) : new Date(),
        },
        mode: "all",
        resolver: yupResolver(object().shape({
            universityName:
                string()
                    .required("University name is required"),
            major:
                string()
                    .required("Major is required"),
        }))
    })

    const onFormSubmit = async (data: IEducationFormData) => {
        if (getValues().city === -1) {
            setError('city', { type: 'custom', message: 'City is required!' })
            return
        } else clearErrors('city')

        let degreeTitle
        const tempData = {
            universityName: data.universityName,
            degreeId: data.degree,
            major: data.major,
            cityId: data.city,
            startAt: data.startAt,
            endAt: data.endAt
        }
        if (degrees.length > 0) degreeTitle = degrees.find(d => d.value === tempData.degreeId)!.label

        try {
            setLoading(true)
            await freelancerService.editEducation(tempData, userId, education.id)
            setLoading(false)
            setShow(false)
            onUpdateList(education.id, data, degreeTitle)
            toast.success("Education updated successfully!");
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    const getListOfCountries = async () => {
        let tempCountries: IDropDownOption[] = []

        try {
            let res = await baseService.getCountries()
            res.data.payload.items.forEach(country => {
                tempCountries.push({
                    label: country.title,
                    value: country.id
                })
            })
            setCountries(tempCountries)
        } catch (error) {
            console.log(error);
        }
    }

    const getListOfDegrees = async () => {
        let tempDegrees: IDropDownOption[] = []

        try {
            let res = await baseService.getDegrees()
            res.data.payload.items.forEach(country => {
                tempDegrees.push({
                    label: country.title,
                    value: country.id
                })
            })
            setDegrees(tempDegrees)
        } catch (error) {
            console.log(error);
        }
    }

    const getListOfCities = async (id: number) => {
        let tempCities: IDropDownOption[] = []
        try {
            let res = await baseService.getCities(id,true)
            if (res.data.payload.items.length > 0) {
                res.data.payload.items.forEach(city => {
                    tempCities.push({
                        label: city.title,
                        value: city.id
                    })
                })
            }
            setCities(tempCities)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        setValue('city', -1)
        getListOfCities(selectedCountry)
    }, [selectedCountry])

    useEffect(() => {
        if (show) {
            getListOfCountries()
            getListOfDegrees()
        } else {
            reset({
                major: education.major,
                universityName: education.universityName,
                degree: education.degree.id,
                city: education.city.id,
                startAt: education?.startAt ? new Date(education?.startAt) : new Date(),
                endAt: education?.endAt ? new Date(education?.endAt) : new Date(),
            })
        }
    }, [show])

    return (
        <div
            className="flex flex-row align-items-center justify-content-between p-2 m-1"
            style={{
                position: 'relative'
            }}
        >
            <div className="flex flex-column">
                <div className="flex flex-row">
                    <span className="education-card-title">{education.major}</span>

                </div>
                <div className="flex flex-row">
                    <span className="education-card-degree-title">
                        {education.degree.title} - {education.universityName} - {education.city.country.title}
                    </span>
                </div>
                <div className="flex flex-row">
                    <span className="education-card-date-title">
                        {dayjs(education.startAt).format('MMM YYYY')} - {dayjs(education.endAt).format('MMM YYYY')}
                    </span>
                </div>
            </div>
            <div className="flex flex-row-reverse align-items-center">
                <div
                    className={"linked-account-edit-button cursor-pointer"}
                    onClick={() => setShow(true)}
                >
                    <i
                        className={"pi pi-pencil"}
                        style={{ 'fontSize': '1em'}}
                    />
                </div>
            </div>
            <Modal
                ariaHideApp={false}
                style={modalStyle}
                contentLabel={"Update education"}
                isOpen={show}
                onRequestClose={() => setShow(false)}
            >
                <div className="flex flex-row justify-content-between">
                    <span style={{ fontWeight: '600' }}>Update education</span>
                    <div className="p-1 cursor-pointer" onClick={() => setShow(false)}>
                        <i
                            className={"pi pi-times"}
                            style={{ 'fontSize': '1em', color: 'var(--black)' }}
                        />
                    </div>
                </div>
                <form className="mt-5" noValidate onSubmit={handleSubmit(onFormSubmit)}>
                    <FormFieldWrapper>
                        <label htmlFor="major" className={classNames({ 'p-error': errors.major })}>Major</label>
                        <Controller name="major" control={control} render={({ field }) => (
                            <InputText autoFocus type="text" {...field} id="major" className={classNames('block w-full', { 'p-invalid': !!errors.major })} />
                        )} />
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="universityName" className={classNames({ 'p-error': errors.universityName })}>University Name</label>
                        <Controller name="universityName" control={control} render={({ field }) => (
                            <InputText autoFocus type="text" {...field} id="universityName" className={classNames('block w-full', { 'p-invalid': !!errors.universityName })} />
                        )} />
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="degree" className={classNames({ 'p-error': errors.degree })}>Degree</label>
                        <Controller name="degree" control={control} render={({ field }) => (
                            <Dropdown
                                options={degrees}
                                style={{ width: '100%' }}
                                {...field}
                            />
                        )} />
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="country" className={classNames({ 'p-error': errors.country })}>country</label>
                        <Dropdown
                            options={countries}
                            value={selectedCountry}
                            onChange={e => setSelectedCountry(e.value)}
                            style={{ width: '100%' }}
                        />
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="city" className={classNames({ 'p-error': errors.city })}>City</label>
                        <Controller name="city" control={control} render={({ field }) => (
                            <Dropdown
                                options={cities}
                                style={{ width: '100%' }}
                                {...field}
                            />
                        )} />
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="startAt" className={classNames({ 'p-error': errors.startAt })}>Start At</label>
                        <Controller name="startAt" control={control} render={({ field }) => (
                            <Calendar style={{ width: '100%' }} id='startAt' {...field} />
                        )} />
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="endAt" className={classNames({ 'p-error': errors.endAt })}>End At</label>
                        <Controller name="endAt" control={control} render={({ field }) => (
                            <Calendar style={{ width: '100%' }} id='endAt' {...field} />
                        )} />
                    </FormFieldWrapper>
                    <FormFooterActions>
                        <Button label={'Update'} className="p-button-primary" type="submit" loading={loading} />
                        <Button label={'Cancel'} className="p-button-danger ml-2" onClick={() => setShow(false)} />
                    </FormFooterActions>
                </form>
            </Modal>
        </div>
    )
}