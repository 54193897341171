import { ICouponAmountType, ICouponAppliedTo } from "src/api/types/coupon";
import { boolean, date, mixed, number, object, string } from "yup";

export const couponFormValidationSchema = (t: any) =>
  object().shape({
    codes: string().required(
      t("validationMessages.fieldIsRequired", { field: "Code" }),
    ),
    amountType: mixed()
      .oneOf([ICouponAmountType.PERCENTAGE, ICouponAmountType.FIXED])
      .required(),
    // amountValue: number()
    //   .min(1)
    //   .when("amountType", {
    //     is: (value: string) => value && value === ICouponAmountType.PERCENTAGE,
    //     then: number()
    //       .min(1)
    //       .test(
    //         "check-range",
    //         "You must enter a number between 1 to 100 when Amount Type is PERCENTAGE",
    //         (value: any) => value > 0 && value <= 100,
    //       )
    //       .required(),
    //     otherwise: number()
    //       .min(1)
    //       .test("check-value", (value: any) => value > 0)
    //       .required(),
    //   }),
    startDateHasSpecifiedValue: boolean().required(),
    startDate: string().when("startDateHasSpecifiedValue", {
      is: (value: boolean) => value,
      then: string().required(),
      otherwise: string().nullable(),
    }),
    expireDateHasSpecifiedValue: boolean().required(),
    expireDate: string().when("expireDateHasSpecifiedValue", {
      is: (value: boolean) => value,
      then: string().required(),
      otherwise: string().nullable(),
    }),
    appliedTo: mixed()
      .oneOf([ICouponAppliedTo.ALL, ICouponAppliedTo.FIRST_CONTRACT])
      .required(),
    usagePerUserHasSpecifiedValue: boolean().required(),
    usagePerUser: number()
      .min(1)
      .nullable()
      .when("usagePerUserHasSpecifiedValue", {
        is: (value: boolean) => !value,
        then: number().min(1).required(),
        otherwise: number().nullable(true),
      }),
    usageLimitHasSpecifiedValue: boolean().required(),
    usageLimit: number()
      .min(1)
      .nullable()
      .when("usageLimitHasSpecifiedValue", {
        is: (value: boolean) => !value,
        then: number().min(1).required(),
        otherwise: number().nullable(true),
      }),
    description: string().trim().nullable(),
    minimumPackagePrice: number().min(0).required(),
    platforms: mixed().test(
      "check-empty",
      "Please select at least one Platform",
      (value) => value && value.length > 0,
    ),
  });
