import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useGeneralLoading, useUser } from "src/context/global-context";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import GeneralLayoutWrapper from "../components/GeneralLayoutWrapper";
import DashboardContent from "./components/DashboardContent";


const DashboardLayoutContent: React.FC = () => {
    const user = useUser();

    if(!user){
        return <Navigate to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE}/>
    }

    return <div>
        <DashboardContent>
            <Outlet />
        </DashboardContent>
    </div>
}

const DashboardLayout: React.FC = () => {
    const generalLoading = useGeneralLoading();
    return <GeneralLayoutWrapper>
        {!generalLoading && <DashboardLayoutContent />}
    </GeneralLayoutWrapper>
}

export default DashboardLayout;