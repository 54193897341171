import { Column } from "primereact/column";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import React, { useCallback, useEffect, useState } from "react";
import { financialService } from "src/api/services/financial";
import { transactionAmount, transactionContractId, transactionDate, transactionTitle, transactionType } from "src/components/App/TableHelpers";
import { DEFAULT_PAGE_SIZE } from "src/constants/pagination";
import { getAxiosError } from "src/utils/get-axios-error";
import { toast } from "src/utils/toast";
import { useUserDetailsTransactionsAndContracts } from "../../UserDetailsTransactionsAndContractsContext";
import { Card } from "primereact/card";
import { useListCSVDownload } from "src/hooks/use-list-csv-download";
import { IGetListBody } from "src/api/types/base-data";
import SortAndFilters from "src/components/App/SortAndFilters";
import { filterListNames } from "src/constants/filter-list-names";

const ContractTransactionsList: React.FC = () => {
    const { transactions, setTransactions, userType, userId, transactionsLazyParams, setTransactionsLazyParams } = useUserDetailsTransactionsAndContracts();
    const [loading, setLoading] = useState(false);

    const { exportLoading, exportResult } = useListCSVDownload(financialService.exportTransactions, 'financial_transactions');

    const [filters, setFilters] = useState<IGetListBody>();

    const onExportClicked = (data: IGetListBody) => {
        const tempFilters = { ...data };
        if (tempFilters.filters) {
            if (userType === 'freelancer') {
                tempFilters.filters.freelancer = userId;
            } else {
                tempFilters.filters.customer = userId;
            }
        } else {
            tempFilters.filters = {
                ...(userType === "freelancer" ? { freelancer: userId } : { customer: userId })
            }
        }
        exportResult(tempFilters);
    }

    const fetchTransactions = useCallback(async () => {
        try {
            setLoading(true);

            const response = await financialService.getAllTransactions({
                filters: {
                    ...(userType === "freelancer" ? { freelancer: userId } : { customer: userId })
                },
                skip: transactionsLazyParams.page! * DEFAULT_PAGE_SIZE,
                take: (transactionsLazyParams.page! + 1) * DEFAULT_PAGE_SIZE
            });
            setTransactions(response.data.payload);
        } catch (err) {
            const error = getAxiosError(err);
            toast.error(error.meta?.message!);
        } finally {
            setLoading(false);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [transactionsLazyParams,filters])

    useEffect(() => {
        fetchTransactions();
    }, [fetchTransactions]);

    const onPageChange = (e: DataTablePFSEvent) => {
        setTransactionsLazyParams(e);
    }

    const onSortAndFiltersChanged = (data: IGetListBody) => {
        setFilters(data);

        setTransactionsLazyParams({
            ...transactionsLazyParams,
            page: 0,
            first: 1
        })
    }

    return <div className="listing-page">
        <SortAndFilters listName={filterListNames.TRANSACTIONS} onChange={onSortAndFiltersChanged}
            exportLoading={exportLoading}
            onExportClicked={onExportClicked}
            ignoreFilterItem={userType} />
        <div className="q-data-table">
            <Card>
                <DataTable value={transactions?.items} emptyMessage="No Transaction found." loading={loading}
                    lazy
                    first={transactionsLazyParams.first}
                    paginator
                    rows={DEFAULT_PAGE_SIZE}
                    totalRecords={transactions?.pagination.totalItems}
                    onPage={onPageChange}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    dataKey="id"
                    className="qud-table">
                    <Column field="contract id" header="Contract ID" body={transactionContractId} />
                    <Column field="amount" header="Amount" body={transactionAmount} />
                    <Column field="type" header="Type" body={transactionType} />
                    <Column field="title" header="Title" body={transactionTitle} />
                    <Column field="date" header="Date" body={transactionDate} />
                </DataTable>
            </Card>
        </div>
    </div>
}

export default ContractTransactionsList;