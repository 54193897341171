import { AxiosError } from "axios";
import { IAxiosResponse } from "src/api/types/axios-response";

//TODO: work on exception handler and error types based on API response
export function getAxiosError(err: any): IAxiosResponse<{}> {
    const error = err as AxiosError;
    if (error.isAxiosError) {
        if (error.response?.data) {
            return error.response?.data as IAxiosResponse<{}>
        }
        return {
            meta: {
                message: error.message,
                code: 500,
                messageType: "error"
            },
            payload:{}
        }
    } else {
        return {
            meta: {
                message: error.message,
                code: 500,
                messageType: "error"
            },
            payload:{}
        }
    }
}