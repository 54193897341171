import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import Task from "../Task";
import { TOrderedStarItem } from "src/types/dynamic-home";

type Props = {
  items: TOrderedStarItem[];
  title: string;
  removeFromOrderedJob: (jobId: number) => void;
};
const Column = ({ title, items, removeFromOrderedJob }: Props) => {
  return (
    <div>
      <h2 className="font-semibold text-lg mb-3">{title}</h2>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((task) => (
          <Task
            key={task.id}
            item={task}
            removeFromOrderedJob={removeFromOrderedJob}
          />
        ))}
      </SortableContext>
    </div>
  );
};

export default Column;
