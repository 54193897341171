import React, { useState } from "react";
import Card from "src/components/Kit/Card";
import CardBody from "src/components/Kit/Card/CardBody";
import styles from "./ChangePassword.module.css";
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePasswordValidationSchema } from "./change-password-validation-schema";
import { IChangePasswordFormData } from "./types";
import { classNames } from "primereact/utils";
import FormFieldError from "src/components/Kit/FormFieldError";
import { getAxiosError } from "src/utils/get-axios-error";
import { toast } from "src/utils/toast";
import { authService } from "src/api/services/auth";

const ChangePassword: React.FC = () => {
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors, isValid } } = useForm<IChangePasswordFormData>({
            resolver: yupResolver(changePasswordValidationSchema()),
            mode: "all",
            defaultValues: {
                currentPassword: "",
                newPassword: "",
                confirmPassword: ""
            }
        });

    const [loading, setLoading] = useState(false);

    const onFormSubmit = async (data: IChangePasswordFormData) => {
        try {
            setLoading(true);
            await authService.changePassword(data.currentPassword, data.newPassword);
            toast.success("Password successfuly changed");
            reset({
                currentPassword: "",
                newPassword: "",
                confirmPassword: ""
            });
        } catch (err) {
            const error = getAxiosError(err);
            toast.error(error.meta?.message!);
        } finally {
            setLoading(false);
        }
    }

    return <Card className={styles["change-password"]}>
        <CardBody>
            <h4>
                Change Password
            </h4>
            <div className={styles.content}>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <FormFieldWrapper>
                        <label htmlFor="currentPassword" className={classNames({ 'p-error': errors.currentPassword })}>Current Password</label>
                        <Controller name="currentPassword" control={control} render={({ field }) => (
                            <InputText type="password" {...field} id="currentPassword"
                                className={classNames('block w-full', { 'p-invalid': !!errors.currentPassword })} />
                        )} />
                        {
                            errors.currentPassword && <FormFieldError>{errors.currentPassword.message}</FormFieldError>
                        }
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="newPassword" className={classNames({ 'p-error': errors.newPassword })}>New Password</label>
                        <Controller name="newPassword" control={control} render={({ field }) => (
                            <InputText type="password" {...field} id="newPassword"
                                className={classNames('block w-full', { 'p-invalid': !!errors.newPassword })} />
                        )} />
                        {
                            errors.newPassword && <FormFieldError>{errors.newPassword.message}</FormFieldError>
                        }
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <label htmlFor="confirmPassword" className={classNames({ 'p-error': errors.confirmPassword })}>Confirm Password</label>
                        <Controller name="confirmPassword" control={control} render={({ field }) => (
                            <InputText type="password" {...field} id="confirmPassword"
                                className={classNames('block w-full', { 'p-invalid': !!errors.confirmPassword })} />
                        )} />
                        {
                            errors.confirmPassword && <FormFieldError>{errors.confirmPassword.message}</FormFieldError>
                        }
                    </FormFieldWrapper>
                    <FormFieldWrapper>
                        <div>
                            <Button type="submit" className="p-button-primary" 
                                label="Change Password"
                                loading={loading} disabled={!isValid || loading} />
                        </div>
                    </FormFieldWrapper>
                </form>
            </div>
        </CardBody>
    </Card>
}

export default ChangePassword;