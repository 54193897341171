import React from "react";
import styles from "./CardHeader.module.css";

interface ICardHeaderProps{
    children:React.ReactNode;
}

const CardHeader:React.FC<ICardHeaderProps> = (props)=>{
    const {children} = props;
    return <div className={styles["card-header"]}>
        <h2>{children}</h2>
    </div>
}

export default CardHeader;