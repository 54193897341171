import React, { useRef, useState } from "react";
import styles from "./ButtonDropDown.module.css";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { useOnClickOutside } from "src/hooks/user-click-outside";
import { IListItem } from "./types";

interface IButtonDropDownProps {
    items: IListItem[];
    activeItem?: IListItem;
    onChange: (sender: IListItem) => void;
    label:string;
}

const ButtonDropDown: React.FC<IButtonDropDownProps> = (props) => {
    const { items, label,activeItem, onChange } = props;
    const [itemsOpened, setItemsOpened] = useState<boolean>(false);

    const elementRef = useRef(null)
    useOnClickOutside(elementRef, () => {
        setItemsOpened(false)
    })

    const toggleItems = () => {
        setItemsOpened(!itemsOpened);
    }

    const onItemClicked = (sender:IListItem)=>{
        onChange(sender);
        setItemsOpened(false);
    }


    return <div className={styles["button-drop-down"]} ref={elementRef}>
        <Button label={activeItem?.title || label} icon="ph-funnel-simple" iconPos="left" className="p-button-info mt-2"
            
            onClick={toggleItems} />
        <ul className={classNames(styles.items, { [styles.opened]: itemsOpened })}>
            {
                items.map((item, key) => (<li key={key}>
                    <button type="button" className="p-2" onClick={()=>{onItemClicked(item)}}>{item.title}</button>
                </li>))
            }
        </ul>
    </div>
}

export default ButtonDropDown;