import React, { useEffect, useState } from "react"
import './styles.css'
import { modalStyle } from 'src/constants/modal-styles'
import Modal from 'react-modal';
import FormFooterActions from "src/components/Kit/FormFooterActions";
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { freelancerService } from "src/api/services/freelancer"
import {  ILanguageType } from "src/api/types/base-data"
import { IBaseUser } from "src/api/types/user";
import { toast } from "src/utils/toast";
import Card from "src/components/Kit/Card";
import CardHeader from "src/components/Kit/Card/CardHeader";
import CardBody from "src/components/Kit/Card/CardBody";
import { baseService } from "src/api/services/base";

interface IDropDownOption {
    label: string;
    value: number
}

interface ILanguagesProps {
    userData: IBaseUser;
}

const Languages: React.FC<ILanguagesProps> = ({userData}) => {
    const [languages, setLanguages] = useState(userData?.languages || [])
    
    const handleUpdateLanguages = (id: number, newData: ILanguageTempData, levelTitle: string, languageTitle: string) => {
        const tempLanguages = languages.map(obj => ({...obj}));
        const tempInd = tempLanguages.findIndex(tl=>tl.id === id);
        tempLanguages[tempInd].language.ml_title.en = languageTitle;
        tempLanguages[tempInd].language.id = newData.languageId;
        tempLanguages[tempInd].level.id = newData.levelId;
        tempLanguages[tempInd].level.ml_title.en = levelTitle;
        setLanguages(tempLanguages)
    }

    return (
        <Card className="mt-4">
            <CardHeader>Languages</CardHeader>
            <CardBody>
            <div className="flex flex-row flex-wrap">
                {languages.length > 0
                    ? languages.map(languageType =>
                        <LanguageCard
                            key={languageType.id}
                            languageType={languageType}
                            userId={userData!.id}
                            onUpdateList={handleUpdateLanguages}
                        />
                    )
                    : <div style={{ width: '100%' }} className='flex justify-content-center'><span>No Languages added yet!</span></div>
                }
            </div>
            </CardBody>
        </Card>
    )
}

export default Languages;

interface ILanguageCarProps {
    userId: number;
    languageType: ILanguageType;
    onUpdateList: (id: number, tempData: ILanguageTempData, levelTitle: string, languageTitle: string) => void
}

interface ILanguageTempData {
    languageId: number;
    levelId: number;
}

const LanguageCard: React.FC<ILanguageCarProps> = ({ languageType, userId, onUpdateList }) => {
    const [show, setShow] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [languageOption, setLanguageOption] = useState<IDropDownOption[]>([])
    const [levelOptions, setLevelOptions] = useState<IDropDownOption[]>([])
    const [selectedLanguage, setSelectedLanguage] = useState<number>(languageType.language.id)
    const [selectedLevel, setSelectedLevel] = useState<number>(languageType.level.id)

    const handleUpdateLanguage = async () => {
        let levelTitle, languageTitle
        const tempData = {
            languageId: selectedLanguage,
            levelId: selectedLevel
        }

        if(levelOptions.length > 0 ) levelTitle = levelOptions.find(d => d.value === tempData.levelId)!.label
        if(languageOption.length > 0 ) languageTitle = languageOption.find(d => d.value === tempData.languageId)!.label
        
        try {
            setLoading(true)
            await freelancerService.editLanguage(tempData, userId, languageType.id)
            setShow(false)
            setLoading(false)
            onUpdateList(languageType.id, tempData, levelTitle || '', languageTitle || '')
            toast.success("Language updated successfully!");
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    const getData = async () => {
        try {
            Promise.all([baseService.getLanguages(), baseService.getSkillLevels()]).then((values) => {
                const tempLanguages: IDropDownOption[] = []
                const tempLevels: IDropDownOption[] = []

                values[0].data.payload.items.forEach(language => {
                    tempLanguages.push({
                        label: language.title,
                        value: language.id
                    })
                })
                values[1].data.payload.items.forEach(level => {
                    tempLevels.push({
                        label: level.title,
                        value: level.id
                    })
                })
                setLanguageOption(tempLanguages)
                setLevelOptions(tempLevels)
            })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (show) getData()
    }, [show])

    return (
        <>
            <div 
                onClick={()=> setShow(true)} 
                className="flex flex-row align-items-center justify-content-center cursor-pointer language-card-wrapper"
            >
                <span className="language-card-title">{languageType.language.ml_title.en}</span>
                <span className="language-card-level-title">{`(${languageType.level.ml_title.en})`}</span>
            </div>
            <Modal
                ariaHideApp={false}
                style={modalStyle}
                contentLabel={"Update linked account"}
                isOpen={show}
                onRequestClose={() => setShow(false)}
            >
                <div className="flex flex-row justify-content-between">
                    <span style={{ fontWeight: '600' }}>Edit Language</span>
                    <div className="p-1 cursor-pointer" onClick={() => setShow(false)}>
                        <i
                            className={"pi pi-times"}
                            style={{ 'fontSize': '1em', color: 'var(--black)' }}
                        />
                    </div>
                </div>
                <div className="flex flex-column mb-4 mt-4">
                    <Dropdown
                        value={selectedLanguage}
                        options={languageOption}
                        onChange={(e) => setSelectedLanguage(e.value)}
                    />
                    <Dropdown
                        value={selectedLevel}
                        options={levelOptions}
                        onChange={(e) => setSelectedLevel(e.value)}
                        className="mt-4"
                    />
                </div>
                <FormFooterActions>
                    <Button label={'Update'} className="p-button-primary" loading={loading} onClick={handleUpdateLanguage} />
                    <Button label={'Cancel'} className="p-button-danger ml-3" onClick={() => setShow(false)} />
                </FormFooterActions>
            </Modal>
        </>
    )
}


