const notificationContainerStyles = `
    position:fixed;
    bottom:20px;
    left:0;
    z-index:1410;
    width:100%;
    height:0;
    display:flex;
    flex-direction: column-reverse;
`;

const notificationStyles = `
    margin-top:16px;
    margin-left:50%;
    transform:translateX(-50%);
    border-radius:8px;
    width:300px;
    max-width:100%;
    padding:16px;
    box-shadow:0 0 10px 1px rgba(0,0,0,0.4);
`;

const errorStyles = `
    background-color:#EA5163;
`;

const successStyles = `
    background-color:#1ec699;
`;

const connectionErrorStyles = `
  max-width:400px;
  background-color:#e97c7c;
  box-shadow:0 1px 4px #ccc;
  position:fixed;
  top:30px;
  left:0;
  right:0;
  margin: 0 auto;
  display:block;
  padding: 12px;
  border-radius:10px;
  font-size:0.875rem;
`;

const checkParent = (cb: (parent: HTMLElement) => void) => {
  let toastContainer = document.getElementById('lmToastContainer');
  if (!toastContainer) {
    toastContainer = document.createElement('div');
    toastContainer.setAttribute('id', 'lmToastContainer');
    toastContainer.setAttribute('style', notificationContainerStyles);
    document.body.appendChild(toastContainer);
  }
  cb(toastContainer);
};

export const toast = {
  success(message: string) {
    checkParent((parent: HTMLElement) => {
      const notificationElement = document.createElement('div');
      notificationElement.setAttribute('style', notificationStyles + successStyles);
      parent.appendChild(notificationElement);
      notificationElement.innerHTML = message;
      setTimeout(() => {
        parent.removeChild(notificationElement);
      }, 5000);
    });
  },
  error(message: string) {
    checkParent((parent: HTMLElement) => {
      const notificationElement = document.createElement('div');
      notificationElement.setAttribute('style', notificationStyles + errorStyles);
      parent.appendChild(notificationElement);
      notificationElement.innerHTML = message;
      setTimeout(() => {
        parent.removeChild(notificationElement);
      }, 5000);
    });
  },
  connectionError(message:string) {
    const alertTemp = document.createElement('div');
    alertTemp.setAttribute('style' , connectionErrorStyles);
    alertTemp.innerHTML = `<span>${message}</span>`;
    document.body.appendChild(alertTemp);
    setTimeout(() => {
      document.body.removeChild(alertTemp);
    }, 5000);
  }
};