import { string, number, object, array } from "yup";

export const editServiceValidationSchema = () =>
  object().shape({
    title: string().required("Title is required").min(2).max(200),
    description: string().required("Description is required").min(15).max(800),
    revisions: string()
      .test("checkIfHasError", "it must be at least 1 ", function (value) {
        const revisionNumber = Number(value);
        if (
          revisionNumber &&
          this.parent.limitedRevisions &&
          revisionNumber < 1
        )
          return false;
        return true;
      })
      .test("checkIfNumber", "please enter a valid number", function (value) {
        if (this.parent.limitedRevisions && !Number(value)) return false;
        return true;
      }),
    category: number().required("Category is required"),
  });
