import { CustomerJobsProvider } from "./CustomerJobsContext";
import Jobs from "./components/Jobs";

const CustomerJobs = () => {
  return (
    <div>
      <CustomerJobsProvider>
        <Jobs />
      </CustomerJobsProvider>
    </div>
  );
};

export default CustomerJobs;
