import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { adminService } from "src/api/services/admin";
import { IBaseUser } from "src/api/types/user";
import ListingPage from "src/components/App/ListingPage";
import { dateCreatedAtBodyTemplate, emailBodyTemplate, emailVerifiedBodyTemplate, roleBodyTemplate, statusTemplate } from "src/components/App/TableHelpers";
import { DEFAULT_PAGE_SIZE } from "src/constants/pagination";
import { useTitle } from "src/hooks/useTitle";
import NewAdministrator from "./components/NewAdministrator";
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { Button } from "primereact/button";
import { userService } from "src/api/services/user";
import { classNames } from "primereact/utils";
import SortAndFilters from "src/components/App/SortAndFilters";
import { filterListNames } from "src/constants/filter-list-names";
import { IGetListBody } from "src/api/types/base-data";


const Administrators: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitles.administrators"))

    const [activationTargetLoading,setActivationTargetLoading] = useState<number>(-1);
    const [loading, setLoading] = useState<boolean>(false);
    const [admins, setAdmins] = useState<IBaseUser[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filters,setFilters] = useState<IGetListBody>()
    const [lazyParams, setLazyParams] = useState<DataTablePFSEvent>({
        filters: {

        },
        first: 0,
        multiSortMeta: [],
        page: 0,
        pageCount: 2,
        rows: DEFAULT_PAGE_SIZE,
        sortField: "",
        sortOrder: null,
    });

    const fetchAdmins = useCallback(async () => {
        try {
            setLoading(true);
            const body:IGetListBody = !!filters ? {
                ...filters,
                ...{
                    skip: lazyParams.page! * DEFAULT_PAGE_SIZE,
                    take: DEFAULT_PAGE_SIZE
                }
            } : {
                skip: lazyParams.page! * DEFAULT_PAGE_SIZE,
                take: DEFAULT_PAGE_SIZE
            }
            const result = await adminService.getAll(body);
            setTotalRecords(result.data.payload.pagination.totalItems);
            setAdmins(result.data.payload.items);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, [lazyParams,filters]);

    useEffect(() => {
        fetchAdmins();
    }, [fetchAdmins]);

    const onPageChange = (e: DataTablePFSEvent) => {
        setLazyParams(e);
    }

    const onNewAdminAdded = (data: IBaseUser) => {
        if (lazyParams.page === 0) {
            setAdmins([
                data,
                ...admins
            ])
        }
    }

    const changeUserActivation = (rowData: IBaseUser) => {
        confirmDialog({
            message: `Are you sure you want to ${rowData.status === 'Active' ? "deactivate" : "activate"} ${rowData.email}`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                try {
                    const tempAdmins = [...admins];
                    setActivationTargetLoading(rowData.id);
                    await userService.changeActivation(rowData.id, rowData.status !== "Active")
                    const targetItemIndex = tempAdmins.indexOf(rowData);
                    tempAdmins[targetItemIndex].status = rowData.status === "Active" ? "Inactive" : "Active";
                    setAdmins(tempAdmins);
                } catch (err) {
                    console.log(err);
                } finally {
                    setActivationTargetLoading(-1);
                }
            }
        })
    }

    const changeActivationTemplate = (rowData: IBaseUser) => {
        return <>
            <Button
                label={t(rowData.status === 'Active' ? "actions.deactive" : "actions.active")}
                className={classNames("p-button-outlined p-button-sm",{"p-button-danger":rowData.status === "Active","p-button-secondary":rowData.status!=="Active"})}
                type="button"
                loading={activationTargetLoading === rowData.id}
                onClick={() => { changeUserActivation(rowData) }}
            />
        </>
    }

    const onSortAndFiltersChanged =  (data:IGetListBody)=>{
        setFilters(data);
        setLazyParams({
            ...lazyParams,
            page:0,
            first:1
        })
    }

    return <>
        <ListingPage title={t("titles.administrators")}>
            <NewAdministrator onNewAdminAdded={onNewAdminAdded} />
            <SortAndFilters listName={filterListNames.ADMINISTRATORS} onChange={onSortAndFiltersChanged}/>
            <Card>
                <DataTable value={admins} emptyMessage="No Administrators found." loading={loading}
                    lazy
                    first={lazyParams.first}
                    paginator
                    rows={DEFAULT_PAGE_SIZE}
                    totalRecords={totalRecords}
                    onPage={onPageChange}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    dataKey="id" 
                    className="qud-table" >
                    <Column field="id" header="ID" />
                    <Column field="email" header="Email Address" body={emailBodyTemplate} />
                    <Column field="role" header="Role" body={roleBodyTemplate} />
                    <Column field="status" header="Status" body={statusTemplate}/>
                    <Column field="emailVerified" header="Email Verified" body={emailVerifiedBodyTemplate} />
                    <Column field="createdAt" header="Created At" body={dateCreatedAtBodyTemplate} />
                    <Column header="" body={changeActivationTemplate} />
                </DataTable>
            </Card>
        </ListingPage>
        <ConfirmDialog />
    </>
}

export default Administrators;