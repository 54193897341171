import { useCallback, useEffect, useState } from "react";
import ListingPage from "src/components/App/ListingPage";
import { dynamicHomeService } from "../../../../api/services/dynamic-home";
import { mapFetchedSetting, TSetting } from "./helper";
import RelocationData from "./RelocationData";

export type TaskType = { id: number; title: string };

const Payments: React.FC = () => {
  const [setting, setSetting] = useState<TSetting>();
  const [loading, setLoading] = useState(false);

  const fetchSetting = useCallback(async () => {
    try {
      setLoading(true);

      const result = await dynamicHomeService.getSetting();
      const data = mapFetchedSetting(result.data.payload);
      setSetting(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSetting();
  }, [fetchSetting]);

  return (
    <>
      <ListingPage title={"Relocation"}>
        {setting && <RelocationData setting={setting} />}
      </ListingPage>
    </>
  );
};

export default Payments;
