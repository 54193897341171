import React, { useCallback, useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import { freelancerService } from "src/api/services/freelancer";
import { IFreelancerServicePackage, IFreelancerServices } from "src/api/types/base-data";
import { contextFactory } from "src/utils/context-factory";
import { IFreelancerServiceDetailsContext, IFreelancerServiceDetailsProps } from "./type";

export const [FreelancerServiceDetailsContext, useFreelancerServiceDetails] = contextFactory<IFreelancerServiceDetailsContext>("FreelancerServiceDetailsContext", "useFreelancerServiceDetails");

export const FreelancerServiceDetailsProvider: React.FC<IFreelancerServiceDetailsProps> = (props) => {
    const { children } = props;
    const { serviceId } = useParams<{ serviceId: string }>();

    const [serviceDetails, setServiceDetails] = useState<IFreelancerServices>();
    const [editingPackageItem, setEditingPackageItem] = useState<IFreelancerServicePackage>();
    const [loading, setLoading] = useState<boolean>(false);

    const getServiceDetails = useCallback(async () => {
        if (serviceId) {
            try {
                setLoading(true);
                let result = await freelancerService.getServiceDetails(parseInt(serviceId))
                setServiceDetails(result.data.payload)
            } catch (error) {
                console.log(error)
                setLoading(false)
            } finally {
                setLoading(false);
            }
        }
    }, [serviceId]);

    useEffect(() => {
        getServiceDetails()
    }, [getServiceDetails]);

    const closeEditPackageModal = ()=>{
        setEditingPackageItem(undefined);
    }

    return <FreelancerServiceDetailsContext.Provider value={{
        serviceDetails,
        setServiceDetails,
        editingPackageItem,
        setEditingPackageItem,
        loading,
        closeEditPackageModal
    }}>{children}</FreelancerServiceDetailsContext.Provider>
}

