import React from 'react'
import { FreelancerServicesProvider } from './FreelancerServicesContext'
import './styles.css'
import FreelancerServicesComponent from './components/Services'

const FreelancerServices = () => {
    return (
        <FreelancerServicesProvider>
            <FreelancerServicesComponent />
        </FreelancerServicesProvider>
    )
}
export default FreelancerServices
