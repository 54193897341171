import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import { IAxiosResponse } from "../types/axios-response";

export const userService = {
    changeActivation(id:number,active: boolean){
        return AxiosInstance.put<IAxiosResponse<{}>>
            (API_ROUTES.USER.CHANGE_ACTIVATION(id), {
                active
            });
    },
};