import { string, object, ref } from "yup";

//todo: translations removed from the function params, add this please.
export const changePasswordValidationSchema = () => object().shape({
    currentPassword: string()
        .required("Current Password is required"),
    newPassword: string()
        .required("New Password is required")
        .min(6),
    confirmPassword: string().oneOf([ref('newPassword'), null], 'Passwords must match')
})