import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";

const BackButton: React.FC = () => {
    const navigate = useNavigate()

    return (
        <div className="layout-back-button" onClick={() => navigate(-1)}>
            <i className="pi pi-arrow-left mr-2" style={{'fontSize': '1.2em'}}></i>
        </div>
    )
}

export default BackButton;