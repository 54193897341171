import React from "react";
import { Link } from "react-router-dom";
import LogoLink from "src/components/App/Logo/LogoLink";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import "./styles.css";

const Navbar:React.FC = ()=>{
    return <div className="general-navbar pt-2 pb-2 pl-4 pr-4">
        <div></div>
        <LogoLink />
        <div >
            <Link to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE}>Login</Link>
        </div>
    </div>
}

export default Navbar;