import React, { useCallback, useEffect, useState } from "react";
import { useFreelancerServiceDetails } from "../../FreelancerServiceDetailsContext";
import Modal from 'react-modal';
import { modalStyle } from "src/constants/modal-styles";
import { baseService } from "src/api/services/base";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { editServicePackageValidationSchema } from "./validation-schema";
import { Button } from "primereact/button";
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import { IDropDownOption, IFreelancerServicePackgeBody } from "src/api/types/base-data";
import { getAxiosError } from "src/utils/get-axios-error";
import { toast } from "src/utils/toast";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { freelancerService } from "src/api/services/freelancer";
import { Chips } from 'primereact/chips';


interface IEditPackgeData {
    title: string;
    description: string;
    items: string[];
    deliveryTime: number;
    deliveryTimeUnitId: number;
    revisions?: number;
    price: number;
}


const EditPackageModal: React.FC = () => {
    const { editingPackageItem, closeEditPackageModal, serviceDetails, setServiceDetails } = useFreelancerServiceDetails();
    const [deliveryTimeUnits, setDeliveryTimeUnits] = useState<IDropDownOption[]>([])
    const [unlimitedRevisionsChecked, setUnlimitedRevisionsChecked] = useState<boolean>(editingPackageItem?.revisions ? false : true);
    const { t } = useTranslation();
    const [revisionsError, setRevisionsError] = useState<string>("");
    const [saving, setSaving] = useState<boolean>(false);
    const [uniquePackageItems, setUniquePackageItems] = useState<string[]>(editingPackageItem?.items || []);
    const [itemsError, setItemsError] = useState<string>("");

    const { control, handleSubmit, formState: { errors } } = useForm<IEditPackgeData>({
        defaultValues: {
            title: editingPackageItem?.title || "",
            description: editingPackageItem?.description || "",
            items: editingPackageItem?.items || [],
            deliveryTime: editingPackageItem?.deliveryTime,
            deliveryTimeUnitId: editingPackageItem?.deliveryTimeUnit.id,
            revisions: editingPackageItem?.revisions,
            price: editingPackageItem?.price ? parseFloat(editingPackageItem.price) : undefined,
        },
        resolver: yupResolver(editServicePackageValidationSchema())
    });

    const onCloseClicked = () => {
        closeEditPackageModal();
    }

    const getTimeUnits = useCallback(async () => {
        try {
            const result = await baseService.getTimeUnits();
            console.log(result.data.payload.items);
            const tempDeliveryTimeUnits: IDropDownOption[] = result.data.payload.items.map(item => ({
                value: item.id,
                label: item.title
            }));
            setDeliveryTimeUnits(tempDeliveryTimeUnits);
        } catch (err) {
            console.log(err);
            const error = getAxiosError(err);
            toast.error(error.meta?.message!);
        }
    }, []);

    useEffect(() => {
        getTimeUnits();
    }, [getTimeUnits]);

    const onFormSubmit = async (data: IEditPackgeData) => {
        const { revisions, ...rest } = data;
        const body: IFreelancerServicePackgeBody = { ...rest, isActive: editingPackageItem?.isActive! };
        let revisionsError = false;
        setItemsError("");
        if (revisions && revisions > 0) {
            body.revisions = revisions;
        } else if (!unlimitedRevisionsChecked) {
            console.log("here")
            setRevisionsError("Revisions is required when unlimited revisions not checked")
            revisionsError = true;
        }
        if (!uniquePackageItems.length) {
            setItemsError("Unique package items is required");
            return;
        }
        if (!revisionsError) {
            if (unlimitedRevisionsChecked) {
                body.revisions = undefined;
            }
            try {
                setSaving(true);
                body.items = uniquePackageItems;
                const result = await freelancerService.editPackage(editingPackageItem?.id!, body);
                toast.success("Package updated successfuly");
                const targetIndex = serviceDetails?.packages.findIndex(p => p.id === editingPackageItem?.id!);
                const tempServiceDetails = { ...serviceDetails! };
                tempServiceDetails.packages![targetIndex!] = {
                    ...result.data.payload
                }
                setServiceDetails(tempServiceDetails!);
                closeEditPackageModal();
            } catch (err) {
                const error = getAxiosError(err);
                toast.error(error.meta?.message!);
            } finally {
                setSaving(false);
            }
        }
    }

    return <Modal
        ariaHideApp={false}
        style={modalStyle}
        contentLabel={"Edit Package"}
        isOpen={!!editingPackageItem}
        onRequestClose={onCloseClicked}>
        <div className="flex flex-row justify-content-between">
            <span style={{ fontWeight: '600' }}>Edit Package</span>
            <div className="p-1 cursor-pointer" onClick={onCloseClicked}>
                <i
                    className={"pi pi-times"}
                    style={{ 'fontSize': '1em', color: 'var(--black)' }}
                />
            </div>
        </div>
        <div>
            <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
                <FormFieldWrapper>
                    <label htmlFor="title" className={classNames({ 'p-error': errors.title })}>{t("fields.title")}</label>
                        <Controller name="title" control={control} render={({ field }) => (
                            <InputText autoFocus type="text" {...field} id="title" className={classNames('block w-full', { 'p-invalid': !!errors.title })} />
                        )} />
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <label htmlFor="description" className={classNames({ 'p-error': errors.description })}>{t("fields.description")}</label>
                        <Controller name="description" control={control} render={({ field }) => (
                            <InputTextarea autoFocus  {...field} id="description" className={classNames('block w-full', { 'p-invalid': !!errors.description })} />
                        )} />
                </FormFieldWrapper>
                <div className="grid">
                    <div className="col">
                        <FormFieldWrapper>
                            <label htmlFor="deliveryTime" className={classNames({ 'p-error': errors.title })}>{t("fields.deliveryTime")}</label>
                                <Controller name="deliveryTime" control={control} render={({ field }) => (
                                    <InputText autoFocus type="text" {...field} id="deliveryTime"
                                        className={classNames('block w-full', { 'p-invalid': !!errors.deliveryTime })} />
                                )} />
                        </FormFieldWrapper>
                    </div>
                    <div className="col">
                        <FormFieldWrapper>
                            <label htmlFor="deliveryTimeUnit" className={classNames({ 'p-error': errors.deliveryTimeUnitId })}>{t("fields.unit")}</label>
                                <Controller name="deliveryTimeUnitId" control={control} render={({ field }) => (
                                    <Dropdown id={field.name} value={field.value} onChange={(e) => field.onChange(e.value)}
                                        options={deliveryTimeUnits}
                                        className={classNames('element-full-w', { 'p-invalid': !!errors.deliveryTimeUnitId })} />
                                )} />
                        </FormFieldWrapper>
                    </div>
                </div>
                <FormFieldWrapper>
                    <label htmlFor="revisions" className={classNames({ 'p-error': errors.revisions })}>{t("fields.revisions")}</label>
                        <Controller name="revisions" control={control} render={({ field }) => (
                            <InputText autoFocus
                                disabled={unlimitedRevisionsChecked} type="text" {...field} id="revisions"
                                className={classNames('block w-full', { 'p-invalid': !!errors.revisions || !!revisionsError })} />
                        )} />
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <div className="field-checkbox">
                        <Checkbox checked={unlimitedRevisionsChecked} onChange={e => setUnlimitedRevisionsChecked(e.checked)} />
                        <label onClick={() => { setUnlimitedRevisionsChecked(!unlimitedRevisionsChecked) }}>{t("fields.unlimitedRevisions")}</label>
                    </div>
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <label htmlFor="price" className={classNames({ 'p-error': errors.title })}>{t("fields.price")}</label>
                        <Controller name="price" control={control} render={({ field }) => (
                            <InputText autoFocus
                                type="text" {...field} id="price" className={classNames('block w-full', { 'p-invalid': !!errors.price })} />
                        )} />
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <div>Unique Items</div>
                    <Chips value={uniquePackageItems} onChange={(e) => setUniquePackageItems(e.value)}
                        className="block w-full" />
                    {itemsError && <span className="text-red-400 text-sm">{itemsError}</span>}
                </FormFieldWrapper>
                <div>
                    <Button label="Submit" type="submit" loading={saving} disabled={saving} />
                </div>
            </form>
        </div>
    </Modal>
}

export default EditPackageModal;