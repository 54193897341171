import { string, number, object, array } from "yup";

export const editServicePackageValidationSchema = () => object().shape({
    title: string().required("Title is required"),
    description: string().required("Description is required"),
    deliveryTime:number().required("Delivery Time is required"),
    deliveryTimeUnitId:number().required("Delivery Time unit is required"),
    items:array(string()).required("Unique items is required"),
    revisions:number(),
    price:number().required("Price is required"),
})