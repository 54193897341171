import * as qs from 'qs';
import { PathLike } from 'fs';


export const ApiConfig = {
    baseURL:window?.REACT_APP_API_URL || "https://api.dev.qudra.cloud",//process.env.REACT_APP_API_URL,
    headers:{
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    timeout: 10 * 1000,
    paramsSerializer: (params: PathLike) => qs.stringify(params, { indices: false }),
    withCredentials:false
};