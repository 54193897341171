import React from "react";
import Details from "./components/Details";
import { FreelancerDetailsProvider } from "./FreelancerDetailsContext";

const FreelancerDetails: React.FC = () => {
  return (
    <div>
      <FreelancerDetailsProvider>
        <Details />
      </FreelancerDetailsProvider>
    </div>
  );
};

export default FreelancerDetails;
