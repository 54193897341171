import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import {
  IPaginatedRequestBody,
  PaginatedResponse,
} from "../types/axios-response";
import { IContractListItem } from "../types/contracts";

export const jobSectionService = {
  getAll(data?: IPaginatedRequestBody) {
    return AxiosInstance.post<PaginatedResponse<IContractListItem>>(
      API_ROUTES.JOB_SECTION.ROOT,
      data || {},
    );
  },
  export(data?: IPaginatedRequestBody) {
    return AxiosInstance.post<Blob>(API_ROUTES.JOB_SECTION.EXPORT, data || {});
  },
};
