import { useFreelancerServices } from "src/pages/Dashboard/userManagement/Freelancers/FreelancerServices/FreelancerServicesContext";
import "./styles.css";
import { freelancerProfileTabs } from "src/pages/Dashboard/userManagement/Freelancers/constants";
import { useLocation, useNavigate } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import Header from "src/components/App/PageHeader";
import ServiceListItem from "../ServiceListItem";
import EditServiceModal from "../EditServiceModal";
import { ConfirmDialog } from "primereact/confirmdialog";

const FreelancerServicesComponent: React.FC = () => {
  const { services, loading, editingServiceItem, setServices } =
    useFreelancerServices();
  const location = useLocation();
  const currentTab = location.pathname.split("/");
  const navigate = useNavigate();

  const handleChangeTab = (selectedTab: string) => {
    if (selectedTab !== currentTab[currentTab.length - 1]) {
      navigate(
        ROUTE_CONSTANTS.DASHBOARD.FREELANCERS.DETAILS.GET_BY_DATA(
          currentTab[currentTab.length - 2],
        ).ABSOLUTE,
      );
    }
  };
  const onServiceRemovedSuccessFully = (deletedServiceId: number) => {
    if (services) {
      const remainedList = services.filter(
        (item) => item.id !== deletedServiceId,
      );
      setServices(remainedList);
    }
  };
  return !loading ? (
    <>
      <Header
        hasGoBack={true}
        title={`Services`}
        tabs={freelancerProfileTabs}
        currentTab={currentTab[currentTab.length - 1]}
        updateTab={(selectedTab) => handleChangeTab(selectedTab)}
      />
      <div className="grid flex justify-content-center">
        {services && services.length > 0 ? (
          <div className="xl:col-8 lg:col-8 md:col-8 sm:col-12">
            {services.map((service, index) => (
              <ServiceListItem
                key={index}
                data={service}
                onServiceRemovedSuccessFully={onServiceRemovedSuccessFully}
              />
            ))}
          </div>
        ) : (
          <span>There is no services!</span>
        )}
      </div>
      <ConfirmDialog />

      {!!editingServiceItem && <EditServiceModal />}
    </>
  ) : (
    <span>loading....</span>
  );
};
export default FreelancerServicesComponent;
