import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import { IActivityTimeLineItem } from "../types/admin";
import { IAxiosResponse, IPaginatedRequestBody, PaginatedResponse } from "../types/axios-response";
import { IBaseUser } from "../types/user";

export const adminService = {
    create(email:string,role:string){
        return AxiosInstance.post<IAxiosResponse<{
            otpExpires:string;
            user:IBaseUser;
        }>>(API_ROUTES.ADMINISTRATORS.NEW, {
            email,
            role
        });
    },
    getAll(data?:IPaginatedRequestBody){
        return AxiosInstance.post<PaginatedResponse<IBaseUser>>(API_ROUTES.ADMINISTRATORS.ROOT,data || {})
    },
    getActivityLogs(data:any){
        return AxiosInstance.post<PaginatedResponse<IActivityTimeLineItem>>(API_ROUTES.ADMINISTRATORS.TIMELINE, data)
    },
    getDetails(id:string){
        return AxiosInstance.get<IAxiosResponse<IBaseUser>>(`${API_ROUTES.ADMINISTRATORS.ROOT}/${id}`)
    },
};