import { IGetListBody } from "src/api/types/base-data";
import { DEFAULT_PAGE_SIZE } from "src/constants/pagination";

export function getListQueryItems(query:any):IGetListBody{
    let result:IGetListBody = {};
    const {sort,...rest} = query;
    if(sort){
        result.sort = query.sort;
    }
    if(rest && Object.keys(rest).length){
        result.filters = {};
        for(let key in rest){
            if(Array.isArray(rest[key])){
                if(rest[key].length){
                    result.filters[key] = rest[key];
                }
            }else{
                result.filters[key] = rest[key];
            }
        }
    }
    result.skip = 0;
    result.take = DEFAULT_PAGE_SIZE;
    return result;
}