import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IGetListBody } from "src/api/types/base-data";
import { IContractListItem } from "src/api/types/contracts";
import ListingPage from "src/components/App/ListingPage";
import SortAndFilters from "src/components/App/SortAndFilters";
import {
  dateBodyTemlateOfContract,
  emailBodyTemplate,
} from "src/components/App/TableHelpers";
import { filterListNames } from "src/constants/filter-list-names";
import { DEFAULT_PAGE_SIZE } from "src/constants/pagination";
import { useListCSVDownload } from "src/hooks/use-list-csv-download";
import { useTitle } from "src/hooks/useTitle";
import { jobSectionService } from "../../../../api/services/jobSectionService";
import { revisionBodyTemplate } from "../../../../components/App/TableHelpers/index";

const JobSection: React.FC = () => {
  const [contracts, setContracts] = useState<IContractListItem[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const { t } = useTranslation();
  useTitle(t("pageTitles.jobSection"));
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<IGetListBody>();
  const [lazyParams, setLazyParams] = useState<DataTablePFSEvent>({
    filters: {},
    first: 0,
    multiSortMeta: [],
    page: 0,
    pageCount: 2,
    rows: DEFAULT_PAGE_SIZE,
    sortField: "",
    sortOrder: null,
  });

  const { exportLoading, exportResult } = useListCSVDownload(
    jobSectionService.export,
    "jobs",
  );

  const fetchContracts = useCallback(async () => {
    try {
      setLoading(true);
      const body: IGetListBody = !!filters
        ? {
            ...filters,
            ...{
              skip: lazyParams.page! * DEFAULT_PAGE_SIZE,
              take: DEFAULT_PAGE_SIZE,
            },
          }
        : {
            skip: lazyParams.page! * DEFAULT_PAGE_SIZE,
            take: DEFAULT_PAGE_SIZE,
          };
      const result = await jobSectionService.getAll(body);
      setTotalRecords(result.data.payload.pagination.totalItems);
      setContracts(result.data.payload.items);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [lazyParams, filters]);

  useEffect(() => {
    fetchContracts();
  }, [fetchContracts]);

  const onPageChange = (e: DataTablePFSEvent) => {
    setLazyParams(e);
  };

  const onSortAndFiltersChanged = (data: IGetListBody) => {
    setFilters(data);

    setLazyParams({
      ...lazyParams,
      page: 0,
      first: 1,
    });
  };

  console.log("contracts", contracts);
  return (
    <>
      <ListingPage title={t("titles.jobs")}>
        {/* <SortAndFilters
          listName={filterListNames.CONTRACTS}
          onChange={onSortAndFiltersChanged}
          exportLoading={exportLoading}
          onExportClicked={exportResult}
        /> */}
        <Card>
          <DataTable
            value={contracts}
            emptyMessage="No jobs found."
            loading={loading}
            rowClassName={(data: IContractListItem) => {
              return data.needAdminAction ? "highlighted-row" : "";
            }}
            lazy
            first={lazyParams.first}
            paginator
            rows={DEFAULT_PAGE_SIZE}
            totalRecords={totalRecords}
            onPage={onPageChange}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            dataKey="id"
            className="qud-table">
            <Column field="id" header="ID" />
            <Column field="title" header="Title" />
            <Column field="ownerUser.fullName" header="Full Name" />

            <Column
              header="Email"
              body={(job) => emailBodyTemplate(job.ownerUser)}
            />

            <Column field="status" header="Status" />
            <Column field="price" header="Budget" />
            <Column
              header="Revisions"
              body={(job) =>
                revisionBodyTemplate(job.limitedRevisions, job.revisions)
              }
            />
            <Column
              header="Delivery Time"
              body={(job) => {
                return (
                  job.deliveryTime + " " + job.deliveryTimeUnit.ml_title.en
                );
              }}
            />
            <Column field="category.ml_title.en" header="Category" />
            <Column
              field="createdAt"
              header="Created At"
              body={dateBodyTemlateOfContract}
            />
          </DataTable>
        </Card>
      </ListingPage>
    </>
  );
};

export default JobSection;
