import React, { useEffect, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { IBaseDataItem } from "src/api/types/base-data";
import { getFilterItems } from "src/utils/get-filter-items";
import { useSortAndFilters } from "../SortAndFiltersContext";
import styles from "./FilterBoxItem.module.css";

interface IFilterBoxItemProps {
    identifier: string;
    items: IBaseDataItem[];
    idIsTitle?:boolean;
}

const FilterBoxItem: React.FC<IFilterBoxItemProps> = (props) => {
    const { identifier, items } = props;
    const { setFilters, filters} = useSortAndFilters();

    const [selectedItems, setSelectedItems] = useState(getFilterItems(identifier, filters));

    useEffect(()=>{
        setSelectedItems(getFilterItems(identifier, filters) as number[]);
    },[filters,identifier])

    const onItemChanged = (sender: IBaseDataItem) => {
        const tempSelectedItems = [...selectedItems];
        if (tempSelectedItems.includes(sender.id)) {
            const targetIndex = tempSelectedItems.indexOf(sender.id);
            tempSelectedItems.splice(targetIndex, 1);
            setSelectedItems(tempSelectedItems as string[] | number[]);
            setFilters({
                ...filters,
                [identifier]: tempSelectedItems as string[] | number
            })
        } else {
            setSelectedItems([...tempSelectedItems,sender.id] as string[] | number[]);
            setFilters({
                ...filters,
                [identifier]: [...tempSelectedItems, sender.id] as string[] | number
            })
        }
    }

    return <div className={styles["filter-box-item"]}>
        {
            items.map(item => (<div key={item.id} onClick={()=>{onItemChanged(item)}}>
                <Checkbox  
                    checked={selectedItems.includes(item.id as never)}/>
                <label >{item.title}</label>
            </div>))
        }
    </div>
}

export default FilterBoxItem;