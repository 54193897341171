import React from "react";
import styles from "./FreelancerServiceDetails.module.css";
import { IFreelancerServicePackage } from "src/api/types/base-data";
import Header from "src/components/App/PageHeader";
import CustomCard from "src/components/Kit/CustomCard";
import PackageListItem from "./components/PackageListItem";
import EditPackageModal from "./components/EditPackgeModal";
import { classNames } from "primereact/utils";
import {
  FreelancerServiceDetailsProvider,
  useFreelancerServiceDetails,
} from "./FreelancerServiceDetailsContext";
import { ConfirmDialog } from "primereact/confirmdialog";

const DetailsContent: React.FC = () => {
  const { loading, serviceDetails, editingPackageItem, setServiceDetails } =
    useFreelancerServiceDetails();
  if (loading) return <span>loading ...</span>;
  const onPackageRemovedSuccessFully = (deletedPackageId: number) => {
    if (serviceDetails) {
      const remainedList = serviceDetails.packages.filter(
        (item) => item.id !== deletedPackageId,
      );
      setServiceDetails({ ...serviceDetails, packages: remainedList });
    }
  };
  return (
    <>
      <Header hasGoBack={true} title="Service Details" />
      <CustomCard className="flex flex-column">
        <span className={styles["card-title"]}>Service General Details</span>
        <div className="flex flex-column mt-4">
          <div>
            <span style={{ color: "var(--text-mute)" }}>Name</span>
            <span className={classNames(styles.title, "ml-3")}>
              {serviceDetails?.title}
            </span>
          </div>
          <div className="mt-2">
            <span style={{ color: "var(--text-mute)" }}>category</span>
            <span className={classNames(styles["category-title"], "ml-3")}>
              {serviceDetails?.category.title}
            </span>
          </div>
          <div className="mt-2">
            <span style={{ color: "var(--text-mute)" }}>Description</span>
            <span className={classNames(styles["package-description"], "ml-3")}>
              {serviceDetails?.description}
            </span>
          </div>
        </div>
      </CustomCard>
      <div className="flex flex-row flex-wrap">
        {serviceDetails?.packages.map((item: IFreelancerServicePackage) => (
          <PackageListItem
            data={item}
            key={item.id}
            onPackageRemovedSuccessFully={onPackageRemovedSuccessFully}
            hasMoreThanOnePackage={serviceDetails.packages.length > 1}
          />
        ))}
      </div>
      <ConfirmDialog />

      {!!editingPackageItem && <EditPackageModal />}
    </>
  );
};

const FreelancerServiceDetails: React.FC = () => {
  return (
    <FreelancerServiceDetailsProvider>
      <DetailsContent />
    </FreelancerServiceDetailsProvider>
  );
};

export default FreelancerServiceDetails;
