import { FilterType } from "src/api/types/base-data";



type FilterListItem = number[] | string[];

export function getFilterItems(key:string,filters?:Record<string,FilterType>):FilterListItem{
    if(filters){
        const items = filters[key] as FilterListItem;
        if(!!items){
            if(Array.isArray(items)){
                if(typeof(items[0]==="string")){
                    if(!isNaN(parseInt(items[0] as string))){
                        const newItems = items.map(i=>parseInt(i as string));
                        return newItems;
                    }
                    return items;
                }
            }else{
                return [parseInt(items)]
            }
        }
    }
    return [];
}