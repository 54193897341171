import React, { useState } from 'react'
import { modalStyle } from 'src/constants/modal-styles'
import Modal from 'react-modal';
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";
import { Controller, useForm } from "react-hook-form";
import FormFooterActions from "src/components/Kit/FormFooterActions";
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { IAvailableAction, IAvailableActionDropDown, IContract } from "src/api/types/contracts";
import { contractService } from "src/api/services/contract";
import { toast } from "src/utils/toast";
import { getAxiosError } from "src/utils/get-axios-error";
import GeneralFormErrorMessage from "src/components/App/GeneralFormErrorMessage";

interface IContactModalProps {
    show: boolean;
    data: IAvailableActionDropDown;
    onHide: () => void;
    contractId: number;
    updateStateActions: (actionsResponse: IAvailableAction[]) => void;
    updateContractDetail: (contractDetails: IContract) => void;
}
interface IContactComment {
    comment: string;
}
const ContractStatusModal: React.FC<IContactModalProps> = ({ show, data, onHide, contractId, updateStateActions, updateContractDetail }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [apiError, setApiError] = useState<string>("");
    
    const { control, reset, formState: { errors }, handleSubmit } = useForm<IContactComment>({
        defaultValues: {
            comment: '',
        },
        mode: "all",
        resolver: yupResolver(object().shape({
            comment:
                string()
                .min(15, 'You have to write at lest 15 character')
                .required("Comment is required"),
        }))
    });

    const onFormSubmit = async (formData: IContactComment) => {
        const tempData = { 
            action: data.title,
            comment: formData.comment
        };

        setLoading(true);
        try {
            let contractDetailResponse = await contractService.changeState(contractId, tempData);
            let nextActions = await contractService.getContractAvailableActions(contractId);
            
            updateContractDetail(contractDetailResponse.data.payload)
            updateStateActions(nextActions.data.payload.actions);
            toast.success("Contract status updated successfully!");
            reset({
                comment: ""
            });
            onHide();
        } catch (err) {
            const error = getAxiosError(err);
            const message = error.meta?.message || "Server Error";

            setApiError(message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        onHide();
        reset({
            comment: "",
        });
    };

    return (
        <Modal
            ariaHideApp={false}
            style={modalStyle}
            contentLabel={"Update linked account"}
            isOpen={show}
            onRequestClose={handleCloseModal}
        >
            <div className="flex flex-row justify-content-between">
                <span style={{ fontWeight: '600' }}>{data?.key}</span>
                <div className="p-1 cursor-pointer" onClick={handleCloseModal}>
                    <i
                        className={"pi pi-times"}
                        style={{ 'fontSize': '1em', color: 'var(--black)' }}
                    />
                </div>
            </div>
            <form className="mt-5" noValidate onSubmit={handleSubmit(onFormSubmit)}>
                {
                    apiError && <GeneralFormErrorMessage message={apiError} />
                }
                <FormFieldWrapper>
                    <label htmlFor="comment" className={classNames({ 'p-error': errors.comment })}>{errors?.comment?.message}</label>
                        <Controller 
                            name="comment" 
                            control={control} 
                            render={({ field }) => (
                                <>
                                    <InputText autoFocus type="text" {...field} id="comment" className={classNames('block w-full', { 'p-invalid': !!errors.comment })} />
                                </>
                            )} 
                        />
                </FormFieldWrapper>
                <FormFooterActions>
                    <Button label={data?.title} className="p-button-primary" type="submit" loading={loading} />
                    <Button label={'Cancel'} className="p-button-danger ml-2" onClick={handleCloseModal} />
                </FormFooterActions>
            </form>
        </Modal>
    )
}

export default ContractStatusModal
