import { DataTablePFSEvent } from "primereact/datatable";
import { useState } from "react";
import { IContractListItem } from "src/api/types/contracts";
import { ITransactionListItem } from "src/api/types/transactions";
import { DEFAULT_PAGE_SIZE } from "src/constants/pagination";
import { IPaginatedPayload } from "src/hooks/useUploadMedia";
import { contextFactory } from "src/utils/context-factory";
import { IUserDetailsTransactionsAndContractsContext, IUserDetailsTransactionsAndContractsProviderProps } from "./types";

export const [UserDetailsTransactionsAndContractsContext, useUserDetailsTransactionsAndContracts] =
    contextFactory<IUserDetailsTransactionsAndContractsContext>("UserDetailsTransactionsAndContractsContext", "useUserDetailsTransactionsAndContracts");



export const UserDetailsTransactionsAndContractsProvider: React.FC<IUserDetailsTransactionsAndContractsProviderProps> = (props) => {
    const { userType, userId,children } = props;

    const [transactions,setTransactions] = useState<IPaginatedPayload<ITransactionListItem>>();
    const [contracts,setContracts] = useState<IPaginatedPayload<IContractListItem>>();

    const [contractsLazyParams, setContractsLazyParams] = useState<DataTablePFSEvent>({
        filters: {

        },
        first: 0,
        multiSortMeta: [],
        page: 0,
        pageCount: 2,
        rows: DEFAULT_PAGE_SIZE,
        sortField: "",
        sortOrder: null,
    });
    const [transactionsLazyParams, setTransactionsLazyParams] = useState<DataTablePFSEvent>({
        filters: {

        },
        first: 0,
        multiSortMeta: [],
        page: 0,
        pageCount: 2,
        rows: DEFAULT_PAGE_SIZE,
        sortField: "",
        sortOrder: null,
    });

    return <UserDetailsTransactionsAndContractsContext.Provider value={{
        transactions,
        setTransactions,
        contracts,
        setContracts,
        userType,
        userId,
        contractsLazyParams, 
        setContractsLazyParams,
        transactionsLazyParams,
        setTransactionsLazyParams
    }}>{
        children
    }</UserDetailsTransactionsAndContractsContext.Provider>
}