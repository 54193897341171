import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import {
  IAxiosResponse,
  IPaginatedRequestBody,
  PaginatedResponse,
} from "../types/axios-response";
import {
  IProfileLinkedAccount,
  IEducationPutData,
  IExperiencePutData,
  ILanguagePutData,
  ISkillPutData,
  IFreelancerServices,
  IFreelancerServicePackage,
  IFreelancerServicePackgeBody,
  IFreelancerServiceBody,
} from "../types/base-data";
import { IBaseFreelancer, IBaseUser } from "../types/user";

export const freelancerService = {
  create(email: string, firstName: string, lastName: string) {
    return AxiosInstance.post<IAxiosResponse<IBaseUser>>(
      API_ROUTES.FREELANCERS.CREATE,
      {
        email,
        firstName,
        lastName,
      },
    );
  },
  getAll(data?: IPaginatedRequestBody) {
    return AxiosInstance.post<PaginatedResponse<IBaseUser>>(
      API_ROUTES.FREELANCERS.ROOT,
      data || {},
    );
  },
  exportAll(data?: IPaginatedRequestBody) {
    return AxiosInstance.post<Blob>(API_ROUTES.FREELANCERS.EXPORT, data || {});
  },
  getDetails(id: string) {
    return AxiosInstance.get<IAxiosResponse<IBaseFreelancer>>(
      `${API_ROUTES.FREELANCERS.ROOT}/${id}`,
    );
  },
  updateDetails(id: number, data: object) {
    return AxiosInstance.put<IAxiosResponse<IBaseFreelancer>>(
      API_ROUTES.USER.UPDATE(id),
      data,
    );
  },

  getServices(freelancerId: number) {
    return AxiosInstance.post<PaginatedResponse<IFreelancerServices>>(
      API_ROUTES.FREELANCERS.SERVICES,
      {
        filters: {
          freelancer: freelancerId,
        },
      },
    );
  },
  getServiceDetails(serviceId: number) {
    return AxiosInstance.get<IAxiosResponse<IFreelancerServices>>(
      API_ROUTES.FREELANCERS.SERVICE(serviceId),
    );
  },
  editLinkedAccount(id: number, linkedAccountId: number, data: any) {
    return AxiosInstance.put<IAxiosResponse<IProfileLinkedAccount>>(
      API_ROUTES.FREELANCERS.EDIT_LINKED_ACCOUNT(id, linkedAccountId),
      data,
    );
  },
  editEducation(data: IEducationPutData, userId: number, id: number) {
    return AxiosInstance.put(
      API_ROUTES.FREELANCERS.EDIT_EDUCATION(userId, id),
      data,
    );
  },
  editExperience(data: IExperiencePutData, userId: number, id: number) {
    return AxiosInstance.put(
      API_ROUTES.FREELANCERS.EDIT_EXPERIENCE(userId, id),
      data,
    );
  },
  editLanguage(
    data: ILanguagePutData,
    userId: number,
    selectedLanguage: number,
  ) {
    return AxiosInstance.put(
      API_ROUTES.FREELANCERS.EDIT_LANGUAGES(userId, selectedLanguage),
      data,
    );
  },
  editSkill(data: ISkillPutData, userId: number, selectedSkillId: number) {
    return AxiosInstance.put(
      API_ROUTES.FREELANCERS.EDIT_SKILLS(userId, selectedSkillId),
      data,
    );
  },
  editPackage(packageId: number, data: IFreelancerServicePackgeBody) {
    return AxiosInstance.put<IAxiosResponse<IFreelancerServicePackage>>(
      API_ROUTES.FREELANCERS.EDIT_PACKAGE(packageId),
      data,
    );
  },
  editService(serviceId: number, data: IFreelancerServiceBody) {
    return AxiosInstance.put<IAxiosResponse<IFreelancerServices>>(
      API_ROUTES.FREELANCERS.EDIT_SERVICE(serviceId),
      data,
    );
  },
  removeUser(id: number) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.FREELANCERS.REMOVE(id),
      {},
    );
  },
  removeService(serviceId: number) {
    return AxiosInstance.delete<IAxiosResponse<{}>>(
      API_ROUTES.FREELANCERS.SERVICE(serviceId),
    );
  },
  removePackage(packageId: number) {
    return AxiosInstance.delete<IAxiosResponse<{}>>(
      API_ROUTES.FREELANCERS.PACKAGE(packageId),
    );
  },
};
