import React, { useEffect, useState } from "react";
import { Editor, EditorTextChangeParams } from "primereact/editor";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { Fieldset, FieldsetToggleParams } from "primereact/fieldset";
import Header from "src/components/App/PageHeader";
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import { toast } from "src/utils/toast";
import { getAxiosError } from "src/utils/get-axios-error";
import { StaticPagesProvider, useStaticPages } from "./StaticPagesContext";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { staticPagesValidationSchema } from "./validation-schema";
import { useTranslation } from "react-i18next";
import { staticPagesService } from "src/api/services/static-pages";
import HorizontalSpinner from "src/components/Kit/HorizontalSpinner";

interface INewStaticPagesData {
  arabicTitle: string;
  englishTitle: string;
  englishContent: string;
  arabicContent: string;
}
const StaticPagesContent: React.FC = () => {
  const { t } = useTranslation();

  const { loading, loadingPages, pagesItem, changeActivePage, activePage, setSaving, saving } =
    useStaticPages();
  const [collapsedEn, setCollapsedEn] = useState(true);
  const [collapsedAr, setCollapsedAr] = useState(true);
  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<INewStaticPagesData>({
    defaultValues: {
      arabicTitle: "",
      englishTitle: "",
      englishContent: "",
      arabicContent: "",
    },
    mode: "all",
    resolver: yupResolver(staticPagesValidationSchema()),
  });

  useEffect(() => {
    reset({
      arabicTitle: activePage?.ml_title?.ar,
      englishTitle: activePage?.ml_title?.en,
      englishContent: activePage?.ml_content?.en,
      arabicContent: activePage?.ml_content?.ar,
    });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [activePage]);

  const [selectedPage, setSelectedPage] = useState<{ name?: string; code?: number }>();

  const onActiveItemChanged = (e: DropdownChangeParams) => {
    setSelectedPage(e.value);
    changeActivePage(e.value.code);
    setCollapsedAr(true);
    setCollapsedEn(true);
  };
  const onFormSubmit = async (data: INewStaticPagesData) => {
    try {
      let senderDate = {
        name: activePage?.name,
        ml_title: {
          ar: data.arabicTitle,
          en: data.englishTitle,
        },
        ml_content: {
          ar: data.arabicContent,
          en: data.englishContent,
        },
      };
      setSaving(true);
      await staticPagesService.updatePage(activePage?.id!, senderDate);
      toast.success(` ${activePage?.name} updated successfully`);
    } catch (err) {
      const error = getAxiosError(err);
      toast.error(error.meta?.message!);
    } finally {
      setSaving(false);
    }
  };
  const onSaveClicked = () => { };

  useEffect(() => {
    if (activePage) {
      setSelectedPage({
        name: activePage?.name,
        code: activePage?.id,
      })
    }
  }, [activePage]);

  return <>
    <Header hasGoBack={true} title="Static Pages" />
    {
      <div>
        <div>
          <Dropdown
            disabled={loading}
            value={selectedPage}
            defaultValue={pagesItem.length && pagesItem[0].id}
            onChange={onActiveItemChanged}
            options={pagesItem.map((item) => {
              return {
                code: item.id,
                name: item.name,
              };
            })}
            optionLabel="name"
            placeholder="Select Page"
            className="w-full md:w-14rem"
          />
        </div>
        {
          (loadingPages || loading) && <HorizontalSpinner />
        }
        <div className="pt-5">
          <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
            <div className="flex">
              <div className="flex-1 px-3">
                <FormFieldWrapper>
                  <label
                    htmlFor="englishTitle"
                    className={classNames({ "p-error": errors.englishTitle })}
                  >
                    {t("fields.englishTitle")}
                  </label>
                  <Controller
                    name="englishTitle"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        type="text"
                        {...field}
                        disabled={loading}
                        id="englishTitle"
                        className={classNames("block w-full", {
                          "p-invalid": !!errors.englishTitle,
                        })}
                      />
                    )}
                  />
                </FormFieldWrapper>
              </div>
              <div className="flex-1 px-3">
                <FormFieldWrapper>
                  <label
                    htmlFor="arabicTitle"
                    className={classNames({ "p-error": errors.arabicTitle })}
                  >
                    {t("fields.arabicTitle")}
                  </label>
                  <Controller
                    name="arabicTitle"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        type="text"
                        {...field}
                        disabled={loading}
                        id="arabicTitle"
                        className={classNames("block w-full", {
                          "p-invalid": !!errors.arabicTitle,
                        })}
                      />
                    )}
                  />
                </FormFieldWrapper>
              </div>
            </div>
            <div className="pb-5">
              <Fieldset legend={"English Content"} toggleable collapsed={collapsedEn}
                onToggle={(e: FieldsetToggleParams) => { setCollapsedEn(e.value) }}>
                <FormFieldWrapper>
                  <Controller
                    name="englishContent"
                    control={control}
                    render={({ field }) => (
                      <Editor
                        id="englishContent"
                        disabled={loading}
                        value={activePage?.ml_content?.en}
                        onTextChange={(e: EditorTextChangeParams) => {
                          field.onChange(e.htmlValue || "");
                        }}
                        style={errors.englishContent ? { height: "320px", border: '1px solid red' } : { height: "320px" }}
                      />
                    )}
                  />
                </FormFieldWrapper>
              </Fieldset>
            </div>
            <div className="pb-5">
              <Fieldset legend={"Arabic Content"} toggleable
                collapsed={collapsedAr}
                onToggle={(e: FieldsetToggleParams) => { setCollapsedAr(e.value) }}>
                <FormFieldWrapper>
                  <Controller
                    name="arabicContent"
                    control={control}
                    render={({ field }) => (
                      <Editor
                        id="arabicContent"
                        value={activePage?.ml_content?.ar}
                        disabled={loading}
                        onTextChange={(e: EditorTextChangeParams) => {
                          field.onChange(e.htmlValue || "");
                        }}
                        style={errors.arabicContent ? { height: "320px", border: '1px solid red' } : { height: "320px" }}
                      />
                    )}
                  />
                </FormFieldWrapper>
              </Fieldset>
            </div>

            <div className="flex">
              <div className="flex-1 px-3 justify-content-start">
                <Button
                  label="Save"
                  className="p-button-primary mr-3"
                  type="submit"
                  onClick={onSaveClicked}
                  loading={saving}
                  disabled={saving}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    }
  </>
};

const StaticPages: React.FC = () => {
  return (
    <StaticPagesProvider>
      <StaticPagesContent />
    </StaticPagesProvider>
  );
};

export default StaticPages;
