import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from "primereact/utils";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Modal from 'react-modal';
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import { modalStyle } from "src/constants/modal-styles";
import { useFreelancerServices } from "../../FreelancerServicesContext";
import { Dropdown } from 'primereact/dropdown';
import { baseService } from "src/api/services/base";
import { IDropDownOption, IDropDownOptionString } from "src/api/types/base-data";
import { useTranslation } from "react-i18next";
import { MultiSelect } from 'primereact/multiselect';
import { editServiceValidationSchema } from "./edit-service-validation-schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { getAxiosError } from "src/utils/get-axios-error";
import { toast } from "src/utils/toast";
import { freelancerService } from "src/api/services/freelancer";


interface IEditServiceData {
    title: string;
    description: string;
    category: number;
    tags: string[];
}

const EditServiceModal: React.FC = () => {
    const { closeEditServiceModal, editingServiceItem ,services,setServices} = useFreelancerServices();
    const { t } = useTranslation();
    const [categories, setCategories] = useState<IDropDownOption[]>([]);
    const [tags, setTags] = useState<IDropDownOptionString[]>([]);
    const [saving,setSaving] = useState(false);

    // useEffect(() => {
    //     console.log(editingServiceItem)
    // }, []);

    const getCategories = useCallback(async () => {
        try {
            const result = await baseService.getCategories();
            let tempCategories: IDropDownOption[] = [];
            if (result.data.payload.items.length > 0) {
                tempCategories = result.data.payload.items.map((item) => ({
                    label: item.title,
                    value: item.id
                }));
            }
            setCategories(tempCategories)
        } catch (err) {

        }
    }, []);

    const getTags = useCallback(async () => {
        try {
            const result = await baseService.getTags();
            let tempTags: IDropDownOptionString[] = [];
            if (result.data.payload.items.length > 0) {
                tempTags = result.data.payload.items.map((item) => ({
                    label: item.title,
                    value: item.title
                }));
            }
            setTags(tempTags)
        } catch (err) {

        }
    }, []);

    useEffect(() => {
        getCategories();
    }, [getCategories]);

    useEffect(() => {
        getTags();
    }, [getTags])

    const { control, handleSubmit, formState: { errors } } = useForm<IEditServiceData>({
        defaultValues: {
            title: editingServiceItem?.title || "",
            description: editingServiceItem?.description || "",
            tags: editingServiceItem?.tags || [],
            category: editingServiceItem?.category.id
        },
        resolver: yupResolver(editServiceValidationSchema())
    });

    const onCloseClicked = () => {
        closeEditServiceModal();
    }

    const onFormSubmit = async(data: IEditServiceData) => {
        try{
            setSaving(true);
            const result = await freelancerService.editService(editingServiceItem?.id!,{
                categoryId:data.category,
                description:data.description,
                tags:data.tags,
                title:data.title
            });
            const tempServices = [...services!];
            const targetIndex = tempServices.findIndex(s=>s.id === editingServiceItem?.id!);
            tempServices[targetIndex] = result.data.payload;
            setServices(tempServices);
            toast.success("Service updated successfuly");
            closeEditServiceModal();
        }catch(err){
            const error = getAxiosError(err);
            toast.error(error.meta?.message!);
        }finally{
            setSaving(false);
        }
    }

    return <Modal
        ariaHideApp={false}
        style={modalStyle}
        contentLabel={"Edit Service"}
        isOpen={!!editingServiceItem}
        onRequestClose={onCloseClicked}>

        <div className="flex flex-row justify-content-between">
            <span style={{ fontWeight: '600' }}>Edit Service</span>
            <div className="p-1 cursor-pointer" onClick={onCloseClicked}>
                <i
                    className={"pi pi-times"}
                    style={{ 'fontSize': '1em', color: 'var(--black)' }}
                />
            </div>
        </div>
        <div>
            <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
                <FormFieldWrapper>
                    <label htmlFor="title" className={classNames({ 'p-error': errors.title })}>{t("fields.title")}</label>
                        <Controller name="title" control={control} render={({ field }) => (
                            <InputText autoFocus type="text" {...field} id="title" className={classNames('block w-full', { 'p-invalid': !!errors.title })} />
                        )} />
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <label htmlFor="description" className={classNames({ 'p-error': errors.description })}>{t("fields.description")}</label>
                        <Controller name="description" control={control} render={({ field }) => (
                            <InputTextarea autoFocus  {...field} id="description" className={classNames('block w-full', { 'p-invalid': !!errors.description })} />
                        )} />
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <label htmlFor="category" className={classNames({ 'p-error': errors.category })}>{t("fields.category")}</label>
                        <Controller name="category" control={control} render={({ field }) => (
                            <Dropdown id={field.name} value={field.value} onChange={(e) => field.onChange(e.value)}
                                options={categories}
                                className={classNames('element-full-w', { 'p-invalid': !!errors.category })} />
                        )} />
                </FormFieldWrapper>
                <FormFieldWrapper>
                    <label htmlFor="tags" className={classNames({ 'p-error': errors.tags })}>{t("fields.tags")}</label>
                        <Controller name="tags" control={control} render={({ field }) => (
                            <MultiSelect options={tags}
                                id={field.name}
                                value={field.value} onChange={(e) => field.onChange(e.value)}
                                maxSelectedLabels={3}
                                className={classNames('element-full-w', { 'p-invalid': !!errors.tags })} />
                        )} />
                </FormFieldWrapper>
                <div>
                    <Button label="Submit" type="submit" disabled={saving} loading={saving}/>
                </div>
            </form>
        </div>
    </Modal>
}

export default EditServiceModal;