import React from "react";
import { IFunctionalComponentProps } from "src/types/functional-component";
import Sidebar from "../Sidebar";
import styles from  "./DashboardContent.module.css";

interface IDashboardContentProps extends IFunctionalComponentProps { }

const DashboardContent: React.FC<IDashboardContentProps> = ({ children }) => {
    return <div className={styles["dashboard-content"]}>
        <Sidebar />
        <div className={styles["d-content"]}>
            <div>{children}</div>
        </div>
    </div>
}

export default DashboardContent;