import dayjs from "dayjs";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { couponService } from "src/api/services/coupon";
import { IGetListBody } from "src/api/types/base-data";
import { ICouponItem } from "src/api/types/coupon";
import ListingPage from "src/components/App/ListingPage";
import Card from "src/components/Kit/Card";
import { DEFAULT_PAGE_SIZE } from "src/constants/pagination";

const CouponList: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [coupons, setCoupons] = useState<ICouponItem[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<IGetListBody>();
  const [lazyParams, setLazyParams] = useState<DataTablePFSEvent>({
    filters: {},
    first: 0,
    multiSortMeta: [],
    page: 0,
    pageCount: 2,
    rows: DEFAULT_PAGE_SIZE,
    sortField: "",
    sortOrder: null,
  });

  const fetchCoupons = useCallback(async () => {
    try {
      setLoading(true);
      const body: IGetListBody = !!filters
        ? {
            ...filters,
            ...{
              skip: lazyParams.page! * DEFAULT_PAGE_SIZE,
              take: DEFAULT_PAGE_SIZE,
            },
          }
        : {
            skip: lazyParams.page! * DEFAULT_PAGE_SIZE,
            take: DEFAULT_PAGE_SIZE,
          };
      const result = await couponService.getAll(body);
      setTotalRecords(result.data.payload.pagination.totalItems);
      setCoupons(result.data.payload.items);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [lazyParams, filters]);

  useEffect(() => {
    fetchCoupons();
  }, [fetchCoupons]);
  console.log("coupons", coupons);
  const onPageChange = (e: DataTablePFSEvent) => {
    setLazyParams(e);
  };

  const renderActions = (rowData: ICouponItem) => {
    return (
      <>
        <Link
          to={
            ROUTE_CONSTANTS.DASHBOARD.PROMOTION.COUPON.DETAILS.GET_BY_DATA(
              rowData.id,
            ).ABSOLUTE
          }>
          Details
        </Link>
      </>
    );
  };

  return (
    <>
      <ListingPage title={t("titles.coupons")}>
        <Button
          label="New Coupon"
          icon="ph-plus"
          iconPos="left"
          className="p-button-primary  m-2"
          onClick={() =>
            navigate(ROUTE_CONSTANTS.DASHBOARD.PROMOTION.COUPON.CREATE.ABSOLUTE)
          }
        />
        <Card>
          <DataTable
            value={coupons}
            emptyMessage="No Coupons found."
            loading={loading}
            lazy
            first={lazyParams.first}
            paginator
            rows={DEFAULT_PAGE_SIZE}
            totalRecords={totalRecords}
            onPage={onPageChange}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            dataKey="id"
            className="qud-table">
            <Column field="id" header="ID" />
            <Column field="code" header="Code" />
            <Column field="amountType" header="Type" />
            <Column field="amountValue" header="Amount" />
            <Column
              field="expireDate"
              header="Expire Date"
              body={(rowData: ICouponItem) => (
                <>
                  {rowData.expireDate
                    ? dayjs(rowData.expireDate).format("YYYY-MM-DD HH:mm")
                    : "Whenever"}
                </>
              )}
            />
            <Column
              field="status"
              header="Status"
              body={(rowData: ICouponItem) => (
                <div
                  style={{
                    color: rowData.style.status.TextColor,
                    background: rowData.style.status.BackgroundColor,
                    textAlign: "center",
                    borderRadius: 8,
                    padding: 4,
                  }}>
                  {rowData.status}
                </div>
              )}
            />
            <Column field="action" header="Action" body={renderActions} />
          </DataTable>
        </Card>
      </ListingPage>
    </>
  );
};

export default CouponList;
