interface ICategory {
  id: number;
  isActive: boolean;
  ml_title: { ar: string; en: string };
}

export interface IDeliveryTimeUnit {
  hours: number;
  id: number;
  isActive: boolean;
  ml_title: { ar: string; en: string };
}

export enum JoBStatus {
  Active = "ACTIVE",
  Contracted = "CONTRACTED",
  Closed = "CLOSED",
  ContractClosed = "CONTRACT_CLOSED",
}

export interface IJobItem {
  category: ICategory;
  deliveryTime: number;
  id: number;
  title: string;
  description: string;
  status: JoBStatus;
  style: {
    status: {
      BackgroundColor: string;
      TextColor: string;
    };
  };
  tags: string[];
  items: string[];

  limitedRevisions: boolean;
  revisions: number;
  deliveryTimeUnit: IDeliveryTimeUnit;
  price: string;
}

export interface IEditJobRequestData {
  title: string;
  description: string;
  categoryId: number;
  revision?: number;
  items: string[];
  deliveryTime: number;
  deliveryTimeUnitId: number;
  price: number;
  tags: string[];
  // limitedRevisions: boolean;
}
