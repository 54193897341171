export const modalStyle = {
    overlay: {
        backgroundColor: '#00000047',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        width: '50vw',
        transform: 'translate(-50%, -50%)',
      },
}