import React from "react";
import { IFreelancerServicePackage } from "src/api/types/base-data";
import CustomCard from "src/components/Kit/CustomCard";
import { useFreelancerServiceDetails } from "../../FreelancerServiceDetailsContext";
import { freelancerService } from "src/api/services/freelancer";
import { confirmDialog } from "primereact/confirmdialog";
import { toast } from "src/utils/toast";

interface IPackageListItemProps {
  data: IFreelancerServicePackage;
  onPackageRemovedSuccessFully: (deletedPackageId: number) => void;
  hasMoreThanOnePackage: boolean;
}

const PackageListItem: React.FC<IPackageListItemProps> = ({
  data,
  onPackageRemovedSuccessFully,
  hasMoreThanOnePackage,
}) => {
  const { setEditingPackageItem } = useFreelancerServiceDetails();
  const onEditClicked = (e: React.MouseEvent) => {
    e.stopPropagation();
    setEditingPackageItem(data);
    return;
  };
  const onDeleteService = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (hasMoreThanOnePackage) {
      confirmDialog({
        message: `Are you sure you want to remove ${data.title}`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: async () => {
          try {
            await freelancerService.removePackage(data.id);
            onPackageRemovedSuccessFully(data.id);
            toast.success("Package removed successfully");
          } catch (err) {
            console.log(err);
          } finally {
            // setRemovingTargetLoading(-1);
          }
        },
      });
    } else {
      toast.error(
        "This is the only package of this service and can't be deleted.",
      );
    }
  };
  return (
    <CustomCard
      className="flex flex-column mt-4 mr-3"
      style={{ minWidth: "350px" }}
      editButton
      onEditClick={onEditClicked}
      deleteButton={true}
      onDelete={onDeleteService}>
      <span className="service-details-card-title ">Service Package</span>
      <div className="flex flex-column mt-4">
        <span className="service-details-package-title">{data?.title}</span>
        <span className="service-details-package-price mt-1">
          {data?.price} KD
        </span>
        <span className="service-details-package-description mt-1">
          {data?.description}
        </span>
        <div className="flex flex-row justify-content-between align-items-center mt-3">
          <div className="flex flex-row align-items-center">
            <i className={"pi pi-clock"} style={{ fontSize: "17px" }} />
            <span className="service-details-package-description ml-2">
              {data.deliveryTime} {data.deliveryTimeUnit.title}
            </span>
          </div>
          <div className="flex flex-row align-items-center">
            <i className={"pi pi-user-edit"} style={{ fontSize: "17px" }} />
            <span className="service-details-package-description ml-2">
              {data?.revisions ? data.revisions : "Unlimited"}
            </span>
          </div>
        </div>
      </div>
    </CustomCard>
  );
};

export default PackageListItem;
