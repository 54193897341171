import React, { useState } from "react";
import { Card } from "primereact/card";
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import FormFooterActions from "src/components/Kit/FormFooterActions";
import { Controller, useForm } from "react-hook-form";
import { IForgotPasswordData } from "./types";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginValidationSchema } from "./validation-schema";
import { classNames } from "primereact/utils";
import { authService } from "src/api/services/auth";
import GeneralFormErrorMessage from "src/components/App/GeneralFormErrorMessage";
import { useTranslation } from "react-i18next";
import { getAxiosError } from "src/utils/get-axios-error";
import { useTitle } from "src/hooks/useTitle";
import GeneralPageContent from "src/components/App/GeneralPageContent";
import { PasswordResetLinkSent } from "./PasswordResetLinkSent";

const ForgotPassword: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitles.forgotPassword"));
    const [loading, setLoading] = useState<boolean>(false);
    const [resetLinkSent, setResetLinkSent] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [apiError, setApiError] = useState<string>("");
    const { control, formState: { errors }, handleSubmit } = useForm<IForgotPasswordData>({
        defaultValues: {
            email: ""
        },
        mode: "all",
        resolver: yupResolver(loginValidationSchema())
    });

    const onFormSubmit = async (data: IForgotPasswordData) => {
        setLoading(true);
        try {
            await authService.forgotPassword(data.email);
            setEmail(data.email);
            setResetLinkSent(true);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error.meta?.message || "Server Error";
            setApiError(message);
        } finally {
            setLoading(false);
        }
    }

    return <GeneralPageContent>
        <div className="content">
            {
                resetLinkSent ? (
                    <PasswordResetLinkSent email={email} />
                ) : (
                    <>
                        <Card className="item-card-trasparent" title={t("titles.forgotPassword")}>
                            <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
                                {
                                    apiError && <GeneralFormErrorMessage message={apiError} />
                                }
                                <div className="mb-3">
                                    {t("content.forgotPasswordHint")}
                                </div>
                                <FormFieldWrapper>
                                    <label htmlFor="email" className={classNames({ 'p-error': errors.email })}>{t("fields.email")}</label>
                                        <Controller name="email" control={control} render={({ field }) => (
                                            <InputText autoFocus type="email" {...field} id="email" className={classNames('block w-full', { 'p-invalid': !!errors.email })} />
                                        )} />
                                </FormFieldWrapper>
                                <FormFooterActions>
                                    <Button label={t("actions.submit")} className="p-button-primary" type="submit" loading={loading} />
                                </FormFooterActions>
                            </form>
                        </Card>
                    </>
                )
            }
        </div>
    </GeneralPageContent>
}

export default ForgotPassword;