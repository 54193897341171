import { API_ROUTES } from "src/constants/api-routes";
import { TOrderedStarItem } from "src/types/dynamic-home";
import { MAX_COUNT } from "../../pages/Dashboard/home/Relocation/RelocationData/index";
import { AxiosInstance } from "../index";
import { IAxiosResponse, PaginatedResponse } from "../types/axios-response";
import {
  TDynamicHomeSettingResponse,
  THomeSettingUpdatePayload,
} from "../types/dynamic-home";
export const dynamicHomeService = {
  getSetting() {
    return AxiosInstance.get<IAxiosResponse<TDynamicHomeSettingResponse>>(
      API_ROUTES.DYNAMIC_HOME.SETTINGS,
    );
  },
  updateSetting(data: THomeSettingUpdatePayload) {
    return AxiosInstance.put<IAxiosResponse<any>>(
      API_ROUTES.DYNAMIC_HOME.SET_SETTING,
      data,
    );
  },
  getItems(searchUrl: string, inputValue: string) {
    return AxiosInstance.post<PaginatedResponse<any>>(searchUrl, {
      skip: 0,
      take: 10,
      sort: {},
      searchQuery: inputValue,
      filters: {},
    });
  },
  getOrderedItems(orderedItemsUrl: string) {
    return AxiosInstance.post<PaginatedResponse<TOrderedStarItem>>(
      orderedItemsUrl,
      {
        skip: 0,
        take: MAX_COUNT,
        sort: {},
        searchQuery: "",
        filters: {},
      },
    );
  },
  updateOrderedJobs(updateOrderedItemsUrl: string, data: any) {
    return AxiosInstance.put<IAxiosResponse<any>>(updateOrderedItemsUrl, data);
  },
};
