import React, { useState, useCallback, useEffect } from 'react'
import { contextFactory } from "src/utils/context-factory";
import { IFreelancerDetailsContext } from "./types";
import { IBaseFreelancer } from "src/api/types/user";
import { useParams } from "react-router-dom";
import { freelancerService } from "src/api/services/freelancer";

export const [FreelancerDetailsContext, useFreelancerDetails] = contextFactory<IFreelancerDetailsContext>("FreelancerDetailsContext", "useFreelancerDetails");

interface IFreelancerDetailProviderProps {
    children: React.ReactNode
}

export const FreelancerDetailsProvider:React.FC<IFreelancerDetailProviderProps> = ({children}) => {
    const [loading, setLoading] = useState(false)
    const [userData, setUserData] = useState<IBaseFreelancer>();
    const routeParams = useParams<{ id: string }>();

    const fetchFreelancerDetails = useCallback(async () => {
        if (routeParams.id) {
            try{
                let res = await freelancerService.getDetails(routeParams.id)
                setUserData(res.data.payload)
            }catch(error){
                console.log(error);
                setLoading(false);
            }
        }
    }, [routeParams.id]);

    useEffect(() => {
        fetchFreelancerDetails()
    }, [fetchFreelancerDetails])
    
    return (
        <FreelancerDetailsContext.Provider value={{userData, setUserData, loading, setLoading}}>
            {children}
        </FreelancerDetailsContext.Provider>
    )
}
