import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import {  IPaginatedRequestBody, PaginatedResponse } from "../types/axios-response";
import { IStaticPage, IStaticPageItem } from "../types/static-pages";
import { IAxiosResponse } from "src/hooks/useUploadMedia";



export const staticPagesService = {
    getAll(data?:IPaginatedRequestBody){
        return AxiosInstance.post<PaginatedResponse<IStaticPageItem>>(API_ROUTES.STATIC_PAGES.ROOT,data || {});
    },
    getById(id:string | number){
        return AxiosInstance.get<IAxiosResponse<IStaticPage>>(`${API_ROUTES.STATIC_PAGES.ROOT}/${id}`);
    },
    updatePage(id:string | number, data:IStaticPage){
        return AxiosInstance.put<IAxiosResponse<IStaticPage>>(`${API_ROUTES.STATIC_PAGES.ROOT}/${id}`,data);
    }
};