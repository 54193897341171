import { Column } from "primereact/column";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import { useCallback, useEffect, useState } from "react";
import { contractService } from "src/api/services/contract";
import { IGetListBody } from "src/api/types/base-data";
import { IContractListItem } from "src/api/types/contracts";
import ListingPage from "src/components/App/ListingPage";
import SortAndFilters from "src/components/App/SortAndFilters";
import { dateBodyTemlateOfContract, userDetailsOfContract } from "src/components/App/TableHelpers";
import Card from "src/components/Kit/Card";
import { filterListNames } from "src/constants/filter-list-names";
import { DEFAULT_PAGE_SIZE } from "src/constants/pagination";

interface ICouponContractsProps {
    id: number;
}

const CouponContracts: React.FC<ICouponContractsProps> = ({ id }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [contracts, setContracts] = useState<IContractListItem[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filters, setFilters] = useState<IGetListBody>({ filters: { couponId: +id! } });
    const [lazyParams, setLazyParams] = useState<DataTablePFSEvent>({
        filters: {},
        first: 0,
        multiSortMeta: [],
        page: 0,
        pageCount: 2,
        rows: DEFAULT_PAGE_SIZE,
        sortField: "",
        sortOrder: null,
    });

    const fetchContracts = useCallback(async () => {
        try {
            setLoading(true);
            const body: IGetListBody = !!filters ? {
                ...filters,
                ...{
                    filters: {
                        couponId: +id!
                    }
                },
                ...{
                    skip: lazyParams.page! * DEFAULT_PAGE_SIZE,
                }
            } : {
                skip: lazyParams.page! * DEFAULT_PAGE_SIZE,
                take: DEFAULT_PAGE_SIZE,
            }
            const result = await contractService.getAll(body);
            setTotalRecords(result.data.payload.pagination.totalItems);
            setContracts(result.data.payload.items);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, [lazyParams, filters]);

    useEffect(() => {
        fetchContracts();
    }, [fetchContracts]);

    const onPageChange = (e: DataTablePFSEvent) => {
        setLazyParams(e);
    }

    const onSortAndFiltersChanged = (data: IGetListBody) => {
        setFilters(data);

        setLazyParams({
            ...lazyParams,
            page: 0,
            first: 1
        })
    }

    return <ListingPage title={'Coupon Contracts'}>
        <Card>
            <SortAndFilters listName={filterListNames.CONTRACTS} onChange={onSortAndFiltersChanged} />
            <DataTable value={contracts} emptyMessage="No contracts found." loading={loading}
                lazy
                first={lazyParams.first}
                paginator
                rows={DEFAULT_PAGE_SIZE}
                totalRecords={totalRecords}
                onPage={onPageChange}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                dataKey="id">
                <Column field="id" header="Contract ID" />
                <Column field="customer" header="Customer" body={userDetailsOfContract} />
                <Column field="freelancer" header="Freelancer" body={userDetailsOfContract} />
                <Column field="openDate" header="Open Date" body={dateBodyTemlateOfContract} />
                <Column field="dueDate" header="Due Date" body={dateBodyTemlateOfContract} />
                <Column field="originPrice" header="Price" />
                <Column field="status" header="Status" />
            </DataTable>
        </Card>
    </ListingPage>;
}

export default CouponContracts;