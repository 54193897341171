import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import { classNames } from "primereact/utils";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { globalSettingServices } from "src/api/services/global-settings";
import { DEFAULT_PAGE_SIZE } from "src/constants/pagination";
import { useTitle } from "src/hooks/useTitle";
import { IEntityMangementItem } from "../../types";
import { IGlobalSettingItem } from "src/api/types/global-setting";
import { IPaginatedPayload } from "src/hooks/useUploadMedia";
import SettingManagmentForm from "../SettingManagmentForm";
import "./styles.css";


interface ISettingManagmentProps {
    settingItem: IEntityMangementItem;
}



const SettingManagment: React.FC<ISettingManagmentProps> = ({ settingItem }) => {

    const { t } = useTranslation();
    useTitle(t("pageTitles.globalSetting"))

    const [dataTable, setDataTable] = useState<IPaginatedPayload<IGlobalSettingItem>>();
    const [loading, setLoading] = useState(false);
    const [activationLoading, setActivationLoading] = useState<Record<number, boolean>>({});
    const [editingRecord, setEditingRecord] = useState<IGlobalSettingItem>();

    const [lazyParams, setLazyParams] = useState<DataTablePFSEvent>({
        filters: {

        },
        first: 0,
        multiSortMeta: [],
        page: 0,
        pageCount: 2,
        rows: DEFAULT_PAGE_SIZE,
        sortField: "",
        sortOrder: null,
    });


    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await globalSettingServices.get(settingItem.code, {
                skip: lazyParams.page! * DEFAULT_PAGE_SIZE,
                take: (lazyParams.page! + 1) * DEFAULT_PAGE_SIZE
            });
            setDataTable(response.data?.payload)

        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false);

        }
    }, [settingItem, lazyParams]);

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const onPageChange = (e: DataTablePFSEvent) => {
        setLazyParams(e);
    }
    const onChangeActivationClicked = async (data: IGlobalSettingItem) => {
        try {
            setActivationLoading({ ...activationLoading, [data.id]: true })
            await globalSettingServices.toggleActivation(settingItem.code, data.id, !data.isActive)
            const tempDataTable = { ...dataTable }
            const findedDataTabeItemIndex = tempDataTable.items!.findIndex(item => item.id === data.id);
            tempDataTable.items![findedDataTabeItemIndex].isActive = !data.isActive;
            setDataTable({
                items: tempDataTable.items!,
                pagination: tempDataTable.pagination!
            });

        } catch (err) {

        } finally {
            setActivationLoading({ ...activationLoading, [data.id]: false })
        }

    }
    const changeActivationTemplate = (rowData: IGlobalSettingItem) => {
        return <>
            <Button
                loading={activationLoading[rowData.id]}
                onClick={() => onChangeActivationClicked(rowData)}
                label={t(rowData.isActive ? "actions.deactive" : "actions.active")}
                className={classNames("p-button-outlined p-button-sm", { "p-button-danger": !rowData.isActive, "p-button-secondary": rowData.isActive })}
                type="button"
            />
        </>
    }

    const onEditRecordClicked = (sender: IGlobalSettingItem) => {
        setEditingRecord(sender);
    }

    const editItemTemplate = (rowData: IGlobalSettingItem) => {
        return <>
            <Button
                label="Edit"
                className="p-button-outlined p-button-sm p-button-danger"
                type="button"
                onClick={() => { onEditRecordClicked(rowData) }}
            />
        </>
    }

    const onAfterSave = (data: IGlobalSettingItem) => {
        const tempItems = dataTable?.items!;
        if(editingRecord){
            const targetIndex = tempItems.findIndex(si=>si.id === data.id);
            tempItems[targetIndex] = {...data};
            setDataTable({
                ...dataTable!,
                items:tempItems
            });
            setEditingRecord(undefined);
        }else{
            setDataTable({
                ...dataTable!,
                items:[data,...tempItems]
            })
        }

    }

    const onUpdateRecordCancelled = (sender?: IGlobalSettingItem) => {
        setEditingRecord(undefined);
    }

    return (
        <div>
            <h1>{settingItem.name}</h1>
            <SettingManagmentForm defaultData={editingRecord} onCancel={onUpdateRecordCancelled}
             onSave={onAfterSave} settingItem={settingItem} />
            <Card className="mt-3">
                {
                    dataTable && <DataTable value={dataTable?.items} emptyMessage="No Administrators found." loading={loading}
                        lazy
                        first={lazyParams.first}
                        paginator
                        rows={DEFAULT_PAGE_SIZE}
                        totalRecords={dataTable?.pagination?.totalItems || 0}
                        onPage={onPageChange}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        dataKey="id"
                        className="qud-table" >
                        <Column field="id" header="ID" />
                        <Column field="ml_title.en" header="English Title" />
                        <Column field="ml_title.ar" header="Arabic Title"  />
                        {
                            !!settingItem.extraField && <Column field={settingItem.extraField.name} 
                            header={settingItem.extraField.label}  />
                        }
                        <Column header="" body={changeActivationTemplate} />
                        <Column header="" body={editItemTemplate} />
                    </DataTable>
                }
            </Card>
        </div>
    )
}

export default SettingManagment;


