import React from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.dashboard"));
  return (
    <div>
      <h2>Welcome to Qudra Administration Panel</h2>
      <p>
        All statistics and some information sections will be added to this page
        in the future.
      </p>
    </div>
  );
};

export default Dashboard;
