import React from "react";
import { Outlet } from "react-router-dom";
import GeneralLayoutWrapper from "../components/GeneralLayoutWrapper";
import Navbar from "./components/Navbar";

const GeneralLayout: React.FC = () => {
    return <GeneralLayoutWrapper>
        <div>
            <Navbar />
            <div className="p-4 flex justify-content-center">
                <Outlet />
            </div>
        </div>
    </GeneralLayoutWrapper>
}

export default GeneralLayout;