import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import {  PaginatedResponse } from "../types/axios-response";
import { IBaseDataItem, IListInfoItem ,IEmploymentTypes,ICity,ILinkedAccountType,
    ILevelObject,
    ICountry,
    ILanguagesObject,
    ISkillObject
} from "../types/base-data";


export const baseService = {
    getListInfos(){
        return AxiosInstance.post<PaginatedResponse<IListInfoItem>>(API_ROUTES.BASE_DATA.LIST_INFO,{
            skip:0,
            take:1000
        });
    },
    getListInfoByEntity(listName:string){
        return AxiosInstance.post<PaginatedResponse<IListInfoItem>>(API_ROUTES.BASE_DATA.LIST_INFO,{
            skip:0,
            take:1000,
            filters:{listName}
        });
    },
    getDynamicBaseData(url:string){
        return AxiosInstance.post<PaginatedResponse<IBaseDataItem>>(`${url}`,{
            skip:0,
            take:1000
        });
    },
    getCategories(){
        return AxiosInstance.post<PaginatedResponse<IBaseDataItem>>(API_ROUTES.BASE_DATA.SERVICE_CATEGORIES,{
            skip:0,
            take:1000,
        },{},true);
    },
    getTags(){
        return AxiosInstance.post<PaginatedResponse<IBaseDataItem>>(API_ROUTES.BASE_DATA.SERVICE_TAGS,{
            skip:0,
            take:1000
        },{},true);
    },
    getTimeUnits(){
        return AxiosInstance.post<PaginatedResponse<IBaseDataItem>>(API_ROUTES.BASE_DATA.TIME_UNITS,{
            skip:0,
            take:1000
        },{},true);
    },
    getCities(countryId: number,miniList:boolean = false) {
        return AxiosInstance.post<PaginatedResponse<ICity>>(miniList ?API_ROUTES.BASE_DATA.CITIES_MINI_LIST: API_ROUTES.BASE_DATA.CITIES, {
            filters: {
                country: countryId,
            },
            skip: 0,
            take: 1000
        });
    },
    getEmploymentTypes() {
        return AxiosInstance.post<PaginatedResponse<IEmploymentTypes>>(API_ROUTES.BASE_DATA.EMPLOYMENT_TYPES, {
            skip: 0,
            take: 1000
        });
    },
    getLinkedAccountTypes() {
        return AxiosInstance.post<PaginatedResponse<ILinkedAccountType>>(API_ROUTES.BASE_DATA.GET_LINKED_ACCOUNTS, {
            skip: 0,
            take: 1000
        });
    },
    getSkills() {
        return AxiosInstance.post<PaginatedResponse<ISkillObject>>(API_ROUTES.BASE_DATA.GET_SKILLS, {
            skip: 0,
            take: 1000
        });
    },
    getSkillLevels() {
        return AxiosInstance.post<PaginatedResponse<ILevelObject>>(API_ROUTES.BASE_DATA.GET_SKILL_LEVEL, {
            skip: 0,
            take: 1000
        });
    },
    getLanguages() {
        return AxiosInstance.post<PaginatedResponse<ILanguagesObject>>(API_ROUTES.BASE_DATA.GET_LANGUAGES, {
            skip: 0,
            take: 1000
        });
    },
    getCountries() {
        return AxiosInstance.post<PaginatedResponse<ICountry>>(API_ROUTES.BASE_DATA.COUNTRIES, {
            skip: 0,
            take: 1000
        });
    },
    getDegrees() {
        return AxiosInstance.post<PaginatedResponse<ICountry>>(API_ROUTES.BASE_DATA.DEGREES, {
            skip: 0,
            take: 1000
        });
    },
};