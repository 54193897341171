import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import React, { useState } from "react";
import { useSortAndFilters } from "../SortAndFiltersContext";
import styles from "./Filters.module.css";
import { Accordion, AccordionEventParams, AccordionTab } from 'primereact/accordion';
import { baseService } from "src/api/services/base";
import { getAxiosError } from "src/utils/get-axios-error";
import { toast } from "src/utils/toast";
import FilterBoxItem from "../FilterBoxItem";

const Filters: React.FC = () => {
    const { filtersIsOpen, setFiltersIsOpen, filterItems, setFilterItems, applyChanges,resetAll} = useSortAndFilters();
    const [loadingItems, setLoadingItems] = useState<Record<string, boolean>>({});

    const onCloseClicked = () => {
        setFiltersIsOpen(false);
        resetAll();
    }

    const onTabOpen = async (e: AccordionEventParams) => {
        const tempFilterItems = [...filterItems];
        if (!(tempFilterItems[e.index].preparedItems && tempFilterItems[e.index].preparedItems?.length)) {
            if (tempFilterItems[e.index].listItems) {
                tempFilterItems[e.index].preparedItems = filterItems[e.index].listItems?.map(((lItem) => ({
                    id: lItem as never,
                    title: lItem
                })));
                setFilterItems(tempFilterItems);
            } else {
                try {
                    setLoadingItems({
                        ...loadingItems,
                        [tempFilterItems[e.index].key]: true
                    });
                    const itemResponse = await baseService.getDynamicBaseData(tempFilterItems[e.index].listUrl!);
                    tempFilterItems[e.index].preparedItems = itemResponse.data.payload?.items;
                    setFilterItems(tempFilterItems);
                } catch (err) {
                    const error = getAxiosError(err);
                    toast.error(error.meta?.message!);
                } finally {
                    setLoadingItems({
                        ...loadingItems,
                        [tempFilterItems[e.index].key]: false
                    });
                }
            }
        }
    }

    const onApplyClicked = ()=>{
        applyChanges();
    }

    const onResetClicked = ()=>{
        resetAll();
    }

    return <div className={classNames(styles.filters, { [styles.opened]: filtersIsOpen })}>
        <Button icon="ph-x" className={classNames(styles["close-btn"])} aria-label="Cancel"
            onClick={onCloseClicked} />
        <h2>Filters</h2>
        <div className={styles["filter-items"]}>
            <Accordion className={styles["accordion"]}
                onTabOpen={onTabOpen}>
                {
                    filterItems.map(filterItem => (<AccordionTab key={filterItem.key}
                        header={<>
                            <span>{filterItem.title}</span>
                            {/* {1 === 1 && <Badge value="4502" className="ml-2" />} */}
                        </>} >
                        {
                            loadingItems[filterItem.key] ? <div>Loading...</div> : (
                                !!filterItem.preparedItems ? <FilterBoxItem identifier={filterItem.key} items={filterItem.preparedItems}
                                    idIsTitle={!!filterItem.listUrl}
                                    key={filterItem.key} /> : <div>There is no item</div>
                            )
                        }
                    </AccordionTab>))
                }
            </Accordion>
        </div>
        <div className={styles.actions}>
            <div>
                <Button label="Apply" onClick={onApplyClicked}/>
            </div>
            <div>
                <Button label="Reset" className="p-button-warning"
                    onClick={onResetClicked}/>
            </div>
        </div>
    </div>
}


export default Filters;