import dayjs from "dayjs";
import React from "react";
import { IActivityTimeLineItem } from "src/api/types/admin";
import './styles.css'

interface ITimeLineProps {
    data: IActivityTimeLineItem
}

export const TimeLineItem: React.FC<ITimeLineProps> = ({data}) => {
    return (
        <div className="time-line-item">
            <h4 className="event-name">{`${data.eventName}`}</h4>
            <p className="item-date">{dayjs(data.createdAt).format("YYYY-MM-DD HH:mm:ss")}</p>
            <div>
                {`On ${data.entityName} by id: ${data.entityId}`}
            </div>
            {/* <pre>{JSON.stringify(data.value,null,2)}</pre> */}
        </div>
    )
}