import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { InputText } from "primereact/inputtext";
import { TOrderedStarItem } from "src/types/dynamic-home";

type Props = {
  item: TOrderedStarItem;
  removeFromOrderedJob: (jobId: number) => void;
};

const Task = (props: Props) => {
  const { item, removeFromOrderedJob } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    touchAction: "none",
    maxWidth: 300,
    marginBottom: 8,
  };

  return (
    <div style={style} ref={setNodeRef} {...attributes}>
      <div style={{ height: 48 }} className="p-inputgroup flex-1">
        <span
          className="p-inputgroup-addon justify-content-start  "
          {...listeners}>
          {/* {getSectionTitle(task.key)} */}
          <i className="pi pi-arrows-v cursor-move	"></i>
        </span>
        <InputText placeholder="Job" value={item.title} />
        <span
          className="p-inputgroup-addon cursor-pointer"
          onClick={() => removeFromOrderedJob(item.id)}>
          <i className="pi pi-trash"></i>
        </span>
      </div>
    </div>
  );
};

export default Task;
