import { confirmDialog } from "primereact/confirmdialog";
import React, { useState } from "react";
import CustomCard from "src/components/Kit/CustomCard";
import { customerService } from "../../../../../../../api/services/customer";
import { IJobItem, JoBStatus } from "../../../../../../../api/types/job";
import { useCustomerJobs } from "../../CustomerJobsContext";
import styles from "./JobListItem.module.css";
import { classNames } from "primereact/utils";
import { toast } from "src/utils/toast";
interface Props {
  data: IJobItem;
  onJobToggledSuccessFully: (deletedServiceId: number) => void;
  onJobDeletedSuccessFully: (deletedServiceId: number) => void;
}

const JobListItem: React.FC<Props> = ({
  data,
  onJobToggledSuccessFully,
  onJobDeletedSuccessFully,
}) => {
  const { openEditJobModal } = useCustomerJobs();
  const [showDescription, setShowDescription] = useState(false);
  const onEditClicked = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (data.status === JoBStatus.Contracted) {
      toast.error("contracted job can't be edited");
    } else {
      openEditJobModal(data);
    }
  };

  const onToggleJobActivation = () => {
    if (data.status === JoBStatus.Contracted) {
      toast.error("Contracted Job can't be status changed.");
      return;
    }
    confirmDialog({
      message: `Are you sure you want to ${
        data.status === JoBStatus.Active ? "deactivate" : "activate"
      } ${data.title}`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          await customerService.toggleJob(
            data.id,
            data.status === JoBStatus.Active ? false : true,
          );
          onJobToggledSuccessFully(data.id);
        } catch (err) {
          console.log(err);
        } finally {
        }
      },
    });
  };

  const onDeleteJob = () => {
    if (data.status === JoBStatus.Contracted) {
      toast.error("Contracted Job can't be deleted.");
      return;
    }
    confirmDialog({
      message: `Are you sure you want to delete ${data.title}`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          await customerService.deleteJob(data.id);
          onJobDeletedSuccessFully(data.id);
        } catch (err) {
          console.log(err);
        } finally {
        }
      },
    });
  };

  return (
    <div>
      <CustomCard
        className={styles.job}
        onClick={() => {}}
        editButton
        onEditClick={onEditClicked}
        hasSwitchButton
        isSwitchButtonActive={data.status === "ACTIVE"}
        onToggleSwitchButton={onToggleJobActivation}
        deleteButton
        onDelete={onDeleteJob}>
        <div className="flex flex-column mt-4">
          <div>
            <span style={{ color: "var(--text-mute)" }}>Title</span>
            <span className={classNames(styles.title, "ml-3")}>
              {data?.title}
            </span>
          </div>
          <div className="mt-2">
            <span style={{ color: "var(--text-mute)" }}>category</span>
            <span className={classNames(styles["category-title"], "ml-3")}>
              {data?.category.ml_title.en}
            </span>
          </div>
          <div
            className={classNames(styles["description"], "mt-2")}
            style={{
              whiteSpace: showDescription ? "normal" : "nowrap",
            }}
            onClick={() => setShowDescription((prev) => !prev)}>
            <span style={{ color: "var(--text-mute)" }}>Description</span>
            <span className={"ml-3"}>{data?.description}</span>
          </div>
          <div className="mt-2">
            <span style={{ color: "var(--text-mute)" }}>Budget</span>
            <span className={classNames(styles["package-description"], "ml-3")}>
              {data?.price} KD
            </span>
          </div>
          <div className="mt-2">
            <span style={{ color: "var(--text-mute)" }}>Job deliverable</span>
            <span className={classNames(styles["package-description"], "ml-3")}>
              {data?.items.map((item, index) => (
                <span key={index}>{item}, </span>
              ))}
            </span>
          </div>
          <div className="mt-2">
            <span style={{ color: "var(--text-mute)" }}>Tags</span>
            <span className={classNames(styles["package-description"], "ml-3")}>
              {data?.tags.map((item, index) => (
                <span key={index}>{item}, </span>
              ))}
            </span>
          </div>

          <div className="flex flex-row align-items-center mt-2">
            <span style={{ color: "var(--text-mute)" }}>Revision</span>

            <i
              className={"pi pi-user-edit ml-3"}
              style={{ fontSize: "17px" }}
            />
            <span className="service-details-package-description ml-2">
              {data?.revisions ? data.revisions : "Unlimited"}
            </span>
          </div>
          <div className="flex flex-row align-items-center mt-2">
            <span style={{ color: "var(--text-mute)" }}>Delivery Time</span>
            <span className="service-details-package-description ml-2">
              {data.deliveryTime} {data.deliveryTimeUnit.ml_title.en}
            </span>
          </div>
          <div className="flex flex-row align-items-center mt-2">
            <span style={{ color: "var(--text-mute)" }}>Status</span>
            <span
              className=" ml-2"
              style={{
                color: data.style?.status?.TextColor,
                background: data.style?.status?.BackgroundColor,
                textAlign: "center",
                borderRadius: 8,
                padding: 4,
              }}>
              {data.status}
            </span>
          </div>
        </div>
      </CustomCard>
    </div>
  );
};

export default JobListItem;
