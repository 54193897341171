import { Column } from "primereact/column";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { contractService } from "src/api/services/contract";
import { IContractListItem } from "src/api/types/contracts";
import { dateBodyTemlateOfContract, dateCreatedAtBodyTemplate, userDetailsOfContract } from "src/components/App/TableHelpers";
import { DEFAULT_PAGE_SIZE } from "src/constants/pagination";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { getAxiosError } from "src/utils/get-axios-error";
import { toast } from "src/utils/toast";
import { useUserDetailsTransactionsAndContracts } from "../../UserDetailsTransactionsAndContractsContext";
import { Card } from "primereact/card";
import SortAndFilters from "src/components/App/SortAndFilters";
import { filterListNames } from "src/constants/filter-list-names";
import { IGetListBody } from "src/api/types/base-data";
import { useListCSVDownload } from "src/hooks/use-list-csv-download";

const ContractList: React.FC = () => {

    const { contracts, setContracts, userType, userId, contractsLazyParams, setContractsLazyParams } = useUserDetailsTransactionsAndContracts();
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState<IGetListBody>();

    const { exportLoading, exportResult } = useListCSVDownload(contractService.export, 'contracts');

    const onExportClicked = (data: IGetListBody) => {
        const tempFilters = { ...data };
        if (tempFilters.filters) {
            if (userType === 'freelancer') {
                tempFilters.filters.freelancer = userId;
            } else {
                tempFilters.filters.customer = userId;
            }
        } else {
            tempFilters.filters = {
                ...(userType === "freelancer" ? { freelancer: userId } : { customer: userId })
            }
        }
        exportResult(tempFilters);
    }



    const fetchContracts = useCallback(async () => {
        try {
            setLoading(true);
            const tempFilters = { ...filters };
            if (tempFilters.filters) {
                if (userType === 'freelancer') {
                    tempFilters.filters.freelancer = userId;
                } else {
                    tempFilters.filters.customer = userId;
                }
            } else {
                tempFilters.filters = {
                    ...(userType === "freelancer" ? { freelancer: userId } : { customer: userId })
                }
            }

            const response = await contractService.getAll({
                filters: tempFilters.filters,
                skip: contractsLazyParams.page! * DEFAULT_PAGE_SIZE,
                take: DEFAULT_PAGE_SIZE
            });
            setContracts(response.data.payload);
        } catch (err) {
            const error = getAxiosError(err);
            toast.error(error.meta?.message!);
        } finally {
            setLoading(false);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [contractsLazyParams, filters])

    useEffect(() => {
        fetchContracts()
    }, [fetchContracts]);

    const onPageChange = (e: DataTablePFSEvent) => {
        setContractsLazyParams(e);
    }

    const onSortAndFiltersChanged = (data: IGetListBody) => {
        setFilters(data);

        setContractsLazyParams({
            ...contractsLazyParams,
            page: 0,
            first: 1
        })
    }


    const getContractDetailsActionsTemplate = (rowData: IContractListItem) => {
        return <>
            <Link to={ROUTE_CONSTANTS.DASHBOARD.CONTRACTS.DETAILS.GET_BY_DATA(rowData.id).ABSOLUTE}>Details</Link>
        </>
    }

    return <div className="listing-page">
        <SortAndFilters listName={filterListNames.CONTRACTS} onChange={onSortAndFiltersChanged}
            exportLoading={exportLoading}
            onExportClicked={onExportClicked}
            ignoreFilterItem={userType} />
        <div className="q-data-table">
            <Card>
                <DataTable value={contracts?.items} emptyMessage="No Contracts found." loading={loading}
                    lazy
                    first={contractsLazyParams.first}
                    paginator
                    rows={DEFAULT_PAGE_SIZE}
                    totalRecords={contracts?.pagination.totalItems}
                    onPage={onPageChange}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    dataKey="id"
                    className="qud-table"  >
                    <Column field="id" header="ID" />
                    <Column field="freelancer" header="Freelancer" body={userDetailsOfContract} />
                    <Column field="openDate" header="Open Date" body={dateBodyTemlateOfContract} />
                    <Column field="dueDate" header="Due Date" body={dateBodyTemlateOfContract} />
                    <Column field="dueDateStatus" header="Due Date Status" />
                    {/* <Column field="fee" header="Fee" /> */}
                    <Column field="status" header="Status" />
                    <Column field="createdAt" header="Created At" body={dateCreatedAtBodyTemplate}></Column>
                    <Column header="" body={getContractDetailsActionsTemplate}></Column>
                </DataTable>
            </Card>
        </div>
    </div>
}

export default ContractList;