import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { contractService } from "src/api/services/contract";
import { Channel, SendBirdProvider } from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import { IChannelInfo } from "src/api/types/contracts";


const ChatAppId = window?.SEND_BIRD_APP_ID || '9168B6C7-F438-4E05-8BE5-10D81B12FFC8';


const ContractChat: React.FC = () => {
    const routeParams = useParams<{ id: string }>();
    const [loading, setLoading] = useState<boolean>(false);
    const [channelInfo, setChannelInfo] = useState<IChannelInfo>();

    const fetchChannelInfo = useCallback(async () => {
        if (routeParams.id) {
            setLoading(true);
            try {
                const result = await contractService.getChannelInfo(routeParams.id);
                setChannelInfo(result.data.payload);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
    }, [routeParams.id]);

    useEffect(() => {
        fetchChannelInfo();
    }, [fetchChannelInfo])

    return <div style={{marginTop:24}}>
        {
            loading && <div>Loading..</div>
        }
        {
            !!channelInfo && <SendBirdProvider appId={ChatAppId} userId={channelInfo.userId}
            
            accessToken={channelInfo.token}
                config={{
                    logLevel:"error",
                }}>
                <div style={{ height: '500px' }}>
                    <Channel channelUrl={channelInfo.url}  
                    renderMessageInput={()=>{return null;}}
                    useReaction={false}
                    useMessageGrouping={false}   
                    
                    />
                    
                </div>
            </SendBirdProvider>
        }
    </div>
}

export default ContractChat;