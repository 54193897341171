// Define the generic types for both arrays
type Array1Item = { id: number | string; [key: string]: any }; // id can be number or string, other properties can vary
type Array2Item = { id: number | string; [key: string]: any }; // id can be number or string, other properties can vary

export function getItemsInArray1ButNotInArray2(
  array1: Array1Item[],
  array2: Array2Item[],
): Array1Item[] {
  // Create a Set of all ids from array2
  const idsInArray2 = new Set(array2.map((item) => item.id));

  // Filter array1 to return items whose id is not in array2
  return array1.filter((item) => !idsInArray2.has(item.id));
}
