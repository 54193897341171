import { useCallback, useState } from "react";
import { getAuthorization } from "src/utils/get-authorization";

export interface IAxiosResponse<T>{
    payload?:T;
    meta?:{
        message?:string;
        code?:number;
        messageType?:string;
    }
}

export interface IPaginationData{
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
}

export interface IPaginatedPayload<T> {
    items: T[];
    pagination: IPaginationData;
}

export type PaginatedResponse<T> = IAxiosResponse<IPaginatedPayload<T>>;

export interface IUploadedMediaPayload {
    id: number;
    originalName: string;
    obfuscatedName: string;
    url: string;
}

export type IUploadMediaResponse = IAxiosResponse<IUploadedMediaPayload>;

interface IUploaderHookArgs {
    onComplete: (data: IUploadedMediaPayload) => void;
    onError: (err: unknown) => void;
    setUploading?:(value:boolean)=>void;
}

function useUploadMedia(data: IUploaderHookArgs) {
    const {onComplete,onError,setUploading:setParentUploading} = data;
    const [progress, setProgress] = useState<number>(0);
    const [uploading, setUploading] = useState<boolean>(false);

    const upload = useCallback((file: File) => {
        try{
            const request = new XMLHttpRequest();
            let uploadUrl = window?.REACT_APP_API_URL ?`${window?.REACT_APP_API_URL}media` : "https://api.dev.qudra.cloud/media"

            request.open("POST", uploadUrl);
            request.setRequestHeader('Authorization', getAuthorization() || "");
            request.upload.addEventListener("progress",function(e){
                setUploading(true);
                setParentUploading && setParentUploading(true);
                let percentage = (e.loaded / e.total) * 100;
                setProgress(()=>{
                    return Math.floor(percentage)
                });
            });
            request.addEventListener("load",function(e){
                setProgress(100);
                setUploading(false);
                setParentUploading && setParentUploading(false);
                onComplete((JSON.parse(request.response) as IUploadMediaResponse).payload!);
            });
            const data = new FormData();
            data.append("file",file);
            request.send(data);
        }catch(err){
            onError(err);
        }
    },[onComplete,onError,setParentUploading])

    return {
        progress,
        upload,
        uploading
    }
}

export default useUploadMedia;