import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { customerService } from "src/api/services/customer";
import { freelancerService } from "src/api/services/freelancer";
import { IBaseUser } from "src/api/types/user";
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import { getAxiosError } from "src/utils/get-axios-error";
import { toast } from "src/utils/toast";
import GeneralFormErrorMessage from "../GeneralFormErrorMessage";
import { INewUser } from "./types";
import { newUserValidationSchema } from "./validation-schema";
import styles from "./NewUser.module.css";

interface INewUserProps {
    type: "customer" | "freelancer";
    afterSave: (data: IBaseUser) => void;
}

const NewUser: React.FC<INewUserProps> = (props) => {
    const { type, afterSave } = props;
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [apiError, setApiError] = useState<string>("");


    const { control, reset, formState: { errors }, handleSubmit } = useForm<INewUser>({
        defaultValues: {
            firstName: "",
            lastName: "",
            email: ""
        },
        mode: "all",
        resolver: yupResolver(newUserValidationSchema())
    });

    const onFormSubmit = async (data: INewUser) => {
        try {
            setLoading(true);
            setApiError("");
            const service = type === "customer" ? customerService : freelancerService;
            const result = await service.create(data.email, data.firstName, data.lastName);
            reset({
                email: "",
                firstName: "",
                lastName: ""
            });
            afterSave(result.data.payload);
            toast.success(`New ${type==="customer"?"Customer":"Freelancer"} Successfuly created`);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error.meta?.message || "Server Error";
            setApiError(message);
        } finally {
            setLoading(false);
        }
    }

    return <div className={`mb-4`}>
        <Fieldset >
                <h3>{`New ${type === "customer" ? "Customer" : "Freelancer"}`}</h3>
            <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
                {
                    apiError && <GeneralFormErrorMessage message={apiError} />
                }
                <div className={styles.content}>
                    <div>
                        <FormFieldWrapper>
                            <label htmlFor="email" className={classNames({ 'p-error': errors.email })}>{t("fields.email")}</label>
                                <Controller name="email" control={control} render={({ field }) => (
                                    <InputText type="email" {...field} id="email" className={classNames('block w-full', { 'p-invalid': !!errors.email })} />
                                )} />
                        </FormFieldWrapper>
                    </div>
                    <div>
                        <FormFieldWrapper>
                            <label htmlFor="firstName" className={classNames({ 'p-error': errors.firstName })}>{t("fields.firstName")}</label>
                                <Controller name="firstName" control={control} render={({ field }) => (
                                    <InputText type="text" {...field} id="firstName" className={classNames('block w-full', { 'p-invalid': !!errors.firstName })} />
                                )} />
                        </FormFieldWrapper>
                    </div>
                    <div>
                        <FormFieldWrapper>
                            <label htmlFor="lastName" className={classNames({ 'p-error': errors.lastName })}>{t("fields.lastName")}</label>
                                <Controller name="lastName" control={control} render={({ field }) => (
                                    <InputText type="text" {...field} id="lastName" className={classNames('block w-full', { 'p-invalid': !!errors.lastName })} />
                                )} />
                        </FormFieldWrapper>
                    </div>
                </div>
                <div>
                    <Button label={t("actions.save")} className="p-button-primary" type="submit" loading={loading} />
                </div>
            </form>
        </Fieldset>
    </div>
}

export default NewUser;