export enum PlatformEnum {
  WEB = "WEB",
  ANDROID = "ANDROID",
  IOS = "IOS",
}

export enum ICouponAmountType {
  PERCENTAGE = "PERCENTAGE",
  FIXED = "FIXED",
}

export enum ICouponAppliedTo {
  ALL = "ALL",
  FIRST_CONTRACT = "FIRST_CONTRACT",
}

export interface ICouponFormData {
  codes: string;
  amountType: ICouponAmountType.PERCENTAGE | ICouponAmountType.FIXED;
  amountValue: number;
  startDate?: string;
  expireDate?: string;
  appliedTo: ICouponAppliedTo.ALL;
  usagePerUser?: number | string;
  usageLimit?: number | string;
  description?: string;
  minimumPackagePrice?: number;
  platforms: Array<PlatformEnum.WEB | PlatformEnum.ANDROID | PlatformEnum.IOS>;
}

export interface ICouponItem {
  id: number;
  code: string;
  amountType: ICouponAmountType.PERCENTAGE | ICouponAmountType.FIXED;
  amountValue: number;
  expireDate: string;
  status: string;
  style: {
    status: {
      BackgroundColor: string;
      TextColor: string;
    };
  };
}

export interface ICouponDetails extends Omit<ICouponFormData, "codes"> {
  id: number;
  status: CouponStatus;
  code: string;
  revokeReason: string;
}

export enum CouponStatus {
  Pending = "PENDING",
  Active = "ACTIVE",
  Expired = "EXPIRED",
  Revoked = "REVOKED",
  MaxedOut = "MAXED_OUT",
}
export interface ICouponAllFormData extends ICouponFormData {
  startDateHasSpecifiedValue?: boolean;
  expireDateHasSpecifiedValue?: boolean;
  usagePerUserHasSpecifiedValue?: boolean;
  usageLimitHasSpecifiedValue?: boolean;
}
