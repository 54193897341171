import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { freelancerService } from "src/api/services/freelancer";
import { IBaseUser } from "src/api/types/user";
import ListingPage from "src/components/App/ListingPage";
import { DEFAULT_PAGE_SIZE } from "src/constants/pagination";
import { useTitle } from "src/hooks/useTitle";
import { dateCreatedAtBodyTemplate, emailBodyTemplate, emailVerifiedBodyTemplate, fullNameBodyTemplate, statusTemplate } from "src/components/App/TableHelpers";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { userService } from "src/api/services/user";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import NewUser from "src/components/App/NewUser";
import SortAndFilters from "src/components/App/SortAndFilters";
import { IGetListBody } from "src/api/types/base-data";
import { filterListNames } from "src/constants/filter-list-names";
import { toast } from "src/utils/toast";
import { useListCSVDownload } from "src/hooks/use-list-csv-download";

const Freelancers: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t("pageTitles.freelancers"))

    const [activationTargetLoading, setActivationTargetLoading] = useState<number>(-1);
    const [removingTargetLoading, setRemovingTargetLoading] = useState<number>(-1);
    const [loading, setLoading] = useState<boolean>(false);
    const [freelancers, setFreelancers] = useState<IBaseUser[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filters,setFilters] = useState<IGetListBody>();
    
    const [lazyParams, setLazyParams] = useState<DataTablePFSEvent>({
        filters: {

        },
        first: 0,
        multiSortMeta: [],
        page: 0,
        pageCount: 2,
        rows: DEFAULT_PAGE_SIZE,
        sortField: "",
        sortOrder: null,
    });


    const {exportLoading,exportResult} = useListCSVDownload(freelancerService.exportAll,'freelancers');

    const fetchFreelancers = useCallback(async () => {
        try {
            setLoading(true);
            const body:IGetListBody = !!filters ? {
                ...filters,
                ...{
                    skip: lazyParams.page! * DEFAULT_PAGE_SIZE,
                    take: DEFAULT_PAGE_SIZE
                }
            } : {
                skip: lazyParams.page! * DEFAULT_PAGE_SIZE,
                take: DEFAULT_PAGE_SIZE
            }
            const result = await freelancerService.getAll(body);
            setTotalRecords(result.data.payload.pagination.totalItems);
            setFreelancers(result.data.payload.items);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, [lazyParams,filters]);

    useEffect(() => {
        fetchFreelancers();
    }, [fetchFreelancers]);

    const onPageChange = (e: DataTablePFSEvent) => {
        setLazyParams(e);
    }

    const changeUserActivation = (rowData: IBaseUser) => {
        confirmDialog({
            message: `Are you sure you want to ${rowData.status === 'Active' ? "deactivate" : "activate"} ${rowData.email}`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                try {
                    const tempFreelancers = [...freelancers];
                    setActivationTargetLoading(rowData.id);
                    await userService.changeActivation(rowData.id, rowData.status !== "Active")
                    const targetItemIndex = tempFreelancers.indexOf(rowData);
                    tempFreelancers[targetItemIndex].status = rowData.status === "Active" ? "Inactive" : "Active";
                    setFreelancers(tempFreelancers);
                } catch (err) {
                    console.log(err);
                } finally {
                    setActivationTargetLoading(-1);
                }
            }
        })
    }

    const removeUser = (rowData:IBaseUser)=>{
        confirmDialog({
            message: `Are you sure you want to remove ${rowData.email}`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                try {
                    const tempFreelancers = [...freelancers];
                    setRemovingTargetLoading(rowData.id);
                    await freelancerService.removeUser(rowData.id)
                    const targetItemIndex = tempFreelancers.indexOf(rowData);
                    tempFreelancers.splice(targetItemIndex,1);
                    setFreelancers(tempFreelancers);
                    toast.success("Freelancer removed successfuly");
                } catch (err) {
                    console.log(err);
                } finally {
                    setRemovingTargetLoading(-1);
                }
            }
        })
    }

    const changeActivationTemplate = (rowData: IBaseUser) => {
        return <>
            <Button
                label={t(rowData.status === 'Active' ? "actions.deactive" : "actions.active")}
                className={classNames("p-button-outlined p-button-sm",{"p-button-danger":rowData.status === "Active","p-button-secondary":rowData.status!=="Active"})}
                type="button"
                loading={activationTargetLoading === rowData.id}
                onClick={() => { changeUserActivation(rowData) }}
            />
        </>
    }

    const removeUserTemplate = (rowData: IBaseUser) => {
        return <>
            <Button
                label="Remove"
                className="p-button-outlined p-button-sm p-button-danger"
                type="button"
                loading={removingTargetLoading === rowData.id}
                onClick={() => { removeUser(rowData) }}
            />
        </>
    }

    const onFreelancerCreated = (data:IBaseUser)=>{
        setFreelancers([data,...freelancers]);
    }

    const onSortAndFiltersChanged =  (data:IGetListBody)=>{
        setFilters(data);
        setLazyParams({
            ...lazyParams,
            page:0,
            first:1
        })
    }



    return <>
        <ListingPage title={t("titles.freelancers")}>
            <NewUser type="freelancer" afterSave={onFreelancerCreated}/>
            <div className="q-data-table">
            <SortAndFilters listName={filterListNames.FREELANCERS} onChange={onSortAndFiltersChanged}
                onExportClicked={exportResult}
                exportLoading={exportLoading}/>
            <Card>
                <DataTable value={freelancers} emptyMessage="No Freelancers found." loading={loading}
                    lazy
                    first={lazyParams.first}
                    paginator
                    rows={DEFAULT_PAGE_SIZE}
                    totalRecords={totalRecords}
                    onPage={onPageChange}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    dataKey="id"
                    className="qud-table"  >
                    <Column field="id" header="ID" />
                    <Column field="email" header="Email Address" body={emailBodyTemplate}/>
                    <Column header="Full Name" body={fullNameBodyTemplate}></Column>
                    <Column field="status" header="Status" body={statusTemplate}/>
                    <Column field="emailVerified" header="Email Verified" body={emailVerifiedBodyTemplate}></Column>
                    <Column field="createdAt" header="Created At" body={dateCreatedAtBodyTemplate}></Column>
                    <Column header="" body={changeActivationTemplate} />
                    <Column header="" body={removeUserTemplate} />
                </DataTable>
            </Card>
            </div>
        </ListingPage>
        <ConfirmDialog />
    </>
}

export default Freelancers;