import dayjs from "dayjs";
import { Button } from "primereact/button";
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { couponService } from "src/api/services/coupon";
import { CouponStatus, ICouponDetails } from "src/api/types/coupon";
import Header from "src/components/App/PageHeader";
import Card from "src/components/Kit/Card";
import Modal from 'react-modal';
import { modalStyle } from "src/constants/modal-styles";
import { InputText } from "primereact/inputtext";
import CouponContracts from "../components/CouponContracts";

const CouponDetails: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [couponInfo, setCouponInfo] = useState<ICouponDetails>({} as ICouponDetails);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [reason, setReason] = useState<string>("");
    const [loadingRevoke, setLoadingRevoke] = useState<boolean>(false);
    const { id } = useParams();

    const fetchCouponInfo = async () => {
        try {
            setLoading(true);
            const result = await couponService.getDetailsInfo(+id!);
            setCouponInfo(result.data.payload);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        id && fetchCouponInfo();
    }, [id]);

    const handleRevoke = async (e: any) => {
        e.preventDefault();
        try {
            setLoadingRevoke(true);
            await couponService.revokeCoupon(+id!, { revokeReason: reason });
            setShowModal(false);
            setReason("");
            fetchCouponInfo();
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingRevoke(false);
        }
    }

    return <>
        <Header
            hasGoBack={true}
            title='Coupon Information'
        />
        {
            loading
                ? <ProgressSpinner />
                : <>
                    <Card>
                        <div className="flex flex-column gap-1" style={{ padding: "20px" }}>
                            <div className="flex gap-1">
                                <div className="flex-1">
                                    <p style={{ color: "#aaa" }}>Code</p>
                                    <p>{couponInfo.code}</p>
                                </div>
                                <div className="flex-1">
                                    <p style={{ color: "#aaa" }}>Status</p>
                                    <p>{couponInfo.status}</p>
                                </div>
                                <div className="flex-1">
                                    <p style={{ color: "#aaa" }}>Description</p>
                                    <p>{couponInfo?.description || "---"}</p>
                                </div>
                            </div>
                            <div className="flex gap-1">
                                <div className="flex-1">
                                    <p style={{ color: "#aaa" }}>Amount Type</p>
                                    <p>{couponInfo?.amountType}</p>
                                </div>
                                <div className="flex-1">
                                    <p style={{ color: "#aaa" }}>Amount Value</p>
                                    <p>{couponInfo?.amountValue}</p>
                                </div>
                                <div className="flex-1">
                                    <p style={{ color: "#aaa" }}>Platform</p>
                                    <p>{couponInfo?.platforms && couponInfo?.platforms.join(", ")}</p>
                                </div>
                            </div>
                            <div className="flex gap-1">
                                <div className="flex-1">
                                    <p style={{ color: "#aaa" }}>Use Limit(Code)</p>
                                    <p>{couponInfo?.usageLimit || "Unlimited"}</p>
                                </div>
                                <div className="flex-1">
                                    <p style={{ color: "#aaa" }}>Use Limit(User)</p>
                                    <p>{couponInfo?.usagePerUser || "Unlimited"}</p>
                                </div>
                                <div className="flex-1">
                                    <p style={{ color: "#aaa" }}>Applied To</p>
                                    <p>{couponInfo?.appliedTo || "---"}</p>
                                </div>
                            </div>
                            <div className="flex gap-1">
                                <div className="flex-1">
                                    <p style={{ color: "#aaa" }}>Start Date</p>
                                    <p>{couponInfo.startDate ? dayjs(couponInfo.startDate).format("YYYY-MM-DD HH:mm") : "Whenever"}</p>
                                </div>
                                <div className="flex-1">
                                    <p style={{ color: "#aaa" }}>Expire Date</p>
                                    <p>{couponInfo.expireDate ? dayjs(couponInfo.expireDate).format("YYYY-MM-DD HH:mm") : "Whenever"}</p>
                                </div>
                                <div className="flex-1"></div>
                            </div>
                            <div className="flex gap-1">
                                <div className="flex-1">
                                    <p style={{ color: "#aaa" }}>Reason if revoke</p>
                                    <p>{couponInfo.revokeReason || "---"}</p>
                                </div>
                                <div className="flex-1">
                                    <p style={{ color: "#aaa" }}>Minimum Package Price</p>
                                    <p>{couponInfo?.minimumPackagePrice || "---"}</p>
                                </div>
                                <div className="flex-1"></div>
                            </div>
                            <div className="flex gap-1">
                                <div className="flex-1">
                                    {
                                        couponInfo.status !== CouponStatus.Revoked && <>
                                            {/* <p style={{ color: "#aaa" }}>Revoke</p> */}
                                            <Button onClick={() => setShowModal(true)}>Revoke</Button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </Card>
                    <br />
                    <CouponContracts id={+id!} />
                </>
        }
        <Modal
            ariaHideApp={false}
            style={modalStyle}
            contentLabel={"Revoke Coupon"}
            isOpen={showModal}
            onRequestClose={() => setShowModal(true)}>
            <div className="flex flex-column align-items-center justify-content-center">
                <i className="ph-info" style={{ fontSize: "80px" }}></i>
                <p>Care to give a reason Why?</p>
                <form onSubmit={handleRevoke} className="flex flex-column">
                    <InputText type="text" onChange={(e) => setReason(e.target.value)} className="block w-full" style={{ margin: "0 auto" }} />
                    <div className="flex gap-2 mt-4">
                        <Button className="p-button-outlined p-button-secondary" disabled={loadingRevoke}
                            onClick={() => setShowModal(false)}>Cancel</Button>
                        <Button type="submit" disabled={loadingRevoke}>Apply</Button>
                    </div>
                </form>
            </div>
        </Modal>
    </>;
}

export default CouponDetails;