import { useState } from "react";
import StartManager from "./StarManager";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { API_ROUTES } from "src/constants/api-routes";

const ToShowItems: React.FC = () => {
  const [tab, setTab] = useState(0);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setTab(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs value={tab} onChange={handleChange}>
          <Tab label="Jobs" />
          <Tab label="Packages" />
          <Tab label="Freelancers" />
          <Tab label="Categories" />
        </Tabs>
      </Box>
      {tab === 0 && (
        <StartManager
          orderedItemsUrl={API_ROUTES.DYNAMIC_HOME.GET_ORDERED_JOBS}
          searchUrl={API_ROUTES.DYNAMIC_HOME.GET_JOBS}
          updateOrderedItemsUrl={API_ROUTES.DYNAMIC_HOME.UPDATE_ORDERED_JOBS}
          payloadKey="jobs"
          name="jobs"
        />
      )}
      {tab === 1 && (
        <StartManager
          orderedItemsUrl={API_ROUTES.DYNAMIC_HOME.GET_ORDERED_PACKAGES}
          searchUrl={API_ROUTES.DYNAMIC_HOME.GET_PACKAGES}
          updateOrderedItemsUrl={
            API_ROUTES.DYNAMIC_HOME.UPDATE_ORDERED_PACKAGES
          }
          payloadKey="packages"
          name="packages"
        />
      )}
      {tab === 2 && (
        <StartManager
          orderedItemsUrl={API_ROUTES.DYNAMIC_HOME.GET_ORDERED_FREELANCERS}
          searchUrl={API_ROUTES.DYNAMIC_HOME.GET_FREELANCERS}
          updateOrderedItemsUrl={
            API_ROUTES.DYNAMIC_HOME.UPDATE_ORDERED_FREELANCERS
          }
          payloadKey="freelancers"
          name="freelancers"
        />
      )}
      {tab === 3 && (
        <StartManager
          orderedItemsUrl={API_ROUTES.DYNAMIC_HOME.GET_ORDERED_CATEGORIES}
          searchUrl={API_ROUTES.DYNAMIC_HOME.GET_CATEGORIES}
          updateOrderedItemsUrl={
            API_ROUTES.DYNAMIC_HOME.UPDATE_ORDERED_CATEGORIES
          }
          payloadKey="categories"
          name="categories"
        />
      )}
    </>
  );
};

export default ToShowItems;
