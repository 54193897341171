import React from "react";
import { useNavigate } from "react-router-dom";
import { IFreelancerServices } from "src/api/types/base-data";
import CustomCard from "src/components/Kit/CustomCard";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { useFreelancerServices } from "../../FreelancerServicesContext";
import { freelancerService } from "src/api/services/freelancer";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { toast } from "src/utils/toast";

interface IServiceListItemProps {
  data: IFreelancerServices;
  onServiceRemovedSuccessFully: (deletedServiceId: number) => void;
}

const ServiceListItem: React.FC<IServiceListItemProps> = ({
  data,
  onServiceRemovedSuccessFully,
}) => {
  const navigate = useNavigate();
  const { openEditServiceModal } = useFreelancerServices();
  const handleNavigateToService = () => {
    navigate(
      ROUTE_CONSTANTS.DASHBOARD.FREELANCERS.SERVICES.SERVICE.GET_BY_DATA(
        data.freelancer.id,
        data.id,
      ).ABSOLUTE,
    );
  };

  const onEditClicked = (e: React.MouseEvent) => {
    e.stopPropagation();
    console.log("Here");
    openEditServiceModal(data);
  };
  const onDeleteService = (e: React.MouseEvent) => {
    e.stopPropagation();
    confirmDialog({
      message: `Are you sure you want to remove ${data.title}`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          await freelancerService.removeService(data.id);
          onServiceRemovedSuccessFully(data.id);
          toast.success("Service removed successfully");
        } catch (err) {
          console.log(err);
        } finally {
          // setRemovingTargetLoading(-1);
        }
      },
    });
  };
  return (
    <CustomCard
      className="freelancer-service-card mt-3"
      onClick={handleNavigateToService}
      editButton
      deleteButton
      onDelete={onDeleteService}
      onEditClick={onEditClicked}>
      <span className="freelancer-service-card-title">{data.title}</span>
      <span className="freelancer-service-card-category-title">
        {data.category.title}
      </span>
      <span className="freelancer-service-card-description">
        {data.description}
      </span>
      <div className="freelancer-service-card-footer">
        <i className={"pi pi-box"} style={{ fontSize: "22px" }} />
        <span className="ml-3" style={{ fontSize: "14px" }}>
          {data.packages.length} packages
        </span>
      </div>
    </CustomCard>
  );
};

export default ServiceListItem;
