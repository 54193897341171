import { ConfirmDialog } from "primereact/confirmdialog";
import { useLocation, useNavigate } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import Header from "src/components/App/PageHeader";
import { JoBStatus } from "../../../../../../../api/types/job";
import { customerProfileTabs } from "../../../constants/index";
import { useCustomerJobs } from "../../CustomerJobsContext";
import EditJobModal from "../EditJobModal";
import JobListItem from "../JobListItem";

const Jobs: React.FC = () => {
  const { jobs, loading, editingJobItem, setJobs } = useCustomerJobs();
  const location = useLocation();
  const currentTab = location.pathname.split("/");
  const navigate = useNavigate();

  const handleChangeTab = (selectedTab: string) => {
    if (selectedTab !== currentTab[currentTab.length - 1]) {
      navigate(
        ROUTE_CONSTANTS.DASHBOARD.CUSTOMERS.DETAILS.GET_BY_DATA(
          currentTab[currentTab.length - 2],
        ).ABSOLUTE,
      );
    }
  };
  const onJobToggledSuccessFully = (toggledJobId: number) => {
    if (jobs) {
      const remainedList = jobs.map((item) => {
        if (item.id === toggledJobId) {
          return {
            ...item,
            status:
              item.status === JoBStatus.Active
                ? JoBStatus.Closed
                : JoBStatus.Active,
          };
        }
        return item;
      });
      setJobs(remainedList);
    }
  };
  const onJobDeletedSuccessFully = (jobId: number) => {
    if (jobs) {
      const remainedList = jobs.filter((item) => item.id !== jobId);
      setJobs(remainedList);
    }
  };
  return !loading ? (
    <>
      <Header
        hasGoBack={true}
        title={`Jobs`}
        tabs={customerProfileTabs}
        currentTab={currentTab[currentTab.length - 1]}
        updateTab={(selectedTab) => handleChangeTab(selectedTab)}
      />
      <div className="grid flex justify-content-center">
        {jobs && jobs.length > 0 ? (
          <div className="xl:col-8 lg:col-8 md:col-8 sm:col-12">
            {jobs.map((service, index) => (
              <JobListItem
                key={index}
                data={service}
                onJobToggledSuccessFully={onJobToggledSuccessFully}
                onJobDeletedSuccessFully={onJobDeletedSuccessFully}
              />
            ))}
          </div>
        ) : (
          <span>There is no jobs!</span>
        )}
      </div>
      <ConfirmDialog />

      {!!editingJobItem && <EditJobModal />}
    </>
  ) : (
    <span>loading....</span>
  );
};
export default Jobs;
