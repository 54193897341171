import { useState, useCallback, useEffect } from "react";
import { contextFactory } from "src/utils/context-factory";
import { staticPagesService } from "src/api/services/static-pages";
import { IStaticPageItem, IStaticPage } from "src/api/types/static-pages";
import { toast } from "src/utils/toast";
import { getAxiosError } from "src/utils/get-axios-error";

interface IStaticPagesContext {
  pagesItem: IStaticPageItem[];
  setPagesItem: React.Dispatch<React.SetStateAction<IStaticPageItem[]>>;
  activePage?: IStaticPage;
  setActivaPage?: React.Dispatch<React.SetStateAction<IStaticPage>>;
  selectedItem?: number;
  setSelectedItem?: React.Dispatch<React.SetStateAction<number | undefined>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loadingPages: boolean;
  setLoadingPages: React.Dispatch<React.SetStateAction<boolean>>;
  saving: boolean;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  changeActivePage: (sender: number) => void;
  
}

export const [StaticPagesContext, useStaticPages] =
  contextFactory<IStaticPagesContext>("StaticPagesContext", "useStaticPages");

interface IStaticPagesProviderProps {
  children: React.ReactNode;
}
export const StaticPagesProvider: React.FC<IStaticPagesProviderProps> = ({
  children,
}) => {
  const [pagesItem, setPagesItem] = useState<IStaticPageItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingPages, setLoadingPages] = useState(true);
  const [selectedItem, setSelectedItem] = useState<number>();
  const [saving,setSaving] = useState(false);

  const [activePage, setActivePage] = useState<IStaticPage>();

  const getPages = useCallback(async () => {
    try {
      setLoadingPages(true);
      const response = await staticPagesService.getAll();
      setPagesItem(response.data.payload.items);
      getStaticPageDetaile(response.data.payload.items[0].id);
    } catch (err) {
      const error = getAxiosError(err);
      toast.error(error.meta?.message!);
    } finally {
      setLoadingPages(false);
    }
  }, []);

  useEffect(() => {
    getPages();
  }, [getPages]);

  const changeActivePage = (sender: number) => {
    getStaticPageDetaile(sender);
  };

  const getStaticPageDetaile = async (id: number | string) => {
    try {
      setLoading(true);
      const response = await staticPagesService.getById(id);
      setActivePage(response.data.payload);
    } catch (err) {
      console.log(err);
      setLoading(false);
      const error = getAxiosError(err);
      toast.error(error.meta?.message!);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StaticPagesContext.Provider
      value={{
        pagesItem,
        setPagesItem,
        loading,
        setLoading,
        activePage,
        selectedItem,
        setSelectedItem,
        changeActivePage,
        saving,
        setSaving,
        loadingPages,
        setLoadingPages
      }}
    >
      {children}
    </StaticPagesContext.Provider>
  );
};
