import React, { useState } from "react";
import Header from "src/components/App/PageHeader";
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { settingItems } from "./setting-items";
import SettingManagment from "./components/SettingManagment";


const GlobalSetting: React.FC = () => {
    const [selectedSetting, setSelectedSetting] = useState(settingItems[0]);

    const onSettingChanged = (e:DropdownChangeParams) => {
        setSelectedSetting(e.value);
    }
    return <>
        <Header
            hasGoBack={true}
            title='Global Setting'
        />
        <div>
            <div>
            <Dropdown value={selectedSetting} onChange={onSettingChanged} options={settingItems} optionLabel="name" 
                placeholder="Select Setting" className="w-full md:w-14rem" />
            </div>
            <div>
                <SettingManagment settingItem={selectedSetting} />
            </div>
        </div>
    </>
}

export default GlobalSetting;