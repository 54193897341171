import { IAuthenticatedUser } from "src/api/types/auth";
import { getAuthorization } from "src/utils/get-authorization";
import create from "zustand";

interface IPageState {
    user?: IAuthenticatedUser;
    setUser:(valur:IAuthenticatedUser | undefined)=>void;
    generalLoading:boolean;
    setGeneralLoading:(valur:boolean)=>void;
}


const useStore = create<IPageState>()((set) => ({
    user: undefined,
    setUser: (value: IAuthenticatedUser | undefined) => set(() => ({ user: value })),
    generalLoading:!!getAuthorization(),
    setGeneralLoading: (value: boolean) => set(() => ({ generalLoading: value })),
}));

export const useUser = ()=>useStore((state)=>state.user);
export const useSetUser = ()=>useStore((state)=>state.setUser);
export const useGeneralLoading = ()=>useStore((state)=>state.generalLoading);
export const useSetGeneralLoading = ()=>useStore((state)=>state.setGeneralLoading);
