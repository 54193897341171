import { ITab } from "src/types/functional-component"

export const freelancerProfileTabs: ITab[]  = [
    {
        label: 'Services',
        value: 'services',
    },
    {
        label: 'Profile',
        value: 'profile',
    }
]