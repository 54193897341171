import { Card } from "primereact/card";
import React from "react";
import { useTranslation } from "react-i18next";

interface IPasswordResetLinkSentProps{
    email:string;
}

export const PasswordResetLinkSent: React.FC<IPasswordResetLinkSentProps> = ({email}) => {
    const { t } = useTranslation();
    return  <Card className="item-card text-center">
        <div className="mb-4">
            <i className="pi pi-check-circle text-4xl text-green-600"></i>
        </div>
    <h3 className="mb-4 font-normal">{t("titles.checkYourEmail")}</h3>
    <div className="mb-2 text-gray-600">{t("content.resetPasswordLinkSent")}</div>
    <div className="mb-4">{email}</div>
</Card >
}
