import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import { IAxiosResponse, IPaginatedRequestBody, PaginatedResponse } from "../types/axios-response";
import { ICouponDetails, ICouponItem } from "../types/coupon";

export const couponService = {
    getAll(data?: IPaginatedRequestBody) {
        return AxiosInstance.post<PaginatedResponse<ICouponItem>>(API_ROUTES.PROMOTION.COUPON_LIST, data || {})
    },
    getDetailsInfo(id: number) {
        return AxiosInstance.get<IAxiosResponse<ICouponDetails>>(API_ROUTES.PROMOTION.COUPON_DETAILS(id))
    },
    generateRandomCode(count:number) {
        return AxiosInstance.get<IAxiosResponse<string[]>>(`${API_ROUTES.PROMOTION.GENERATE_RANDOM_CODE}?count=${count}`);
    },
    createCoupon(data: any) {
        return AxiosInstance.post<IAxiosResponse<any>>(`${API_ROUTES.PROMOTION.CREATE_COUPON}`, data);
    },
    revokeCoupon(id: number, data: {revokeReason: string}) {
        return AxiosInstance.put<IAxiosResponse<any>>(`${API_ROUTES.PROMOTION.REVOKE_TOKEN(id)}`, data);
    }
};