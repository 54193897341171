import Avatar from "src/components/App/Avatar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Controller, useForm } from "react-hook-form";
import { categoryService } from "src/api/services/categories";
import { staticImages } from "src/assets";
import GeneralFormErrorMessage from "src/components/App/GeneralFormErrorMessage";
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import { modalStyle } from "src/constants/modal-styles";
import { getAxiosError } from "src/utils/get-axios-error";
import { toast } from "src/utils/toast";
import Modal from "react-modal";
import { ICategory, INewCategoryData } from "src/api/types/category";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "./UpdateCategoryModal.module.css";
import { categoryValidationSchema } from "../../validation-schema";

interface IEditCategoryModalProps {
  show: boolean;
  modalData?: ICategory;
  onHide: () => void;
  onNewCategoryUpdate: (data: ICategory) => void;
}

const UpdateCategoryModal: React.FC<IEditCategoryModalProps> = ({
  show,
  modalData,
  onHide,
  onNewCategoryUpdate,
}) => {
  const [mediaId, setMediaId] = useState<number | undefined>(
    modalData?.image ? modalData?.image?.id : undefined,
  );
  const [iconId, setIconId] = useState<number | undefined>(
    modalData?.icon ? modalData?.icon?.id : undefined,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>("");

  const {
    control,
    getValues,
    reset,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<INewCategoryData>({
    defaultValues: {
      ml_title: {
        en: modalData?.ml_title.en,
        ar: modalData?.ml_title.ar || "",
      },
      isActive: modalData?.isActive,
    },
    mode: "all",
    resolver: yupResolver(categoryValidationSchema()),
  });
  watch(["ml_title.en", "ml_title.ar"]);

  const onAvatarChanged = (id: number) => {
    setMediaId(id);
  };
  const onIconChanged = (id: number) => {
    setIconId(id);
  };
  const onFormSubmit = async (data: INewCategoryData) => {
    if (!mediaId) {
      toast.error("Image is required!");
      return;
    }
    const tempData: INewCategoryData = { ...data };
    tempData.mediaId = mediaId;
    tempData.iconId = iconId;
    if (
      mediaId === modalData?.image?.id &&
      iconId === modalData?.icon?.id &&
      data.ml_title.en === modalData.ml_title.en &&
      data.ml_title.ar === modalData.ml_title.ar
    ) {
      toast.error("There is no change!");
      return;
    }

    try {
      setLoading(true);
      let res = await categoryService.update(modalData!.id, tempData);
      reset({
        ml_title: {
          ar: "",
          en: "",
        },
      });
      setMediaId(undefined);
      onNewCategoryUpdate(res.data.payload);
      onHide();
      toast.success("New Category Successfully created");
    } catch (err) {
      const error = getAxiosError(err);
      const message = error.meta?.message || "Server Error";
      setApiError(message);
    } finally {
      setLoading(false);
    }
  };

  const isUpdateDisabled = () => {
    return (
      getValues("ml_title.en").trim() ===
        (modalData?.ml_title.en || "").trim() &&
      getValues("ml_title.ar").trim() ===
        (modalData?.ml_title.ar || "").trim() &&
      mediaId === modalData!.image?.id &&
      iconId === modalData!.icon?.id
    );
  };

  return (
    <Modal
      ariaHideApp={false}
      style={modalStyle}
      contentLabel={"Edit Category"}
      isOpen={show}
      onRequestClose={onHide}>
      <div className="flex flex-row justify-content-between">
        <span style={{ fontWeight: "600" }}>Edit Category</span>
        <div className="p-1 cursor-pointer" onClick={onHide}>
          <i
            className={"pi pi-times"}
            style={{ fontSize: "1em", color: "var(--black)" }}
          />
        </div>
      </div>
      <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
        {apiError && <GeneralFormErrorMessage message={apiError} />}
        <div className="new-category-content">
          <div>
            <FormFieldWrapper>
              <label
                htmlFor="englishTitle"
                className={classNames({ "p-error": errors.ml_title?.en })}>
                English Title
              </label>
              <Controller
                name="ml_title.en"
                control={control}
                render={({ field }) => (
                  <InputText
                    type="title"
                    {...field}
                    id="englishTitle"
                    className={classNames("block w-full", {
                      "p-invalid": !!errors.ml_title?.en,
                    })}
                  />
                )}
              />
            </FormFieldWrapper>
            <FormFieldWrapper>
              <label
                htmlFor="arabicTitle"
                className={classNames({ "p-error": errors.ml_title?.ar })}>
                Arabic Title
              </label>
              <Controller
                name="ml_title.ar"
                control={control}
                render={({ field }) => (
                  <InputText
                    type="title"
                    {...field}
                    id="arabicTitle"
                    className={classNames("block w-full", {
                      "p-invalid": !!errors.ml_title?.ar,
                    })}
                  />
                )}
              />
            </FormFieldWrapper>
            <div className="flex gap-8">
              <div>
                <Avatar
                  src={modalData?.image?.url || staticImages.noImage}
                  editable
                  setUploading={setLoading}
                  onAvatarChanged={onAvatarChanged}
                  rectangle
                  width="120px"
                  height="184px"
                  style={{ borderRadius: "10px", marginBottom: "15px" }}
                />
              </div>
              <div>
                <Avatar
                  src={modalData?.icon?.url || staticImages.noImage}
                  editable
                  setUploading={setLoading}
                  onAvatarChanged={onIconChanged}
                  rectangle
                  width="120px"
                  height="184px"
                  style={{ borderRadius: "10px", marginBottom: "15px" }}
                />
              </div>
            </div>
          </div>
          <div className={styles["image-and-desc"]}>
            <p>Maximum Size(600 * 920) Minimum Size(260 * 400)</p>
            <p>Format: Image Types, *.jpg, *.png, *.jpeg</p>
          </div>
        </div>
        <div>
          <Button
            label={"Save"}
            className="p-button-primary"
            type="submit"
            loading={loading}
            disabled={isUpdateDisabled()}
          />
        </div>
      </form>
    </Modal>
  );
};

export default UpdateCategoryModal;
