import React, { useState } from "react";
import styles from "./Sidebar.module.css";
import { Link } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { Avatar } from "primereact/avatar";
import { useSetUser, useUser } from "src/context/global-context";
import { ACCESS_TOKEN } from "src/constants/access-token";
import { authService } from "src/api/services/auth";
import { useTranslation } from "react-i18next";
import { classNames } from "primereact/utils";
import QudraLogo from "src/components/App/QudraLogo";
import { PanelMenu } from 'primereact/panelmenu';
import { FilteredSidebarMenuItems } from "./SidebarMenuItems";


const Sidebar: React.FC = () => {
    const user = useUser();
    const setUser = useSetUser();
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);

    const onSignOutClicked = async () => {
        if (loading) return;
        try {
            setLoading(true);
            await authService.logout();
            localStorage.removeItem(ACCESS_TOKEN);
            setUser(undefined);
        } catch (err) {
            console.log(err)
        }
    }

    return <div className={styles["dashboard-sidebar"]}>
        <div>
            <div className={styles["brand"]}>
                <Link to={ROUTE_CONSTANTS.DASHBOARD.ROOT.ABSOLUTE}>
                    <QudraLogo />
                </Link>
            </div>
            <div className={styles["menu"]}>
                <PanelMenu model={FilteredSidebarMenuItems(user?.role!)} />
            </div>
        </div>
        <div className={styles["user-box"]}>
            <Link to={ROUTE_CONSTANTS.DASHBOARD.MY_PROFILE.ABSOLUTE}>
                <span>
                    <Avatar icon="pi pi-user" className="mr-2 p-handler" size="large" shape="circle" />
                </span>
                <span>{user?.email}</span>
            </Link>
            <button type="button" onClick={onSignOutClicked} className={classNames({ "disable-wait": loading })} disabled={loading}>
                <i className="pi pi-sign-out"></i>
                <span>{t("actions.logOut")}</span>
            </button>
        </div>
    </div>
}

export default Sidebar;