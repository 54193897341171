import React from "react";
import Details from "./components/Details";
import ActivityTimeLine from "./components/TimeLine";

const AdminDetails:React.FC = ()=>{

    return <div>
        <Details />
        <ActivityTimeLine />
    </div>
}

export default AdminDetails;