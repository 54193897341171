import React, { useCallback, useEffect, useState } from "react"

import './styles.css'
import { useParams } from "react-router-dom";
import { adminService } from "src/api/services/admin";
import { IBaseUser } from "src/api/types/user";
import { roleValues } from "src/constants/role-values"
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { userService } from "src/api/services/user";
import Header from "src/components/App/PageHeader";
import Card from "src/components/Kit/Card";
import CardBody from "src/components/Kit/Card/CardBody";
import { InputSwitch } from "primereact/inputswitch";

const Details: React.FC = () => {

    const [, setLoading] = useState(false);
    const [activationLoading, setActivationLoading] = useState(false);
    const [userData, setUserData] = useState<IBaseUser>();

    const routeParams = useParams<{ id: string }>();

    const fetchAdminDetails = useCallback(async () => {
        if (routeParams.id) {
            try {
                setLoading(true);
                const result = await adminService.getDetails(routeParams.id);
                setUserData(result.data.payload);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        }
    }, [routeParams.id]);

    useEffect(() => {
        fetchAdminDetails()
    }, [fetchAdminDetails]);


    const changeUserActivation = () => {
        if (userData && !activationLoading) {
            confirmDialog({
                message: `Are you sure you want to ${userData.status === 'Active' ? "deactivate" : "activate"} ${userData.email}`,
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    try {
                        setActivationLoading(true);
                        await userService.changeActivation(userData.id, userData.status !== "Active")
                        setUserData({
                            ...userData,
                            status: userData.status === "Active" ? "Inactive" : "Active"
                        })
                    } catch (err) {
                        console.log(err);
                    } finally {
                        setActivationLoading(false);
                    }
                }
            })
        }
    }

    return userData ? (
        <>
            <Header 
                hasGoBack={true}
                title='Admin Details'
            />
            <Card>
                <CardBody>
                <div className="container">
                <section className="section-wrapper">
                    <h4>Email</h4>
                    <span>{userData.email}</span>
                </section>
                <section className="section-wrapper">
                    <h4>Role</h4>
                    <span>{roleValues[userData.role]}</span>
                </section>
                <section className="section-wrapper">
                    <h4>Current Status</h4>
                    <span>{userData.status}</span>
                </section>
                <section className="action-wrapper">
                        <InputSwitch
                                    checked={userData.status === "Active" }
                                    className={`mt-3`}
                                    onChange={changeUserActivation}
                                />
                </section>
            </div>
                </CardBody>
            </Card>
            <ConfirmDialog />
        </>
    ) : <span>Loading...</span>


}

export default Details;