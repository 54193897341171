import React, { useState } from "react"
import './styles.css'
import HighlightText from 'src/components/App/HighlightText'
import Card from "src/components/Kit/Card"
import CardBody from "src/components/Kit/Card/CardBody"
import CardHeader from "src/components/Kit/Card/CardHeader"

interface IFinancialAccountProps {
    fAccount: any
}
const FinancialAccount: React.FC<IFinancialAccountProps> = ({fAccount}) => {
    const [financialAccount] = useState(fAccount || {})
    
    return (
        <Card className="mt-4">
            <CardHeader>Financial account</CardHeader>
            <CardBody>
            <div className="flex flex-row mt-3">
                <span className="financial-account-title">Account number</span>
                <span className="ml-3">{financialAccount?.accountNumber || '-'}</span>
            </div>
            <div className="flex flex-row align-items-center mt-3">
                <span className="financial-account-title">Card validation</span>
                <HighlightText style={{marginLeft:'10px'}} boolean={financialAccount.isValid}>
                    <span style={{fontSize: '15px'}}>{financialAccount.isValid ? 'Valid' : 'Not valid'}</span>
                </HighlightText>
            </div>
            <div className="flex flex-row mt-3">
                <span className="financial-account-title">Bank Name</span>
                <span className="ml-3">{financialAccount?.bankName || '-'}</span>
            </div>
            <div className="flex flex-row mt-3">
                <span className="financial-account-title">IBAN</span>
                <span className="ml-3">{financialAccount?.iBAN || '-'}</span>
            </div>
            <div className="flex flex-row mt-3">
                <span className="financial-account-title">Branch</span>
                <span className="ml-3">{financialAccount?.branchName || '-'}</span>
            </div>
            <div className="flex flex-row mt-3">
                <span className="financial-account-title">Swift</span>
                <span className="ml-3">{financialAccount?.swift || '-'}</span>
            </div>
            
                </CardBody>        
        </Card>
    )
}

export default FinancialAccount;
