import React, { useState } from "react";
import { Fieldset } from "primereact/fieldset";
import { Dropdown } from 'primereact/dropdown';

import "./styles.css";
import { Controller, useForm } from "react-hook-form";
import { INewAdministratorData } from "../../types";
import { newAdminValidationSchema } from "./validation-schema";
import { yupResolver } from "@hookform/resolvers/yup";
import FormFieldWrapper from "src/components/Kit/FormFieldWrapper";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { adminService } from "src/api/services/admin";
import { getAxiosError } from "src/utils/get-axios-error";
import GeneralFormErrorMessage from "src/components/App/GeneralFormErrorMessage";
import { toast } from "src/utils/toast";
import { IBaseUser } from "src/api/types/user";

const roleItems = [{
    label: 'Financial Manager',
    value: 'financialManager'
}, {
    label: 'System Manager',
    value: 'systemManager'
}];

interface INewAdministratorProps{
    onNewAdminAdded:(data:IBaseUser)=>void;
}

const NewAdministrator: React.FC<INewAdministratorProps> = ({onNewAdminAdded}) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [apiError,setApiError] = useState<string>("");
    const { control,reset, formState: { errors }, handleSubmit } = useForm<INewAdministratorData>({
        defaultValues: {
            email: "",
            role: ""
        },
        mode: "all",
        resolver: yupResolver(newAdminValidationSchema())
    });

    const onFormSubmit = async (data: INewAdministratorData) => {
        try {
            setLoading(true);
            const result = await adminService.create(data.email, data.role);
            reset({
                email:"",
                role:""
            });
            onNewAdminAdded(result.data.payload.user)
            toast.success("New Admin Successfuly created");
        } catch (err) {
            const error = getAxiosError(err);
            const message = error.meta?.message || "Server Error";
            setApiError(message);
        } finally {
            setLoading(false);
        }
    }

    return <div className="new-administrator-box mb-4">
        <Fieldset >
            <h3>New Administrator</h3>
            <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
            {
                        apiError && <GeneralFormErrorMessage message={apiError} />
                    }
                <div className="new-admin-content">
                    <div>
                        <FormFieldWrapper>
                            <label htmlFor="email" className={classNames({ 'p-error': errors.email })}>{t("fields.email")}</label>
                                <Controller name="email" control={control} render={({ field }) => (
                                    <InputText type="email" {...field} id="email" className={classNames('block w-full', { 'p-invalid': !!errors.email })} />
                                )} />
                        </FormFieldWrapper>
                    </div>
                    <div>
                        <FormFieldWrapper>
                            <label htmlFor="role" className={classNames({ 'p-error': errors.role })}>{t("fields.role")}</label>
                                <Controller name="role" control={control} render={({ field }) => (
                                    <Dropdown id={field.name} value={field.value} onChange={(e) => field.onChange(e.value)}
                                        options={roleItems}
                                        className={classNames('element-full-w', { 'p-invalid': !!errors.role })} />
                                )} />
                        </FormFieldWrapper>
                    </div>
                </div>
                <div>
                    <Button label={t("actions.save")} className="p-button-primary" type="submit" loading={loading}/>
                </div>
            </form>
        </Fieldset>
    </div>
}

export default NewAdministrator;