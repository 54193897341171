import { TabView, TabPanel } from "primereact/tabview";
import React from "react";
import ContractList from "./components/ContractList";
import ContractTransactionsList from "./components/ContractTransactionsList";
import { IUserDetailsTransactionsAndContractsProps } from "./types";
import { UserDetailsTransactionsAndContractsProvider } from "./UserDetailsTransactionsAndContractsContext";

const UserDetailsTransactionsAndContracts: React.FC<IUserDetailsTransactionsAndContractsProps> = (props) => {
    return <UserDetailsTransactionsAndContractsProvider {...props}>
        <TabView style={{padding:"0"}}>
            <TabPanel header="Contracts" >
                <ContractList />
            </TabPanel>
            <TabPanel header="Contract Transactions">
                <ContractTransactionsList />
            </TabPanel>
        </TabView>
    </UserDetailsTransactionsAndContractsProvider>
}

export default UserDetailsTransactionsAndContracts;