import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { adminService } from "src/api/services/admin";
import { IActivityTimeLineItem } from "src/api/types/admin";
import { getAxiosError } from "src/utils/get-axios-error";
import { Timeline } from 'primereact/timeline';
import { TimeLineItem } from "./TimeLineItem";
import { Paginator, PaginatorPageState } from 'primereact/paginator';
import { DEFAULT_PAGE_SIZE } from "src/constants/pagination";
import "./timeline.css";
import { classNames } from "primereact/utils";

const ActivityTimeLine: React.FC = () => {
    const [loading,setLoading] = useState<boolean>(false);
    const routeParams = useParams<{ id: string }>();
    const [data,setData] = useState<IActivityTimeLineItem[]>([]);
    const [first, setFirst] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [totalRecords, setTotalRecords] = useState(0)


    const fetchData = useCallback(async() => {
        if(routeParams.id){
            try{
                setLoading(true);
                const result = await adminService.getActivityLogs({
                    filters: {
                        userId: parseInt(routeParams.id)
                    },
                    skip: currentPage * DEFAULT_PAGE_SIZE,
                    take: (currentPage+1) * DEFAULT_PAGE_SIZE,
                });
                setTotalRecords(result.data.payload.pagination.totalItems)
                setData(result.data.payload.items);
            }catch(err){
                const error = getAxiosError(err);
                console.log(error);
            }finally{
                setLoading(false);
            }
        }
    }, [routeParams.id, currentPage]);

    useEffect(()=>{
        fetchData();
    },[fetchData]);

    const onPageChanged = (e: PaginatorPageState)=>{
        setFirst(e.first)
        setCurrentPage(e.page);
    }


    return <>
        <h3 className="font-medium">Activity logs</h3>
        <Timeline 
            value={data} 
            layout="vertical" 
            align="alternate"
            className={classNames("qud-time-line",{"is-loading":loading})}
            content={(item: IActivityTimeLineItem) => (<TimeLineItem data={item} />)} 
        />
        <Paginator rows={DEFAULT_PAGE_SIZE} totalRecords={totalRecords} first={first} onPageChange={onPageChanged} />
    </>
}

export default ActivityTimeLine;