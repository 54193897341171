import React, { useState, useCallback, useEffect } from 'react'
import { IBaseUser } from 'src/api/types/user';
import { contextFactory } from "src/utils/context-factory";
import { ICustomerDetailsContext } from "./types";
import { useParams } from "react-router-dom";
import { customerService } from 'src/api/services/customer';

export const [CustomerDetailsContext, useCustomerDetails] = contextFactory<ICustomerDetailsContext>("CustomerDetailsContext", "useCustomerDetails")


interface ICustomerDetailsProviderProps { 
    children: React.ReactNode
}
export const CustomerDetailsProvider: React.FC<ICustomerDetailsProviderProps> = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState<IBaseUser>();
    const routeParams = useParams<{ id: string }>();
    
    const getCustomerDetails = useCallback(async () => {
        if (routeParams.id) {
            try{
                const result = await customerService.getDetails(routeParams.id);
                setUserData(result.data.payload);
            }catch(error) {
                console.log(error)
            }
        }
    }, [routeParams.id]);

    useEffect(() => {
        getCustomerDetails()
    }, [getCustomerDetails])

    return (
        <CustomerDetailsContext.Provider value={{userData, setUserData, loading, setLoading}}>
            {children}
        </CustomerDetailsContext.Provider>
    )
}