import React, { useState, useCallback, useEffect } from "react";
import { IFreelancerServices } from "src/api/types/base-data";
import { contextFactory } from "src/utils/context-factory";
import { ICustomerJobsContext } from "./types";
import { useParams } from "react-router-dom";
import { freelancerService } from "src/api/services/freelancer";
import { customerService } from "../../../../../api/services/customer";
import { IJobItem } from "../../../../../api/types/job";

export const [CustomerJobsContext, useCustomerJobs] =
  contextFactory<ICustomerJobsContext>(
    "CustomerJobsContext",
    "useCustomerJobs",
  );

interface IFreelancerServicesProviderProps {
  children: React.ReactNode;
}

export const CustomerJobsProvider: React.FC<
  IFreelancerServicesProviderProps
> = ({ children }) => {
  const [jobs, setJobs] = useState<IJobItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [editingJobItem, setEditingJobItem] = useState<IJobItem>();
  const routeParams = useParams<{ id: string }>();

  const fetchFreelancerDetails = useCallback(async () => {
    if (routeParams.id) {
      try {
        let res = await customerService.getJobs(parseInt(routeParams.id));
        setJobs(res.data.payload.items);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }, [routeParams.id]);

  useEffect(() => {
    fetchFreelancerDetails();
  }, [fetchFreelancerDetails]);

  const closeEditServiceModal = () => {
    setEditingJobItem(undefined);
  };

  const openEditJobModal = (sender: IJobItem) => {
    setEditingJobItem(sender);
  };

  return (
    <CustomerJobsContext.Provider
      value={{
        jobs,
        setJobs,
        loading,
        setLoading,
        editingJobItem,
        openEditJobModal,
        closeEditServiceModal,
      }}>
      {children}
    </CustomerJobsContext.Provider>
  );
};
